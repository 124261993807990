package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.model.project.ProjectMember
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.repository.ProjectRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase.GetProjectMembersUseCase.Params
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase

interface GetProjectMembersUseCase : UseCase<Params, List<ProjectMember>> {
    data class Params(
        val projectId: String,
    )
}

internal class GetProjectMembersUseCaseImpl(
    private val projectRepository: ProjectRepository,
) : GetProjectMembersUseCase {
    override suspend fun execute(params: Params): List<ProjectMember> {
        return projectRepository.getMembers(
            projectId = params.projectId,
        )
    }
}
