package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.model.batch.BatchFormat
import cz.cvut.fit.horanvoj.ribbon.model.project.OneTimeExport
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.repository.ProjectRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase.CreateOneTimeExportUseCase.Params
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase

interface CreateOneTimeExportUseCase : UseCase<Params, OneTimeExport> {
    data class Params(
        val projectId: String,
        val format: BatchFormat,
        val languageId: String,
    )
}

internal class CreateOneTimeExportUseCaseImpl(
    private val projectRepository: ProjectRepository,
) : CreateOneTimeExportUseCase {
    override suspend fun execute(params: Params): OneTimeExport {
        return projectRepository.createOneTimeExport(
            projectId = params.projectId,
            format = params.format,
            languageId = params.languageId,
        )
    }
}
