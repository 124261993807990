package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.translation.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.model.term.Term
import cz.cvut.fit.horanvoj.ribbon.model.translation.TranslationUpdate
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.translation.domain.repository.TranslationRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.translation.domain.usecase.SetTranslationUseCase.Params
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase

interface SetTranslationUseCase : UseCase<Params, Term> {
    data class Params(
        val projectId: String,
        val termId: String,
        val translation: TranslationUpdate,
    )
}

internal class SetTranslationUseCaseImpl(
    private val translationRepository: TranslationRepository,
) : SetTranslationUseCase {
    override suspend fun execute(params: Params): Term {
        return translationRepository
            .setTranslation(
                projectId = params.projectId,
                termId = params.termId,
                translation = params.translation,
            )
    }
}
