package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.translation.infrastructure.api

import cz.cvut.fit.horanvoj.ribbon.model.term.Term
import cz.cvut.fit.horanvoj.ribbon.model.translation.TranslationUpdate
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.use
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*

internal interface TranslationApi {
    suspend fun setTranslation(
        projectId: String,
        termId: String,
        translation: TranslationUpdate,
    ): Term

    suspend fun deleteTranslation(
        projectId: String,
        termId: String,
        languageId: String,
    ): Term
}

internal class TranslationApiImpl(
    private val httpClient: HttpClient,
) : TranslationApi {
    override suspend fun setTranslation(
        projectId: String,
        termId: String,
        translation: TranslationUpdate,
    ): Term {
        return httpClient.use {
            post("api/v1/project/$projectId/term/$termId/translation") {
                setBody(translation)
            }.body()
        }
    }

    override suspend fun deleteTranslation(
        projectId: String,
        termId: String,
        languageId: String,
    ): Term {
        return httpClient.use {
            delete("api/v1/project/$projectId/term/$termId/translation/$languageId")
                .body()
        }
    }
}
