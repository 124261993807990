package cz.cvut.fit.horanvoj.ribbon.model.user

import kotlinx.serialization.Serializable

@Serializable
data class User(
    val id: String,
    val name: String,
    val emailAddress: String,
)
