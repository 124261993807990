package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.data.repository

import cz.cvut.fit.horanvoj.ribbon.model.batch.BatchFormat
import cz.cvut.fit.horanvoj.ribbon.model.invite.Invite
import cz.cvut.fit.horanvoj.ribbon.model.invite.InviteCreation
import cz.cvut.fit.horanvoj.ribbon.model.project.*
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.data.source.ProjectLocalSource
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.data.source.ProjectRemoteSource
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.repository.ProjectRepository
import kotlinx.coroutines.flow.Flow

internal class ProjectRepositoryImpl(
    private val projectRemoteSource: ProjectRemoteSource,
    private val projectLocalSource: ProjectLocalSource,
) : ProjectRepository {
    override suspend fun getProjects(): List<Project> {
        return projectRemoteSource.getProjects()
    }

    override suspend fun getProject(projectId: String): Flow<ProjectWithMembership> {
        if (!projectLocalSource.isValuePresent(projectId)) {
            updateProject(projectId)
        }

        return projectLocalSource.getValue(projectId)
    }

    private suspend fun updateProject(
        projectId: String,
        updated: ProjectWithMembership? = null,
    ) {
        val remote = updated ?: projectRemoteSource.getProject(projectId)
        projectLocalSource.updateValue(remote.id, remote)
    }

    override suspend fun createProject(creation: ProjectCreation): Project {
        return projectRemoteSource.createProject(creation)
    }

    override suspend fun modifyProject(
        projectId: String,
        projectModification: ProjectModification,
    ): ProjectWithMembership {
        return projectRemoteSource.modifyProject(projectId, projectModification)
            .also { updateProject(projectId, updated = it) }
    }

    override suspend fun removeProject(projectId: String) {
        projectRemoteSource.removeProject(projectId)
        projectLocalSource.clearValue(projectId)
    }

    override suspend fun createApiKey(projectId: String) {
        projectRemoteSource.createApiKey(projectId)
    }

    override suspend fun getApiKey(projectId: String): String? {
        return projectRemoteSource.getApiKey(projectId)
    }

    override suspend fun removeApiKey(projectId: String) {
        projectRemoteSource.removeApiKey(projectId)
    }

    override suspend fun getMembers(projectId: String): List<ProjectMember> {
        return projectRemoteSource.getMembers(projectId)
    }

    override suspend fun removeMember(
        projectId: String,
        userId: String,
    ): List<ProjectMember> {
        return projectRemoteSource.removeMember(projectId, userId)
            .also { updateProject(projectId) }
    }

    override suspend fun modifyMemberRole(
        projectId: String,
        userId: String,
        modification: MemberModification,
    ): List<ProjectMember> {
        return projectRemoteSource.modifyMemberRole(projectId, userId, modification)
            .also { updateProject(projectId) }
    }

    override suspend fun inviteMember(inviteCreation: InviteCreation) {
        projectRemoteSource.inviteMember(inviteCreation)
    }

    override suspend fun getInvites(projectId: String): List<Invite> {
        return projectRemoteSource.getInvites(projectId)
    }

    override suspend fun removeInvite(
        inviteId: String,
        projectId: String,
    ) {
        projectRemoteSource.removeInvite(inviteId, projectId)
    }

    override suspend fun createOneTimeExport(
        projectId: String,
        format: BatchFormat,
        languageId: String,
    ): OneTimeExport {
        return projectRemoteSource.createOneTimeExport(projectId, format, languageId)
    }
}
