package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.languages

import cz.cvut.fit.horanvoj.ribbon.model.language.Language
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.domain.usecase.GetProjectLanguagesUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.invoke
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.ViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.languages.LanguagesViewModel.State

class LanguagesViewModel(
    private val getProjectLanguages: GetProjectLanguagesUseCase,
) : ViewModel<State>(State()) {
    fun onAppear(projectId: String) {
        update { copy(projectId = projectId) }
        launch { fetchLanguages() }
    }

    private suspend fun fetchLanguages() {
        val projectId = state.projectId ?: return

        update { copy(loading = true) }
        getProjectLanguages(
            GetProjectLanguagesUseCase.Params(
                projectId = projectId,
            ),
        ).onSuccess {
            update {
                copy(
                    loading = false,
                    languagesError = null,
                    languages = it,
                )
            }
        }.onFailure {
            update {
                copy(
                    languagesError = it,
                    loading = false,
                )
            }
        }
    }

    data class State(
        val projectId: String? = null,
        val languages: List<Language> = emptyList(),
        val projectError: Throwable? = null,
        val languagesError: Throwable? = null,
        val loading: Boolean = true,
    ) {
        val error: Throwable? = projectError ?: languagesError
    }
}
