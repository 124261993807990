package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.data.repository

import cz.cvut.fit.horanvoj.ribbon.model.invite.Invite
import cz.cvut.fit.horanvoj.ribbon.model.user.User
import cz.cvut.fit.horanvoj.ribbon.model.user.UserModification
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.data.source.UserSource
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.domain.repository.UserRepository

internal class UserRepositoryImpl(
    private val userSource: UserSource,
) : UserRepository {
    override suspend fun getUser(userId: String): User {
        return userSource.getUser(userId)
    }

    override suspend fun removeUser() {
        userSource.removeUser()
    }

    override suspend fun modifyUser(userModification: UserModification): User {
        return userSource.modifyUser(userModification)
    }

    override suspend fun getInvites(): List<Invite> {
        return userSource.getInvites()
    }

    override suspend fun acceptInvite(inviteId: String) {
        userSource.acceptInvite(inviteId)
    }

    override suspend fun declineInvite(inviteId: String) {
        userSource.declineInvite(inviteId)
    }
}
