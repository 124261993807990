package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term.domain.repository.TermRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term.domain.usecase.RemoveTermUseCase.Params
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase

interface RemoveTermUseCase : UseCase<Params, Unit> {
    data class Params(
        val projectId: String,
        val termId: String,
    )
}

internal class RemoveTermUseCaseImpl(
    private val termRepository: TermRepository,
) : RemoveTermUseCase {
    override suspend fun execute(params: Params) {
        termRepository.removeTerm(
            projectId = params.projectId,
            termId = params.termId,
        )
    }
}
