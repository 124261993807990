package cz.cvut.fit.horanvoj.ribbon.model.term

import kotlinx.serialization.Serializable

@Serializable
data class TermStatistics(
    val translatedIn: List<String>,
    val notTranslatedIn: List<String>,
    val percentageOfTranslations: Int,
)
