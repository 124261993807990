package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.model.term.Term
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term.domain.repository.TermRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term.domain.usecase.GetTermUseCase.Params
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase

interface GetTermUseCase : UseCase<Params, Term> {
    data class Params(
        val projectId: String,
        val termId: String,
    )
}

internal class GetTermUseCaseImpl(
    private val termRepository: TermRepository,
) : GetTermUseCase {
    override suspend fun execute(params: Params): Term {
        return termRepository.getTerm(
            projectId = params.projectId,
            termId = params.termId,
        )
    }
}
