package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared

import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.data.repository.CommonRepositoryImpl
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.data.source.CommonSource
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.repository.CommonRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.usecase.ClearCacheUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.usecase.ClearCacheUseCaseImpl
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.usecase.ValidateEmailUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.usecase.ValidateEmailUseCaseImpl
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.infrastructure.source.CommonSourceImpl
import org.koin.core.module.dsl.factoryOf
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.bind
import org.koin.dsl.module

val featureSharedModule =
    module {
        singleOf(::CommonSourceImpl) bind CommonSource::class
        singleOf(::CommonRepositoryImpl) bind CommonRepository::class

        factoryOf(::ValidateEmailUseCaseImpl) bind ValidateEmailUseCase::class
        factoryOf(::ClearCacheUseCaseImpl) bind ClearCacheUseCase::class
    }
