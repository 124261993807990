package cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffectResult
import androidx.compose.runtime.DisposableEffectScope
import androidx.compose.runtime.key
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.CornerSizeExtraSmall
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.sp
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.toSitePalette
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.dom.TextArea
import org.w3c.dom.HTMLTextAreaElement

@Composable
fun TranslationTextArea(
    value: String,
    onInput: (String) -> Unit,
    onBlur: () -> Unit,
    onFocus: () -> Unit,
    onClick: () -> Unit,
    isFocused: Boolean,
    enabled: Boolean = true,
    ref: DisposableEffectScope.(HTMLTextAreaElement) -> DisposableEffectResult,
) {
    val style = ColorMode.current.toSitePalette()

    key(enabled) {
        TextArea(
            value = value,
        ) {
            ref(ref)

            if (!enabled) {
                disabled()
            }

            onInput { input -> onInput(input.value) }
            onBlur { onBlur() }
            onFocus { onFocus() }
            onClick { onClick() }

            style {
                width(100.percent)
                resize(Resize.Vertical)
                background(style.surface)
                color(style.onSurface)
                borderRadius(CornerSizeExtraSmall)
                fontSize(16.sp)
                minHeight(56.px)
                padding(SpaceMedium)
                outline("transparent")
                if (isFocused) {
                    border(1.px, LineStyle.Solid, style.primary)
                    boxShadow(spreadRadius = 1.px, color = style.primary)
                } else {
                    border(1.px, LineStyle.Solid, Colors.Transparent)
                }
            }
        }
    }
}
