package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.infrastructure.source

import com.russhwolf.settings.Settings
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.data.source.CommonSource

internal class CommonSourceImpl(
    private val settings: Settings,
) : CommonSource {
    override suspend fun clearCaches() {
        settings.clear() // Clear everything from Local Storage
    }
}
