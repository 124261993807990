package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.model.project.MemberModification
import cz.cvut.fit.horanvoj.ribbon.model.project.ProjectMember
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.repository.ProjectRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase.ModifyMemberRoleUseCase.Params
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase

interface ModifyMemberRoleUseCase : UseCase<Params, List<ProjectMember>> {
    data class Params(
        val projectId: String,
        val userId: String,
        val modification: MemberModification,
    )
}

internal class ModifyMemberRoleUseCaseImpl(
    private val projectRepository: ProjectRepository,
) : ModifyMemberRoleUseCase {
    override suspend fun execute(params: Params): List<ProjectMember> {
        return projectRepository.modifyMemberRole(
            projectId = params.projectId,
            userId = params.userId,
            modification = params.modification,
        )
    }
}
