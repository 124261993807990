package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.model.invite.Invite
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.repository.ProjectRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase.GetProjectInvitesUseCase.Params
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase

interface GetProjectInvitesUseCase : UseCase<Params, List<Invite>> {
    data class Params(
        val projectId: String,
    )
}

internal class GetProjectInvitesUseCaseImpl(
    private val projectRepository: ProjectRepository,
) : GetProjectInvitesUseCase {
    override suspend fun execute(params: Params): List<Invite> {
        return projectRepository.getInvites(params.projectId)
    }
}
