package cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.silk.components.icons.mdi.MdiArrowCircleRight
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.OutlinedCard
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.Radio
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.TitleMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.typography
import org.jetbrains.compose.web.css.px

@Composable
fun PrimaryListItem(
    modifier: Modifier = Modifier,
    isSelectable: Boolean = false,
    isSelected: Boolean = false,
    onClick: () -> Unit,
    content: @Composable () -> Unit,
) {
    OutlinedCard(
        isSelected = isSelected,
        modifier = Modifier
            .minHeight(72.px)
            .fillMaxWidth()
            .then(modifier),
        onClick = onClick,
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier
                .fillMaxHeight()
                .fillMaxWidth(),
        ) {
            Box(
                Modifier.typography(TitleMedium),
            ) {
                content()
            }
            Spacer()

            if (isSelectable) {
                Radio(
                    checked = isSelected,
                    onCheckedChange = { onClick() },
                    clickable = false,
                )
            } else {
                MdiArrowCircleRight(
                    modifier = Modifier
                        .fontSize(24.px),
                )
            }
        }
    }
}
