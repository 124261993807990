package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.user

import cz.cvut.fit.horanvoj.ribbon.model.user.User
import cz.cvut.fit.horanvoj.ribbon.model.user.UserModification
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.usecase.GetLoggedInUserUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.usecase.LogoutUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.usecase.ResetPasswordUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.invoke
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.domain.usecase.ModifyUserUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.domain.usecase.RemoveUserUseCase
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.ViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.user.UserViewModel.State
import kotlinx.coroutines.delay
import kotlin.time.Duration.Companion.seconds

internal class UserViewModel(
    private val getLoggedInUser: GetLoggedInUserUseCase,
    private val modifyUser: ModifyUserUseCase,
    private val removeUser: RemoveUserUseCase,
    private val logout: LogoutUseCase,
    private val resetPassword: ResetPasswordUseCase,
) : ViewModel<State>(State()) {
    fun onAppear() {
        launch { fetchUser() }
    }

    private suspend fun fetchUser() {
        update { copy(loading = true) }
        getLoggedInUser()
            .onSuccess { user ->
                val name = user?.name.orEmpty()
                val email = user?.emailAddress.orEmpty()

                update {
                    copy(
                        user = user,
                        loading = false,
                        error = null,
                        name = name,
                        email = email,
                    )
                }
            }
            .onFailure {
                update { copy(loading = false, error = it) }
            }
    }

    fun onNameChanged(name: String) {
        update { copy(name = name) }
    }

    fun onDeleteClick() {
        update { copy(isDeleteDialogVisible = true) }
    }

    fun onCancelDeleteClick() {
        update { copy(isDeleteDialogVisible = false) }
    }

    fun onConfirmDeleteClick() {
        update { copy(isDeleteDialogVisible = false, loading = true) }
        launch {
            removeUser()
            logout()
            update { copy(hasDeletedAccount = true) }
        }
    }

    fun onResetButtonClick() {
        launch {
            resetPassword(ResetPasswordUseCase.Params())
            update { copy(hasResetPassword = true) }
            delay(1.seconds)
            update { copy(hasResetPassword = false) }
        }
    }

    fun onSaveClick() {
        update { copy(loading = true) }

        val modification =
            UserModification(
                name = state.name.takeIf { state.user?.name != it },
            )

        launch {
            modifyUser(
                ModifyUserUseCase.Params(
                    modification = modification,
                ),
            ).onSuccess {
                update { copy(user = it, loading = false) }
            }.onFailure {
                update { copy(error = it, loading = false) }
            }
        }
    }

    data class State(
        val isDeleteDialogVisible: Boolean = false,
        val name: String = "",
        val email: String = "",
        val isEmailVerified: Boolean? = null,
        val user: User? = null,
        val loading: Boolean = true,
        val error: Throwable? = null,
        val hasResetPassword: Boolean = false,
        val hasDeletedAccount: Boolean = false,
    ) {
        val isSaveButtonEnabled = hasEdited()

        private fun hasEdited(): Boolean {
            return name != user?.name
        }
    }
}
