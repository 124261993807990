package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage

import cz.cvut.fit.horanvoj.ribbon.model.common.Order
import cz.cvut.fit.horanvoj.ribbon.model.term.Term
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.invoke
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term.domain.usecase.GetTermsUseCase
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.ViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.TermsCardViewModel.State

internal class TermsCardViewModel(
    private val getTermsUseCase: GetTermsUseCase,
) : ViewModel<State>(State()) {
    fun onAppear(projectId: String) {
        update { copy(projectId = projectId) }
        launch { fetchTerms() }
    }

    private suspend fun fetchTerms() {
        val projectId = state.projectId ?: return
        update { copy(loading = true) }
        getTermsUseCase(
            GetTermsUseCase.Params(
                cursor = null,
                projectId = projectId,
                includeLanguageIds = emptyList(),
                size = 10,
                order = Order.UPDATED_DESC,
            ),
        ).onSuccess {
            update { copy(terms = it.items, loading = false) }
        }.onFailure {
            update { copy(error = it, loading = false) }
        }
    }

    data class State(
        val projectId: String? = null,
        val loading: Boolean = true,
        val error: Throwable? = null,
        val terms: List<Term> = emptyList(),
    )
}
