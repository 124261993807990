package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.languages

import Res
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import cz.cvut.fit.horanvoj.ribbon.model.language.Language
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.PageLayout
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.ProjectTab
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.RequireAuthenticationEffect
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.rememberViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.routeToTranslations
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.EmptyView
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.ErrorSnackbar
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.PrimaryListItem
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.domain.displayName
import org.jetbrains.compose.web.dom.Text

@Page("/project/{projectId}/languages")
@Composable
fun LanguagesPage() {
    RequireAuthenticationEffect()
    val ctx = rememberPageContext()
    val projectId = ctx.route.params.getValue("projectId")
    val viewModel = rememberViewModel<LanguagesViewModel>()

    LaunchedEffect(viewModel, projectId) {
        viewModel.onAppear(projectId)
    }

    ErrorSnackbar(
        throwable = viewModel.state.error,
    )

    PageLayout(
        title = Res.string.title_translate,
        activeTab = ProjectTab.TRANSLATE,
        loading = viewModel.state.loading,
    ) {
        ProjectLanguages(
            state = viewModel.state,
            onLanguageClick = { language ->
                ctx.router.routeToTranslations(
                    projectId = projectId,
                    languageId = language.languageId,
                )
            },
        )
    }
}

@Composable
private fun ProjectLanguages(
    state: LanguagesViewModel.State,
    onLanguageClick: (Language) -> Unit,
) {
    Box(
        modifier = Modifier
            .fillMaxSize(),
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .gap(SpaceMedium)
                .padding(SpaceMedium),
        ) {
            if (state.languages.isEmpty()) {
                EmptyView(
                    text = Res.string.label_languages_empty,
                )
            } else {
                state.languages.forEach { language ->
                    PrimaryListItem(
                        onClick = {
                            onLanguageClick(language)
                        },
                    ) {
                        Text(language.displayName)
                    }
                }
            }
        }
    }
}
