package strings

import io.github.skeptick.libres.strings.PluralForms
import io.github.skeptick.libres.strings.getCurrentLanguageCode
import kotlin.String

public object StringsEn : Strings {
  override val app_name: String = "Ribbon"

  override val title_user: String = "User"

  override val title_your_account: String = "Your account"

  override val label_password_reset_email_sent: String =
      "An email with a password reset link has been sent to your email address."

  override val label_email_address: String = "Email address"

  override val label_full_name: String = "Full name"

  override val button_delete: String = "Delete"

  override val button_reset_password: String = "Reset password"

  override val button_save: String = "Save"

  override val button_cancel: String = "Cancel"

  override val button_confirm: String = "Confirm"

  override val title_are_you_sure: String = "Are you sure?"

  override val label_delete_account_info: String = "This will remove your account permanently."

  override val title_register: String = "Register"

  override val button_login_instead: String = "Log in instead"

  override val button_register_instead: String = "Register instead"

  override val button_register: String = "Register"

  override val label_email: String = "Email"

  override val label_password: String = "Password"

  override val label_repeat_password: String = "Repeat password"

  override val title_projects: String = "Projects"

  override val button_create_project: String = "Create project"

  override val button_add_language: String = "Add language"

  override val title_login: String = "Login"

  override val button_login: String = "Log in"

  override val button_translate: String = "Translate"

  override val button_manage: String = "Manage"

  override val label_logout: String = "Log out"

  override val label_empty: String = "There's nothing here"

  override val error_unknown: String = "Unknown error"

  override val title_looking_for_registration: String = "Looking for registration?"

  override val title_looking_for_login: String = "Wanting to log in instead?"

  override val button_forgotten_password: String = "Forgotten password"

  override val title_forgotten_password: String = "Forgotten password"

  override val title_add_language: String = "Add language"

  override val button_add: String = "Add"

  override val label_search: String = "Search"

  override val label_languages_search: String =
      "Search for a language first. Two characters are required at a minimum."

  override val label_languages_empty_search: String = "No languages found"

  override val title_translate: String = "Translate"

  override val button_set_reference_language: String = "Set reference language"

  override val title_reference_language: String = "Select reference language"

  override val label_no_translation: String = "Translation missing"

  override val button_next_page: String = "Next page"

  override val button_prev_page: String = "Previous page"

  override val title_create_project: String = "Create project"

  override val button_create: String = "Create"

  override val label_project_name: String = "Project name"

  override val label_project_creation: String =
      "To create a project, provide a name for it. You will be able to add languages and members to it later."

  override val button_view_more: String = "View more"

  override val title_terms: String = "Terms"

  override val title_languages: String = "Languages"

  override val title_members: String = "Members"

  override val title_manage: String = "Manage"

  override val title_options: String = "Options"

  override val button_delete_project: String = "Delete project"

  override val button_edit_project: String = "Edit project"

  override val button_generate_api_key: String = "Generate API key"

  override val button_remove_api_key: String = "Remove API key"

  override val button_copy_api_key: String = "Copy API key"

  override val button_import: String = "Import"

  override val button_export: String = "Export"

  override val label_role_admin: String = "Administrator"

  override val label_role_developer: String = "Developer"

  override val label_role_translator: String = "Translator"

  override val label_api_key_has_been_copied: String =
      "The API key has been copied to your clipboard."

  override val label_delete_api_key_info: String =
      "This will remove the API and everyone that has it will lose their access."

  override val label_delete_project_info: String =
      "This will remove the entire project, and you will lose access to its terms and all the translations. It is recommended to do a backup using the export function first!"

  override val title_edit_project: String = "Edit project"

  override val label_edit_project: String =
      "You can only edit the projects name here. To edit the projects languages or members, use the different cards in the \"Manage\" tab."

  override val title_export: String = "Export"

  override val batch_json: String = "Ribbon (.json)"

  override val batch_android: String = "Android (.xml)"

  override val batch_apple: String = "Apple strings (.strings)"

  override val label_export_info: String =
      "To export your translations, first select the language of your export:"

  override val button_invite_member: String = "Invite"

  override val label_user_has_been_invited_info: String =
      "User has been invited to the project, they will be prompted to accept or decline the invite on their next login."

  override val title_invite: String = "Invite"

  override val label_invite_member: String =
      "To invite a user to your project, please provide an email address of that user. The user doesn't have to be registered so keep in mind that anyone that registers under that email adress is going to be invited to this project."

  override val label_invite_member_role: String =
      "Select a role which the new member will have. This can be later changed in the manage tab of the project."

  override val label_invited_to: LibresFormatLabelInvitedTo =
      LibresFormatLabelInvitedTo("You have been invited by %1${'$'}s to %2${'$'}s with %3${'$'}s role. Do you want to accept the invite?")

  override val button_accept: String = "Accept"

  override val button_decline: String = "Decline"

  override val label_remove_member: LibresFormatLabelRemoveMember =
      LibresFormatLabelRemoveMember("Removing %1${'$'}s will cause them to lose access to the project immediately.")

  override val title_modify_member: String = "Modify member role"

  override val label_modify_member: LibresFormatLabelModifyMember =
      LibresFormatLabelModifyMember("You can modify %1${'$'}ss role here. The user will gain or lose some privileges when you chose to do so.")

  override val label_remove_language: LibresFormatLabelRemoveLanguage =
      LibresFormatLabelRemoveLanguage("When you remove %1${'$'}s from the project, all the translations in this language will be lost forever.")

  override val label_show_statistics: String = "Show statistics"

  override val label_last_updated: LibresFormatLabelLastUpdated =
      LibresFormatLabelLastUpdated("Last updated by %1${'$'}s on %2${'$'}s")

  override val label_last_updated_no_author: LibresFormatLabelLastUpdatedNoAuthor =
      LibresFormatLabelLastUpdatedNoAuthor("Last updated on %1${'$'}s")

  override val label_description: String = "Description"

  override val label_author: String = "Author"

  override val label_translations: String = "Translations"

  override val label_translated_in: LibresFormatLabelTranslatedIn =
      LibresFormatLabelTranslatedIn("Translated in %1${'$'}s% languages")

  override val button_terms: String = "Terms"

  override val title_create_term: String = "Create a term"

  override val label_create_term: String =
      "To create a term, set its unique identification. This identifier must not contain any spaces. You can also provide a description which will show up to translator, although this is not necessary."

  override val label_term_id: String = "Term identifier"

  override val label_term_description: String = "Description (optional)"

  override val button_create_term: String = "Create term"

  override val label_remove_term: LibresFormatLabelRemoveTerm =
      LibresFormatLabelRemoveTerm("Removing the term %1${'$'}s will also remove all of its translations.")

  override val title_update_term: String = "Update a term"

  override val label_update_term: String =
      "When updating a term, you can choose a new unique identifier without any spaces. Leaving the description blank causes it to be removed."

  override val title_filter: String = "Filter"

  override val label_order_by: String = "Order by"

  override val label_filter: String = "Filter"

  override val label_order_updated_asc: String = "Updated (ascending)"

  override val label_order_updated_desc: String = "Updated (descending)"

  override val label_order_name_asc: String = "Name (ascending)"

  override val label_order_name_desc: String = "Name (descending)"

  override val label_term_filter_all: String = "All"

  override val label_term_filter_translated: String = "Translated"

  override val label_term_filter_not_translated: String = "Not translated"

  override val title_import: String = "Import"

  override val label_select_file: String =
      "Select a file by clicking here or dragging it over this area."

  override val label_drop_now: String = "Drop it now!"

  override val label_override_existing: String = "Override existing terms & translations"

  override val label_import_info: String =
      "To begin the import, start by uploading a file with your terms & translations:"

  override val label_import_languages_info: String =
      "Select a language in which the terms in your file are translated in:"

  override val label_import_format_info: String =
      "Select the correct format of the file you have uploaded:"

  override val label_import_override_info: String =
      "If you choose to override existing terms & strings, you will lose existing translations for terms that have the matching names with the ones in your import file."

  override val label_format_unknown: String =
      "The file format has not been recognized, have you uploaded the correct file? You can still select the format manually; however, the import may fail."

  override val title_import_successful: String = "Import successful"

  override val label_import_count: LibresFormatLabelImportCount =
      LibresFormatLabelImportCount("Imported %1${'$'}s terms in total.")

  override val label_import_skipped: LibresFormatLabelImportSkipped =
      LibresFormatLabelImportSkipped("Skipped %1${'$'}s terms:")

  override val label_import_nothing_skipped: String = "No terms were skipped."

  override val title_importing: String = "Importing..."

  override val label_importing: String = "This may take a while."

  override val title_error: String = "Error"

  override val label_user_avatar_info: String = "User avatar is provided by "

  override val label_password_criteria: String =
      "Password must be at least 8 characters long and contain at least one character and a number."

  override val label_registration_email: String =
      "We will send you a verification link to this email address after you register. To log in, you first need to verify yourself using the link."

  override val label_invited_by: LibresFormatLabelInvitedBy =
      LibresFormatLabelInvitedBy("Invited by %1${'$'}s as %2${'$'}s. Waiting for the invitee action.")

  override val error_unknown_user: String = "Unknown user"

  override val label_remove_invite: LibresFormatLabelRemoveInvite =
      LibresFormatLabelRemoveInvite("If you remove an invitation for %1${'$'}s, they will not be able to join the project anymore.")

  override val label_languages_empty: String = "There are no languages in your project."

  override val label_terms_empty: String = "There are no terms here..."

  override val label_projects_empty: String =
      "You are not a member of any projects. Either wait for an invitation or create one yourself"

  override val label_translations_empty: String = "Nothing to translate..."

  override val error_invalid_credentials: String = "Login failed. Invalid credentials."

  override val error_too_many_requests: String =
      "Login failed. Too many requests, please try again later."

  override val error_login_failed: String = "Login failed."

  override val error_not_verified: String =
      "Email address has not been verified. Please verify it first via the link in the email we just sent you to your inbox."

  override val title_registration_successful: String = "Registration successful"

  override val label_registered_verify_email: String =
      "Your account has been created. Please check your inbox for the verification link. You will be able to sign in after verification."

  override val label_export_generated: String =
      "Export has been generated, click the link bellow to download it."

  override val title_export_successful: String = "Export successful"

  override val title_exporting: String = "Exporting..."

  override val label_exporting: String = "This may take a while."

  override val button_download: String = "Download"

  override val label_export_format_info: String = "Select the desired format:"
}
