package cz.cvut.fit.horanvoj.ribbon.unwinder.web

import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.featureAuthModule
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.batch.featureBatchModule
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.featureLanguageModule
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.featureProjectModule
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.featureSharedModule
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term.featureTermModule
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.translation.featureTranslationModule
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.featureUserModule
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.infrastructure.infrastructureModule
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.presentationModule
import org.koin.dsl.module

internal val unwinderModule =
    module {
        includes(infrastructureModule)

        // Feature modules
        includes(
            featureBatchModule,
            featureLanguageModule,
            featureProjectModule,
            featureSharedModule,
            featureTermModule,
            featureTranslationModule,
            featureUserModule,
            featureAuthModule,
        )

        includes(presentationModule)
    }
