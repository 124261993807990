package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.domain.repository.UserRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.domain.usecase.AcceptInviteUseCase.Params
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase

interface AcceptInviteUseCase : UseCase<Params, Unit> {
    data class Params(
        val inviteId: String,
    )
}

internal class AcceptInviteUseCaseImpl(
    private val userRepository: UserRepository,
) : AcceptInviteUseCase {
    override suspend fun execute(params: Params) {
        return userRepository.acceptInvite(
            inviteId = params.inviteId,
        )
    }
}
