package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.model.language.Language
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.domain.repository.LanguageRepository
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCaseNoParams

interface GetSupportedLanguagesUseCase : UseCaseNoParams<List<Language>>

internal class GetSupportedLanguagesUseCaseImpl(
    private val languageRepository: LanguageRepository,
) : GetSupportedLanguagesUseCase {
    override suspend fun execute(): List<Language> {
        return languageRepository.getSupportedLanguages()
    }
}
