package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.data.repository

import cz.cvut.fit.horanvoj.ribbon.model.user.User
import cz.cvut.fit.horanvoj.ribbon.model.user.UserModification
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.data.source.AuthSource
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.repository.AuthRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.model.AuthRequest
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.model.RegistrationRequest

internal class AuthRepositoryImpl(
    private val authSource: AuthSource,
) : AuthRepository {
    override suspend fun login(request: AuthRequest) {
        authSource.login(request)
    }

    override suspend fun getToken(): String {
        return authSource.getToken()
    }

    override suspend fun logout() {
        authSource.logout()
    }

    override suspend fun resetPassword(email: String?) {
        authSource.resetPassword(email)
    }

    override suspend fun isLoggedIn(): Boolean {
        return authSource.isLoggedIn()
    }

    override suspend fun getUser(): User {
        return authSource.getUser()
    }

    override suspend fun updateLoggedInUser(modification: UserModification) {
        return authSource.updateLoggedInUser(modification)
    }

    override suspend fun register(request: RegistrationRequest) {
        authSource.register(request)
    }
}
