package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.translation.infrastructure.source

import cz.cvut.fit.horanvoj.ribbon.model.term.Term
import cz.cvut.fit.horanvoj.ribbon.model.translation.TranslationUpdate
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.translation.data.source.TranslationSource
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.translation.infrastructure.api.TranslationApi

internal class TranslationSourceImpl(
    private val translationApi: TranslationApi,
) : TranslationSource {
    override suspend fun setTranslation(
        projectId: String,
        termId: String,
        translation: TranslationUpdate,
    ): Term {
        return translationApi.setTranslation(projectId, termId, translation)
    }

    override suspend fun deleteTranslation(
        projectId: String,
        termId: String,
        languageId: String,
    ): Term {
        return translationApi.deleteTranslation(projectId, termId, languageId)
    }
}
