package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.translate.referenceLanguage

import cz.cvut.fit.horanvoj.ribbon.model.language.Language
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.domain.usecase.GetProjectLanguagesUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.invoke
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.ViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.translate.referenceLanguage.ReferenceLanguageViewModel.State

internal class ReferenceLanguageViewModel(
    private val getProjectLanguages: GetProjectLanguagesUseCase,
) : ViewModel<State>(State()) {
    fun onAppear(
        projectId: String,
        languageId: String,
    ) {
        if (state.projectId == projectId && state.languageId == languageId) {
            return
        }

        update { copy(projectId = projectId, languageId = languageId) }
        launch {
            getProjectLanguages(
                GetProjectLanguagesUseCase.Params(
                    projectId = projectId,
                ),
            )
                .onSuccess { languages ->
                    update {
                        copy(
                            allLanguages = languages,
                            loading = false,
                        )
                    }
                }.onFailure {
                    update { copy(error = it, loading = false) }
                }
        }
    }

    fun onQueryChange(query: String) {
        update { copy(query = query) }
    }

    data class State(
        val projectId: String? = null,
        val languageId: String? = null,
        val allLanguages: List<Language> = emptyList(),
        val loading: Boolean = true,
        val query: String = "",
        val error: Throwable? = null,
    ) {
        val isQueryInvalid = query.isNotEmpty()

        val languages: List<Language> =
            if (isQueryInvalid) {
                emptyList()
            } else {
                allLanguages.filter { l -> l.matches(query) && l.languageId != languageId }
            }

        private fun Language.matches(query: String): Boolean {
            return listOfNotNull(languageId, languageName, localLanguageName, countryEmoji)
                .any { it.contains(query, ignoreCase = true) }
        }
    }
}
