package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.batch.data.repository

import cz.cvut.fit.horanvoj.ribbon.model.batch.BatchFormat
import cz.cvut.fit.horanvoj.ribbon.model.batch.ImportResult
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.batch.data.source.BatchSource
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.batch.domain.repository.BatchRepository

internal class BatchRepositoryImpl(
    private val batchSource: BatchSource,
) : BatchRepository {
    override suspend fun import(
        projectId: String,
        languageId: String,
        format: BatchFormat,
        importData: String,
        overrideExisting: Boolean,
    ): ImportResult {
        return batchSource.import(
            projectId = projectId,
            languageId = languageId,
            format = format,
            importData = importData,
            overrideExisting = overrideExisting,
        )
    }
}
