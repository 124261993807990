package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project

import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.data.repository.ProjectRepositoryImpl
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.data.source.ProjectLocalSource
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.data.source.ProjectRemoteSource
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.repository.ProjectRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase.*
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.infrastructure.api.ProjectApi
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.infrastructure.api.ProjectApiImpl
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.infrastructure.source.ProjectLocalSourceImpl
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.infrastructure.source.ProjectRemoteSourceImpl
import org.koin.core.module.dsl.factoryOf
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.bind
import org.koin.dsl.module

val featureProjectModule =
    module {
        singleOf(::ProjectApiImpl) bind ProjectApi::class
        singleOf(::ProjectLocalSourceImpl) bind ProjectLocalSource::class
        singleOf(::ProjectRemoteSourceImpl) bind ProjectRemoteSource::class
        singleOf(::ProjectRepositoryImpl) bind ProjectRepository::class

        factoryOf(::CreateApiKeyUseCaseImpl) bind CreateApiKeyUseCase::class
        factoryOf(::CreateProjectUseCaseImpl) bind CreateProjectUseCase::class
        factoryOf(::GetApiKeyUseCaseImpl) bind GetApiKeyUseCase::class
        factoryOf(::GetProjectsUseCaseImpl) bind GetProjectsUseCase::class
        factoryOf(::GetProjectUseCaseImpl) bind GetProjectUseCase::class
        factoryOf(::ModifyMemberRoleUseCaseImpl) bind ModifyMemberRoleUseCase::class
        factoryOf(::ModifyProjectUseCaseImpl) bind ModifyProjectUseCase::class
        factoryOf(::RemoveApiKeyUseCaseImpl) bind RemoveApiKeyUseCase::class
        factoryOf(::RemoveMemberUseCaseImpl) bind RemoveMemberUseCase::class
        factoryOf(::RemoveProjectUseCaseImpl) bind RemoveProjectUseCase::class
        factoryOf(::GetProjectMembersUseCaseImpl) bind GetProjectMembersUseCase::class
        factoryOf(::InviteMemberUseCaseImpl) bind InviteMemberUseCase::class
        factoryOf(::CreateOneTimeExportUseCaseImpl) bind CreateOneTimeExportUseCase::class
        factoryOf(::GetProjectInvitesUseCaseImpl) bind GetProjectInvitesUseCase::class
        factoryOf(::RemoveInviteUseCaseImpl) bind RemoveInviteUseCase::class
    }
