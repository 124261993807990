package cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material

import androidx.compose.runtime.Composable
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.css.CSSLengthNumericValue
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TransitionProperty
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.RowScope
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.forms.ButtonSize
import com.varabyte.kobweb.silk.components.forms.ButtonVars
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.CornerSizeRound
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceSmall
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.toSitePalette
import org.jetbrains.compose.web.css.AnimationTimingFunction
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.s
import org.jetbrains.compose.web.dom.Span

val ElevatedOnHoverButtonStyle by ComponentStyle {
    base {
        Modifier
            .transition(ButtonTransition)
    }

    hover {
        Modifier
            .materialShadow(MaterialShadowLevel.LEVEL_ONE)
    }
}

val FilledButtonSize =
    object : ButtonSize {
        override val fontSize: CSSLengthNumericValue
            get() = 14.sp
        override val height: CSSLengthNumericValue
            get() = 40.px
        override val horizontalPadding: CSSLengthNumericValue
            get() = 24.px
    }

@Composable
fun MaterialFilledButton(
    onClick: (evt: SyntheticMouseEvent) -> Unit,
    modifier: Modifier = Modifier,
    enabled: Boolean = true,
    color: Color = ColorMode.current.toSitePalette().onPrimary,
    backgroundColor: Color = ColorMode.current.toSitePalette().primary,
    content: @Composable RowScope.() -> Unit,
) {
    Button(
        onClick = onClick,
        enabled = enabled,
        size = FilledButtonSize,
        modifier = Modifier
            .then(ElevatedOnHoverButtonStyle.toModifier())
            .setVariable(ButtonVars.BackgroundDefaultColor, backgroundColor)
            .setVariable(ButtonVars.BackgroundFocusColor, backgroundColor)
            .setVariable(ButtonVars.BackgroundHoverColor, backgroundColor)
            .setVariable(ButtonVars.BackgroundPressedColor, backgroundColor)
            .setVariable(ButtonVars.Height, 40.px)
            .setVariable(ButtonVars.FontSize, 14.sp)
            .setVariable(ButtonVars.Color, color)
            .borderRadius(CornerSizeRound)
            .fontWeight(FontWeight.Medium)
            .then(modifier),
    ) {
        Span(attrs = Modifier.typography(LabelLarge).toAttrs()) {
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier
                    .gap(SpaceSmall),
            ) {
                content()
            }
        }
    }
}

@Composable
fun MaterialTonalButton(
    onClick: (evt: SyntheticMouseEvent) -> Unit,
    modifier: Modifier = Modifier,
    enabled: Boolean = true,
    content: @Composable RowScope.() -> Unit,
) {
    val style = ColorMode.current.toSitePalette()
    MaterialFilledButton(
        onClick = onClick,
        modifier = modifier,
        enabled = enabled,
        color = style.onSecondaryContainer,
        backgroundColor = style.secondaryContainer,
        content = content,
    )
}

val TextButtonSize =
    object : ButtonSize {
        override val fontSize: CSSLengthNumericValue
            get() = 14.sp
        override val height: CSSLengthNumericValue
            get() = 40.px
        override val horizontalPadding: CSSLengthNumericValue
            get() = 12.px
    }

@Composable
fun MaterialTextButton(
    onClick: (evt: SyntheticMouseEvent) -> Unit,
    modifier: Modifier = Modifier,
    enabled: Boolean = true,
    color: Color = ColorMode.current.toSitePalette().primary,
    content: @Composable RowScope.() -> Unit,
) {
    Button(
        onClick = onClick,
        enabled = enabled,
        size = TextButtonSize,
        modifier = Modifier
            .transition(ButtonTransition)
            .setVariable(ButtonVars.BackgroundDefaultColor, Colors.Transparent)
            .setVariable(ButtonVars.BackgroundFocusColor, color.toRgb().copy(alpha = 25))
            .setVariable(ButtonVars.BackgroundHoverColor, color.toRgb().copy(alpha = 20))
            .setVariable(ButtonVars.BackgroundFocusColor, color.toRgb().copy(alpha = 25))
            .setVariable(ButtonVars.Height, 40.px)
            .setVariable(ButtonVars.FontSize, 14.sp)
            .setVariable(ButtonVars.Color, color)
            .borderRadius(CornerSizeRound)
            .fontWeight(FontWeight.Medium)
            .then(modifier),
    ) {
        Span(attrs = Modifier.typography(LabelLarge).toAttrs()) {
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier
                    .gap(SpaceSmall),
            ) {
                content()
            }
        }
    }
}

val ButtonTransition =
    CSSTransition.group(
        properties =
            listOf(
                TransitionProperty.of("box-shadow"),
                TransitionProperty.of("background"),
            ),
        duration = 0.3.s,
        timingFunction = AnimationTimingFunction.EaseInOut,
    )

@Composable
fun MaterialIconButton(
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    content: @Composable () -> Unit,
) {
    MaterialTextButton(
        onClick = { onClick() },
        modifier = modifier
            .size(40.px)
            .setVariable(ButtonVars.FontSize, 24.px),
    ) { content() }
}
