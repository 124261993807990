package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.repository.AuthRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.usecase.ModifyLoggedInUseUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.usecase.ModifyLoggedInUseUseCase.Params

internal class ModifyLoggedInUseUseCaseImpl(
    private val authRepository: AuthRepository,
) : ModifyLoggedInUseUseCase {
    override suspend fun execute(params: Params) {
        authRepository.updateLoggedInUser(params.modification)
    }
}
