package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term.infrastructure.api

import cz.cvut.fit.horanvoj.ribbon.model.common.Order
import cz.cvut.fit.horanvoj.ribbon.model.common.Paging
import cz.cvut.fit.horanvoj.ribbon.model.term.Term
import cz.cvut.fit.horanvoj.ribbon.model.term.TermCreation
import cz.cvut.fit.horanvoj.ribbon.model.term.TermFilter
import cz.cvut.fit.horanvoj.ribbon.model.term.TermModification
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.use
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*

internal interface TermApi {
    suspend fun getTerms(
        projectId: String,
        size: Int,
        cursor: String?,
        includeLanguageIds: List<String>,
        filter: TermFilter?,
        filterLanguage: String?,
        order: Order?,
    ): Paging<Term>

    suspend fun queryTerms(
        projectId: String,
        size: Int,
        cursor: String?,
        includeLanguageIds: List<String>,
        filter: TermFilter?,
        filterLanguage: String?,
        order: Order?,
        query: String,
    ): Paging<Term>

    suspend fun getTerm(
        projectId: String,
        termId: String,
    ): Term

    suspend fun createTerm(
        projectId: String,
        creation: TermCreation,
    ): Term

    suspend fun updateTerm(
        projectId: String,
        termId: String,
        modification: TermModification,
    ): Term

    suspend fun removeTerm(
        projectId: String,
        termId: String,
    )
}

internal class TermApiImpl(
    private val httpClient: HttpClient,
) : TermApi {
    override suspend fun getTerms(
        projectId: String,
        size: Int,
        cursor: String?,
        includeLanguageIds: List<String>,
        filter: TermFilter?,
        filterLanguage: String?,
        order: Order?,
    ): Paging<Term> {
        return httpClient.use {
            get("api/v1/project/$projectId/term") {
                parameter("size", size)
                parameter("cursor", cursor)
                parameter("filter", filter)
                parameter("filterLanguage", filterLanguage)
                parameter("order", order)
                includeLanguageIds.forEach { language ->
                    parameter("includeLanguage", language)
                }
            }.body()
        }
    }

    override suspend fun queryTerms(
        projectId: String,
        size: Int,
        cursor: String?,
        includeLanguageIds: List<String>,
        filter: TermFilter?,
        filterLanguage: String?,
        order: Order?,
        query: String,
    ): Paging<Term> {
        return httpClient.use {
            get("api/v1/project/$projectId/term") {
                parameter("size", size)
                parameter("cursor", cursor)
                parameter("query", query)
                parameter("filter", filter)
                parameter("filterLanguage", filterLanguage)
                parameter("order", order)
                includeLanguageIds.forEach { language ->
                    parameter("includeLanguage", language)
                }
            }.body()
        }
    }

    override suspend fun getTerm(
        projectId: String,
        termId: String,
    ): Term {
        return httpClient.use {
            get("api/v1/project/$projectId/term/$termId")
                .body()
        }
    }

    override suspend fun createTerm(
        projectId: String,
        creation: TermCreation,
    ): Term {
        return httpClient.use {
            post("api/v1/project/$projectId/term") {
                setBody(creation)
            }.body()
        }
    }

    override suspend fun updateTerm(
        projectId: String,
        termId: String,
        modification: TermModification,
    ): Term {
        return httpClient.use {
            put("api/v1/project/$projectId/term/$termId") {
                setBody(modification)
            }.body()
        }
    }

    override suspend fun removeTerm(
        projectId: String,
        termId: String,
    ) {
        return httpClient.use {
            delete("api/v1/project/$projectId/term/$termId")
                .body()
        }
    }
}
