package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.repository.AuthRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.usecase.ClearCacheUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.invoke
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCaseNoParams

interface LogoutUseCase : UseCaseNoParams<Unit>

internal class LogoutUseCaseImpl(
    private val authRepository: AuthRepository,
    private val clearCache: ClearCacheUseCase,
) : LogoutUseCase {
    override suspend fun execute() {
        clearCache()
        authRepository.logout()
    }
}
