package strings

import io.github.skeptick.libres.strings.formatString
import io.github.skeptick.libres.strings.getPluralizedString
import kotlin.String

public class LibresFormatLabelInvitedTo(
  private val `value`: String,
) {
  public fun format(
    user: String,
    project: String,
    role: String,
  ): String = formatString(value, arrayOf(user,project,role))
}

public class LibresFormatLabelRemoveMember(
  private val `value`: String,
) {
  public fun format(name: String): String = formatString(value, arrayOf(name))
}

public class LibresFormatLabelModifyMember(
  private val `value`: String,
) {
  public fun format(name: String): String = formatString(value, arrayOf(name))
}

public class LibresFormatLabelRemoveLanguage(
  private val `value`: String,
) {
  public fun format(language: String): String = formatString(value, arrayOf(language))
}

public class LibresFormatLabelLastUpdated(
  private val `value`: String,
) {
  public fun format(name: String, date: String): String = formatString(value, arrayOf(name,date))
}

public class LibresFormatLabelLastUpdatedNoAuthor(
  private val `value`: String,
) {
  public fun format(date: String): String = formatString(value, arrayOf(date))
}

public class LibresFormatLabelTranslatedIn(
  private val `value`: String,
) {
  public fun format(percent: String): String = formatString(value, arrayOf(percent))
}

public class LibresFormatLabelRemoveTerm(
  private val `value`: String,
) {
  public fun format(term: String): String = formatString(value, arrayOf(term))
}

public class LibresFormatLabelImportCount(
  private val `value`: String,
) {
  public fun format(count: String): String = formatString(value, arrayOf(count))
}

public class LibresFormatLabelImportSkipped(
  private val `value`: String,
) {
  public fun format(count: String): String = formatString(value, arrayOf(count))
}

public class LibresFormatLabelInvitedBy(
  private val `value`: String,
) {
  public fun format(name: String, role: String): String = formatString(value, arrayOf(name,role))
}

public class LibresFormatLabelRemoveInvite(
  private val `value`: String,
) {
  public fun format(email: String): String = formatString(value, arrayOf(email))
}
