package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.repository.AuthRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.usecase.ResetPasswordUseCase.Params
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase

interface ResetPasswordUseCase : UseCase<Params, Unit> {
    data class Params(
        val email: String? = null,
    )
}

internal class ResetPasswordUseCaseImpl(
    private val authRepository: AuthRepository,
) : ResetPasswordUseCase {
    override suspend fun execute(params: Params) {
        authRepository.resetPassword(params.email)
    }
}
