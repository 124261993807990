package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.register

import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.usecase.GetIsLoggedInUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.usecase.RegisterUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.model.RegistrationRequest
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.usecase.ValidateEmailUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.invoke
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.ViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.infrastructure.mapToException
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.register.RegisterViewModel.State
import dev.gitlive.firebase.auth.FirebaseAuthException

internal class RegisterViewModel(
    private val registerUseCase: RegisterUseCase,
    private val isLoggedIn: GetIsLoggedInUseCase,
    private val validateEmail: ValidateEmailUseCase,
) : ViewModel<State>(State()) {
    fun onAppear() {
        launch {
            if (isLoggedIn().getOrElse { false }) {
                update { copy(isRegistered = true) }
            }
        }
    }

    fun register() {
        launch {
            update { copy(loading = true) }

            registerUseCase(
                RegisterUseCase.Params(
                    request =
                        RegistrationRequest.Credentials(
                            email = state.email,
                            password = state.password,
                            displayName = state.displayName,
                        ),
                ),
            ).onFailure { throwable ->
                val error =
                    if (throwable is FirebaseAuthException) {
                        throwable.mapToException()
                    } else {
                        throwable
                    }

                update { copy(error = error, loading = false) }
            }.onSuccess {
                update {
                    State().copy(isRegistered = true)
                }
            }
        }
    }

    fun onEmailChange(email: String) {
        launch {
            val isValid =
                validateEmail(
                    ValidateEmailUseCase.Params(
                        email,
                    ),
                ).getOrElse { false }

            update { copy(email = email, emailValid = isValid) }
        }
    }

    fun onPasswordChange(password: String) {
        update {
            copy(
                password = password,
                passwordValid = validatePassword(password),
            )
        }
    }

    private fun validatePassword(password: String): Boolean {
        return password.length >= 8 &&
            password.any { it.isDigit() } &&
            password.any { it.isLetter() }
    }

    fun onDisplayNameChange(displayName: String) {
        update { copy(displayName = displayName) }
    }

    fun onRepeatPasswordChange(password: String) {
        update { copy(repeatPassword = password) }
    }

    fun onRegistrationInfoShown() {
        update { copy(isRegistered = false) }
    }

    data class State(
        val email: String = "",
        val password: String = "",
        val repeatPassword: String = "",
        val displayName: String = "",
        val isRegistered: Boolean = false,
        val loading: Boolean = false,
        val error: Throwable? = null,
        val emailValid: Boolean = true,
        val passwordValid: Boolean = true,
    ) {
        val repeatPasswordValid: Boolean = password == repeatPassword || repeatPassword.isEmpty()

        val registerButtonEnabled: Boolean =
            email.isNotBlank() &&
                password.isNotBlank() &&
                repeatPassword.isNotBlank() &&
                displayName.isNotBlank() &&
                emailValid &&
                passwordValid &&
                repeatPasswordValid
    }
}
