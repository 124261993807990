package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.infrastructure.source

import com.russhwolf.settings.Settings
import cz.cvut.fit.horanvoj.ribbon.model.project.ProjectWithMembership
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.data.source.ProjectLocalSource
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.infrastructure.source.CacheLocalSource

internal class ProjectLocalSourceImpl(
    settings: Settings,
) : ProjectLocalSource, CacheLocalSource<ProjectWithMembership>(
        serializer = ProjectWithMembership.serializer(),
        cacheKey = PROJECT,
        settings = settings,
    ) {
    private companion object {
        const val PROJECT = "projects"
    }
}
