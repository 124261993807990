package cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.CSSLengthOrPercentageNumericValue
import com.varabyte.kobweb.compose.dom.ref
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.mdi.MdiAccountCircle
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.shapes.Circle
import com.varabyte.kobweb.silk.theme.shapes.clip
import cz.cvut.fit.horanvoj.ribbon.model.user.User
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.domain.imageUrl
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.toSitePalette
import org.jetbrains.compose.web.css.percent

@Composable
fun UserImage(
    user: User,
    modifier: Modifier = Modifier,
    size: CSSLengthOrPercentageNumericValue,
) {
    val imageUrl = remember(user) { user.imageUrl }

    Box(
        contentAlignment = Alignment.Center,
        modifier = modifier
            .size(size),
    ) {
        MdiAccountCircle(
            modifier = Modifier
                .color(ColorMode.current.toSitePalette().onBackground)
                .fontSize(size),
        )

        var hasAvatar by remember { mutableStateOf(true) }

        if (hasAvatar) {
            Image(
                src = imageUrl,
                modifier = Modifier
                    .clip(Circle())
                    .width(100.percent),
                ref =
                    ref {
                        it.onerror = { _, _, _, _, _ ->
                            hasAvatar = false
                            Unit
                        }
                    },
            )
        }
    }
}
