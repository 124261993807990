package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.data.repository

import cz.cvut.fit.horanvoj.ribbon.model.language.Language
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.data.source.LanguageSource
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.domain.repository.LanguageRepository

internal class LanguageRepositoryImpl(
    private val languageSource: LanguageSource,
) : LanguageRepository {
    override suspend fun getSupportedLanguages(): List<Language> {
        return languageSource.getSupportedLanguages()
    }

    override suspend fun getProjectLanguages(projectId: String): List<Language> {
        return languageSource.getProjectLanguages(projectId)
    }

    override suspend fun addLanguageToProject(
        projectId: String,
        languageId: String,
    ): List<Language> {
        return languageSource.addLanguageToProject(projectId, languageId)
    }

    override suspend fun removeLanguageFromProject(
        projectId: String,
        languageId: String,
    ): List<Language> {
        return languageSource.removeLanguageFromProject(projectId, languageId)
    }
}
