package cz.cvut.fit.horanvoj.ribbon.unwinder.web.domain

import Res
import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaAndroid
import com.varabyte.kobweb.silk.components.icons.fa.FaApple
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import cz.cvut.fit.horanvoj.ribbon.model.batch.BatchFormat
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.toSitePalette
import org.jetbrains.compose.web.css.px

val BatchFormat.displayName
    get() =
        when (this) {
            BatchFormat.JSON -> Res.string.batch_json
            BatchFormat.ANDROID -> Res.string.batch_android
            BatchFormat.APPLE -> Res.string.batch_apple
        }

@Composable
fun BatchFormat.Icon() {
    val style = ColorMode.current.toSitePalette()
    when (this) {
        BatchFormat.JSON -> {
            Image(
                src = "/ribbon-logo.webp",
                description = Res.string.app_name,
                modifier = Modifier
                    .size(24.px),
            )
        }

        BatchFormat.ANDROID -> {
            FaAndroid(
                Modifier
                    .color(style.primary)
                    .fontSize(24.px),
            )
        }

        BatchFormat.APPLE -> {
            FaApple(
                Modifier
                    .color(style.primary)
                    .padding(bottom = 4.px)
                    .fontSize(24.px),
            )
        }
    }
}
