package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.model.project.ProjectModification
import cz.cvut.fit.horanvoj.ribbon.model.project.ProjectWithMembership
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.repository.ProjectRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase.ModifyProjectUseCase.Params
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase

interface ModifyProjectUseCase : UseCase<Params, ProjectWithMembership> {
    data class Params(
        val projectId: String,
        val modification: ProjectModification,
    )
}

internal class ModifyProjectUseCaseImpl(
    private val projectRepository: ProjectRepository,
) : ModifyProjectUseCase {
    override suspend fun execute(params: Params): ProjectWithMembership {
        return projectRepository.modifyProject(
            projectId = params.projectId,
            projectModification = params.modification,
        )
    }
}
