package cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.StyleVariable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.RowScope
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.BackgroundTransition
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.toSitePalette
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun Tabs(
    modifier: Modifier = Modifier,
    content: @Composable RowScope.() -> Unit,
) {
    Row(
        modifier = modifier
            .height(48.px)
            .fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically,
    ) {
        content()
    }
}

val tabHoverColor by StyleVariable<CSSColorValue>(Colors.Gray)

val TabStyle by ComponentStyle {
    base {
        Modifier
            .flex(1)
            .height(100.percent)
            .cursor(Cursor.Pointer)
            .transition(BackgroundTransition)
    }

    hover {
        Modifier
            .backgroundColor(tabHoverColor.value())
    }
}

@Composable
fun Tab(
    text: String,
    modifier: Modifier = Modifier,
    isActive: Boolean = false,
    onClick: () -> Unit,
) {
    val style = ColorMode.current.toSitePalette()
    val hoverColor =
        if (isActive) {
            style.primary
        } else {
            style.onSurfaceVariant
        }.toRgb().copy(alpha = 20)

    Box(
        modifier = modifier
            .then(TabStyle.toModifier())
            .typography(TitleSmall)
            .setVariable(tabHoverColor, hoverColor)
            .onClick { onClick() },
    ) {
        val textColor =
            if (isActive) {
                style.primary
            } else {
                style.onSurfaceVariant
            }

        Box(
            Modifier.align(Alignment.Center)
                .height(100.percent),
        ) {
            SpanText(
                text = text,
                modifier = Modifier
                    .color(textColor)
                    .align(Alignment.Center),
            )

            if (isActive) {
                Box(
                    modifier = Modifier
                        .align(Alignment.BottomCenter)
                        .height(3.px)
                        .borderRadius {
                            topLeft(3.px)
                            topRight(3.px)
                        }
                        .backgroundColor(style.primary)
                        .width(100.percent),
                )
            }
        }
    }
}
