package cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts

import cz.cvut.fit.horanvoj.ribbon.model.project.MemberRole
import cz.cvut.fit.horanvoj.ribbon.model.project.ProjectWithMembership
import cz.cvut.fit.horanvoj.ribbon.model.user.User
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.usecase.GetLoggedInUserUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.usecase.LogoutUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase.GetProjectUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.invoke
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.ViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.NavHeaderViewModel.State

internal class NavHeaderViewModel(
    private val getLoggedInUser: GetLoggedInUserUseCase,
    private val getProject: GetProjectUseCase,
    private val logoutUseCase: LogoutUseCase,
) : ViewModel<State>(State()) {
    fun onAppear(projectId: String?) {
        launch {
            getLoggedInUser()
                .onSuccess { user ->
                    update {
                        copy(
                            user = user,
                            error = null,
                        )
                    }
                }
                .onFailure {
                    update { copy(error = it) }
                }
        }

        if (projectId != null) {
            launch {
                getProject(GetProjectUseCase.Params(projectId = projectId))
                    .onSuccess { flow ->
                        flow.collect {
                            update { copy(project = it, error = null) }
                        }
                    }
                    .onFailure { update { copy(error = it) } }
            }
        }
    }

    fun logout() {
        launch {
            logoutUseCase()
                .onSuccess {
                    clearCurrentProject()
                    update { copy(didLogOut = true, error = null) }
                }
                .onFailure {
                    update { copy(error = it) }
                }
        }
    }

    fun clearCurrentProject() {
        update { copy(project = null) }
    }

    fun setActiveTab(activeTab: ProjectTab?) {
        if (activeTab != null) {
            update { copy(currentTab = activeTab) }
        }
    }

    data class State(
        val user: User? = null,
        val project: ProjectWithMembership? = null,
        val didLogOut: Boolean = false,
        val error: Throwable? = null,
        val currentTab: ProjectTab = ProjectTab.TRANSLATE,
    ) {
        val canManage =
            project?.membership?.role in
                listOf(MemberRole.ADMINISTRATOR, MemberRole.DEVELOPER)

        val isTranslateActive = currentTab == ProjectTab.TRANSLATE
        val isManageActive = currentTab == ProjectTab.MANAGE
        val isTermsActive = currentTab == ProjectTab.TERMS
    }
}
