package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.repository.AuthRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.usecase.RegisterUseCase.Params
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.model.RegistrationRequest
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase

interface RegisterUseCase : UseCase<Params, Unit> {
    data class Params(
        val request: RegistrationRequest,
    )
}

internal class RegisterUseCaseImpl(
    private val authRepository: AuthRepository,
) : RegisterUseCase {
    override suspend fun execute(params: Params) {
        authRepository.register(params.request)
    }
}
