package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.terms

import cz.cvut.fit.horanvoj.ribbon.model.language.Language
import cz.cvut.fit.horanvoj.ribbon.model.translation.Translation
import kotlinx.datetime.Instant

sealed class TermTranslationViewObject(
    val language: Language,
) {
    data class Translated(
        private val _language: Language,
        val text: String,
        val updated: Instant,
        val authorEmail: String?,
        val authorId: String?,
    ) : TermTranslationViewObject(_language)

    data class NotTranslated(
        private val _language: Language,
    ) : TermTranslationViewObject(_language)
}

fun Translation.toViewObject(language: Language) =
    TermTranslationViewObject.Translated(
        _language = language,
        text = text,
        updated = updated,
        authorEmail = authorEmail,
        authorId = authorId,
    )
