package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.model.user.User
import cz.cvut.fit.horanvoj.ribbon.model.user.UserModification
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.usecase.ModifyLoggedInUseUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.invoke
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.domain.repository.UserRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.domain.usecase.ModifyUserUseCase.Params
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase

interface ModifyUserUseCase : UseCase<Params, User> {
    data class Params(
        val modification: UserModification,
    )
}

internal class ModifyUserUseCaseImpl(
    private val userRepository: UserRepository,
    private val modifyLoggedInUser: ModifyLoggedInUseUseCase,
) : ModifyUserUseCase {
    override suspend fun execute(params: Params): User {
        val user =
            userRepository.modifyUser(
                userModification = params.modification,
            )
        modifyLoggedInUser(ModifyLoggedInUseUseCase.Params(params.modification))
        return user
    }
}
