package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.model.term.Term
import cz.cvut.fit.horanvoj.ribbon.model.term.TermModification
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term.domain.repository.TermRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term.domain.usecase.UpdateTermUseCase.Params
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase

interface UpdateTermUseCase : UseCase<Params, Term> {
    data class Params(
        val projectId: String,
        val termId: String,
        val modification: TermModification,
    )
}

internal class UpdateTermUseCaseImpl(
    private val termRepository: TermRepository,
) : UpdateTermUseCase {
    override suspend fun execute(params: Params): Term {
        return termRepository.updateTerm(
            projectId = params.projectId,
            termId = params.termId,
            modification = params.modification,
        )
    }
}
