package cz.cvut.fit.horanvoj.ribbon.model.project

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
enum class MemberRole {
    @SerialName("administrator")
    ADMINISTRATOR,

    @SerialName("developer")
    DEVELOPER,

    @SerialName("translator")
    TRANSLATOR,
}
