package cz.cvut.fit.horanvoj.ribbon.model.common

import kotlinx.serialization.Serializable

@Serializable
data class Pagination(
    val size: Int,
    val cursor: String?,
    val next: String?,
    val previous: String?,
)
