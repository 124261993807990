package cz.cvut.fit.horanvoj.ribbon.unwinder.web

import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.colors.palette.background
import com.varabyte.kobweb.silk.theme.colors.palette.border
import com.varabyte.kobweb.silk.theme.colors.palette.color
import com.varabyte.kobweb.silk.theme.colors.palette.overlay

private val md_theme_light_primary = Color.argb(0xFF006A65)
private val md_theme_light_onPrimary = Color.argb(0xFFFFFFFF)
private val md_theme_light_primaryContainer = Color.argb(0xFF70F7EE)
private val md_theme_light_onPrimaryContainer = Color.argb(0xFF00201E)
private val md_theme_light_secondary = Color.argb(0xFF4A6361)
private val md_theme_light_onSecondary = Color.argb(0xFFFFFFFF)
private val md_theme_light_secondaryContainer = Color.argb(0xFFCCE8E5)
private val md_theme_light_onSecondaryContainer = Color.argb(0xFF051F1E)
private val md_theme_light_tertiary = Color.argb(0xFF48607B)
private val md_theme_light_onTertiary = Color.argb(0xFFFFFFFF)
private val md_theme_light_tertiaryContainer = Color.argb(0xFFD0E4FF)
private val md_theme_light_onTertiaryContainer = Color.argb(0xFF001D34)
private val md_theme_light_error = Color.argb(0xFFBA1A1A)
private val md_theme_light_errorContainer = Color.argb(0xFFFFDAD6)
private val md_theme_light_successContainer = Color.argb(0xFFBCF0B3)
private val md_theme_light_onError = Color.argb(0xFFFFFFFF)
private val md_theme_light_onErrorContainer = Color.argb(0xFF410002)
private val md_theme_light_onSuccessContainer = Color.argb(0xFF002105)
private val md_theme_light_background = Color.argb(0xFFFAFDFB)
private val md_theme_light_onBackground = Color.argb(0xFF191C1C)
private val md_theme_light_surface = Color.argb(0xFFFAFDFB)
private val md_theme_light_onSurface = Color.argb(0xFF191C1C)
private val md_theme_light_surfaceVariant = Color.argb(0xFFDAE5E3)
private val md_theme_light_onSurfaceVariant = Color.argb(0xFF3F4948)
private val md_theme_light_outline = Color.argb(0xFF6F7978)
private val md_theme_light_inverseOnSurface = Color.argb(0xFFEFF1F0)
private val md_theme_light_inverseSurface = Color.argb(0xFF2D3131)
private val md_theme_light_inversePrimary = Color.argb(0xFF4FDAD2)
private val md_theme_light_shadow = Color.argb(0xFF000000)
private val md_theme_light_surfaceTint = Color.argb(0xFF006A65)
private val md_theme_light_outlineVariant = Color.argb(0xFFBEC9C7)
private val md_theme_light_scrim = Color.argb(0x64000000)

private val md_theme_dark_primary = Color.argb(0xFF4FDAD2)
private val md_theme_dark_onPrimary = Color.argb(0xFF003734)
private val md_theme_dark_primaryContainer = Color.argb(0xFF00504C)
private val md_theme_dark_onPrimaryContainer = Color.argb(0xFF70F7EE)
private val md_theme_dark_secondary = Color.argb(0xFFB0CCC9)
private val md_theme_dark_onSecondary = Color.argb(0xFF1B3533)
private val md_theme_dark_secondaryContainer = Color.argb(0xFF324B49)
private val md_theme_dark_onSecondaryContainer = Color.argb(0xFFCCE8E5)
private val md_theme_dark_tertiary = Color.argb(0xFFB0C9E7)
private val md_theme_dark_onTertiary = Color.argb(0xFF19324A)
private val md_theme_dark_tertiaryContainer = Color.argb(0xFF304962)
private val md_theme_dark_onTertiaryContainer = Color.argb(0xFFD0E4FF)
private val md_theme_dark_error = Color.argb(0xFFFFB4AB)
private val md_theme_dark_errorContainer = Color.argb(0xFF93000A)
private val md_theme_dark_successContainer = Color.argb(0xFFBCF0B3)
private val md_theme_dark_onError = Color.argb(0xFF690005)
private val md_theme_dark_onErrorContainer = Color.argb(0xFFFFDAD6)
private val md_theme_dark_onSuccessContainer = Color.argb(0xFF002105)
private val md_theme_dark_background = Color.argb(0xFF191C1C)
private val md_theme_dark_onBackground = Color.argb(0xFFE0E3E2)
private val md_theme_dark_surface = Color.argb(0xFF191C1C)
private val md_theme_dark_onSurface = Color.argb(0xFFE0E3E2)
private val md_theme_dark_surfaceVariant = Color.argb(0xFF3F4948)
private val md_theme_dark_onSurfaceVariant = Color.argb(0xFFBEC9C7)
private val md_theme_dark_outline = Color.argb(0xFF889391)
private val md_theme_dark_inverseOnSurface = Color.argb(0xFF191C1C)
private val md_theme_dark_inverseSurface = Color.argb(0xFFE0E3E2)
private val md_theme_dark_inversePrimary = Color.argb(0xFF006A65)
private val md_theme_dark_shadow = Color.argb(0xFF000000)
private val md_theme_dark_surfaceTint = Color.argb(0xFF4FDAD2)
private val md_theme_dark_outlineVariant = Color.argb(0xFF3F4948)
private val md_theme_dark_scrim = Color.argb(0x64000000)

private val seed = Color.argb(0xFF025955)

class SitePalette(
    val background: Color,
    val onBackground: Color,
    val surface: Color,
    val onSurface: Color,
    val surfaceVariant: Color,
    val onSurfaceVariant: Color,
    val inverseSurface: Color,
    val inverseOnSurface: Color,
    val primaryContainer: Color,
    val onPrimaryContainer: Color,
    val secondaryContainer: Color,
    val onSecondaryContainer: Color,
    val tertiaryContainer: Color,
    val onTertiaryContainer: Color,
    val primary: Color,
    val onPrimary: Color,
    val secondary: Color,
    val onSecondary: Color,
    val outline: Color,
    val outlineVariant: Color,
    val error: Color,
    val onError: Color,
    val errorContainer: Color,
    val onErrorContainer: Color,
    val successContainer: Color,
    val onSuccessContainer: Color,
    val shadow: Color,
)

object SitePalettes {
    val light =
        SitePalette(
            background = md_theme_light_background,
            onBackground = md_theme_light_onBackground,
            surface = md_theme_light_surface,
            onSurface = md_theme_light_onSurface,
            surfaceVariant = md_theme_light_surfaceVariant,
            onSurfaceVariant = md_theme_light_onSurfaceVariant,
            primary = md_theme_light_primary,
            onPrimary = md_theme_light_onPrimary,
            secondary = md_theme_light_secondary,
            onSecondary = md_theme_light_onSecondary,
            outline = md_theme_light_outline,
            outlineVariant = md_theme_light_outlineVariant,
            error = md_theme_light_error,
            onError = md_theme_light_onError,
            errorContainer = md_theme_light_errorContainer,
            onErrorContainer = md_theme_light_onErrorContainer,
            shadow = md_theme_light_shadow,
            secondaryContainer = md_theme_light_secondaryContainer,
            onSecondaryContainer = md_theme_light_onSecondaryContainer,
            primaryContainer = md_theme_light_primaryContainer,
            onPrimaryContainer = md_theme_light_onPrimaryContainer,
            inverseSurface = md_theme_light_inverseSurface,
            inverseOnSurface = md_theme_light_inverseOnSurface,
            successContainer = md_theme_light_successContainer,
            onSuccessContainer = md_theme_light_onSuccessContainer,
            tertiaryContainer = md_theme_light_tertiaryContainer,
            onTertiaryContainer = md_theme_light_onTertiaryContainer,
        )
    val dark =
        SitePalette(
            background = md_theme_dark_background,
            onBackground = md_theme_dark_onBackground,
            surface = md_theme_dark_surface,
            onSurface = md_theme_dark_onSurface,
            surfaceVariant = md_theme_dark_surfaceVariant,
            onSurfaceVariant = md_theme_dark_onSurfaceVariant,
            primary = md_theme_dark_primary,
            onPrimary = md_theme_dark_onPrimary,
            secondary = md_theme_dark_secondary,
            onSecondary = md_theme_dark_onSecondary,
            outline = md_theme_dark_outline,
            outlineVariant = md_theme_dark_outlineVariant,
            error = md_theme_dark_error,
            onError = md_theme_dark_onError,
            errorContainer = md_theme_dark_errorContainer,
            onErrorContainer = md_theme_dark_onErrorContainer,
            shadow = md_theme_dark_shadow,
            secondaryContainer = md_theme_dark_secondaryContainer,
            onSecondaryContainer = md_theme_dark_onSecondaryContainer,
            primaryContainer = md_theme_dark_primaryContainer,
            onPrimaryContainer = md_theme_dark_onPrimaryContainer,
            inverseSurface = md_theme_dark_inverseSurface,
            inverseOnSurface = md_theme_dark_inverseOnSurface,
            successContainer = md_theme_dark_successContainer,
            onSuccessContainer = md_theme_dark_onSuccessContainer,
            tertiaryContainer = md_theme_dark_tertiaryContainer,
            onTertiaryContainer = md_theme_dark_onTertiaryContainer,
        )
}

fun ColorMode.toSitePalette(): SitePalette {
    return when (this) {
        ColorMode.LIGHT -> SitePalettes.light
        ColorMode.DARK -> SitePalettes.dark
    }
}

@InitSilk
fun initTheme(ctx: InitSilkContext) {
    ctx.theme.palettes.light.background = md_theme_light_background
    ctx.theme.palettes.light.color = md_theme_light_onBackground
    ctx.theme.palettes.light.border = md_theme_light_outline
    ctx.theme.palettes.light.overlay = md_theme_light_scrim

    ctx.theme.palettes.dark.background = md_theme_dark_background
    ctx.theme.palettes.dark.color = md_theme_dark_onBackground
    ctx.theme.palettes.dark.border = md_theme_dark_outline
    ctx.theme.palettes.dark.overlay = md_theme_dark_scrim
}
