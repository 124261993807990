package cz.cvut.fit.horanvoj.ribbon.unwinder.web.domain

import Res
import cz.cvut.fit.horanvoj.ribbon.model.common.Order

val Order.displayName: String
    get() =
        when (this) {
            Order.UPDATED_ASC -> Res.string.label_order_updated_asc
            Order.UPDATED_DESC -> Res.string.label_order_updated_desc
        }
