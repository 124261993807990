package cz.cvut.fit.horanvoj.ribbon.model.user

import kotlinx.serialization.Serializable

@Serializable
data class UserCreation(
    val name: String,
    val email: String,
    val password: String,
)
