package cz.cvut.fit.horanvoj.ribbon.model.project

import kotlinx.serialization.Serializable

@Serializable
enum class RolePermission {
    REMOVE_PROJECT,
    MODIFY_PROJECT,
    MODIFY_PROJECT_MEMBERS,
    MODIFY_PROJECT_LANGUAGES,

    CREATE_API_KEY,
    REMOVE_API_KEY,
    READ_API_KEY,
    CREATE_OTP,
    IMPORT,

    UPDATE_TERM,
    CREATE_TERM,
    REMOVE_TERM,

    CREATE_TRANSLATION,
    MODIFY_TRANSLATION,
    REMOVE_TRANSLATION,

    READ_INVITES,
}
