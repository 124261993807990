package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.infrastructure.api

import cz.cvut.fit.horanvoj.ribbon.model.user.User
import cz.cvut.fit.horanvoj.ribbon.model.user.UserCreation
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.use
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*

internal interface AuthApi {
    suspend fun login(): User

    suspend fun register(userCreation: UserCreation): User
}

internal class AuthApiImpl(
    private val client: HttpClient,
) : AuthApi {
    override suspend fun login(): User {
        return client.use {
            post("api/v1/auth/login")
                .body()
        }
    }

    override suspend fun register(userCreation: UserCreation): User {
        return client.use {
            post("api/v1/auth/register") {
                setBody(userCreation)
            }.body()
        }
    }
}
