package cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.filter

import Res
import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.silk.components.icons.mdi.MdiFilterList
import cz.cvut.fit.horanvoj.ribbon.model.common.Order
import cz.cvut.fit.horanvoj.ribbon.model.term.TermFilter
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.MaterialDialog
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.MaterialTextButton
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.RadioButtonItem
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.RadioButtonItemGroup
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.domain.displayName
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.fadeInAnimation
import org.jetbrains.compose.web.dom.Text

@Composable
fun TermFilterDialog(
    order: Order,
    filter: TermFilter,
    onConfirmed: (TermFilter, Order) -> Unit,
    onCancelled: () -> Unit,
) {
    var internalOrder by remember(order) { mutableStateOf(order) }
    var internalFilter by remember(filter) { mutableStateOf(filter) }

    MaterialDialog(
        modifier = Modifier
            .fadeInAnimation(),
        icon = {
            MdiFilterList()
        },
        headline = {
            Text(Res.string.title_filter)
        },
        onDismiss = onCancelled,
        buttons = {
            MaterialTextButton(
                onClick = { onCancelled() },
            ) {
                Text(Res.string.button_cancel)
            }
            MaterialTextButton(
                onClick = {
                    onConfirmed(internalFilter, internalOrder)
                },
            ) {
                Text(Res.string.button_confirm)
            }
        },
        content = {
            Column(
                modifier = Modifier
                    .gap(SpaceMedium),
            ) {
                Text(Res.string.label_order_by)

                RadioButtonItemGroup {
                    Order.entries.forEach { orderItem ->
                        RadioButtonItem(
                            isChecked = orderItem == internalOrder,
                            onSelect = {
                                internalOrder = orderItem
                            },
                        ) {
                            Text(orderItem.displayName)
                        }
                    }
                }

                Text(Res.string.label_filter)

                RadioButtonItemGroup {
                    TermFilter.entries.forEach { filterIterm ->
                        RadioButtonItem(
                            isChecked = filterIterm == internalFilter,
                            onSelect = {
                                internalFilter = filterIterm
                            },
                        ) {
                            Text(filterIterm.displayName)
                        }
                    }
                }
            }
        },
    )
}
