package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.batch.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.model.batch.BatchFormat
import cz.cvut.fit.horanvoj.ribbon.model.batch.ImportResult
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.batch.domain.repository.BatchRepository
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase

interface ImportUseCase : UseCase<ImportUseCase.Params, ImportResult> {
    data class Params(
        val projectId: String,
        val languageId: String,
        val format: BatchFormat,
        val importData: String,
        val overrideExisting: Boolean,
    )
}

internal class ImportUseCaseImpl(
    private val batchRepository: BatchRepository,
) : ImportUseCase {
    override suspend fun execute(params: ImportUseCase.Params): ImportResult {
        return batchRepository.import(
            projectId = params.projectId,
            languageId = params.languageId,
            format = params.format,
            importData = params.importData,
            overrideExisting = params.overrideExisting,
        )
    }
}
