package cz.cvut.fit.horanvoj.ribbon.model.language

import kotlinx.serialization.Serializable

@Serializable
data class Language(
    val languageId: String,
    val languageName: String,
    val localLanguageName: String,
    val countryEmoji: String?,
)
