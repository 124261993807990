package cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens

import org.jetbrains.compose.web.css.px

val CornerSizeExtraSmall = 4.px
val CornerSizeSmall = 8.px
val CornerSizeMedium = 12.px
val CornerSizeLarge = 16.px
val CornerSizeExtraLarge = 28.px
val CornerSizeRound = 9999.px
