package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.usecase.ValidateEmailUseCase.Params
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase

interface ValidateEmailUseCase : UseCase<Params, Boolean> {
    data class Params(
        val email: String,
    )
}

internal class ValidateEmailUseCaseImpl : ValidateEmailUseCase {
    override suspend fun execute(params: Params): Boolean {
        return REGEX.matches(params.email)
    }

    companion object {
        @Suppress("ktlint:standard:max-line-length")
        private val REGEX =
            """^[a-zA-Z0-9.!#${'$'}%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*${'$'}"""
                .toRegex()
    }
}
