package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.translate

import cz.cvut.fit.horanvoj.ribbon.model.common.Order
import cz.cvut.fit.horanvoj.ribbon.model.common.Paging
import cz.cvut.fit.horanvoj.ribbon.model.language.Language
import cz.cvut.fit.horanvoj.ribbon.model.term.Term
import cz.cvut.fit.horanvoj.ribbon.model.term.TermFilter
import cz.cvut.fit.horanvoj.ribbon.model.translation.TranslationUpdate
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.domain.usecase.GetProjectLanguagesUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.invoke
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term.domain.usecase.GetTermsUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term.domain.usecase.QueryTermsUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.translation.domain.usecase.DeleteTranslationUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.translation.domain.usecase.SetTranslationUseCase
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.ViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.translate.TranslateViewModel.State

internal class TranslateViewModel(
    private val getTermsUseCase: GetTermsUseCase,
    private val setTranslation: SetTranslationUseCase,
    private val deleteTranslation: DeleteTranslationUseCase,
    private val getProjectLanguages: GetProjectLanguagesUseCase,
    private val queryTerms: QueryTermsUseCase,
) : ViewModel<State>(State()) {
    fun onAppear(
        projectId: String,
        languageId: String,
        query: String?,
        cursor: String?,
        referenceLanguage: String?,
        order: Order?,
        filter: TermFilter?,
    ) {
        launch {
            if (state.language?.languageId != languageId || state.referenceLanguage?.languageId != referenceLanguage) {
                getProjectLanguages(
                    GetProjectLanguagesUseCase.Params(
                        projectId = projectId,
                    ),
                ).onSuccess { languages ->
                    val language = languages.firstOrNull { it.languageId == languageId }
                    if (language != null) {
                        update { copy(language = language) }
                    } else {
                        update { copy(error = IllegalArgumentException("Not found")) }
                        return@launch
                    }

                    if (!referenceLanguage.isNullOrEmpty()) {
                        val refLanguage = languages.firstOrNull { it.languageId == referenceLanguage }
                        if (refLanguage != null) {
                            update { copy(referenceLanguage = refLanguage) }
                        } else {
                            update { copy(error = IllegalArgumentException("Not found")) }
                            return@launch
                        }
                    }
                }.onFailure {
                    update { copy(error = it) }
                    return@launch
                }
            }

            update {
                copy(
                    projectId = projectId,
                    filter = filter ?: this.filter,
                    order = order ?: this.order,
                )
            }

            loadPage(query, cursor)
        }
    }

    private suspend fun loadPage(
        query: String?,
        cursor: String?,
    ) {
        val projectId = state.projectId ?: return
        update { copy(loading = true) }
        val includeLanguageIds =
            listOfNotNull(
                state.language?.languageId,
                state.referenceLanguage?.languageId,
            )

        val terms =
            if (query.isNullOrEmpty()) {
                getTermsUseCase(
                    GetTermsUseCase.Params(
                        projectId = projectId,
                        cursor = cursor,
                        includeLanguageIds = includeLanguageIds,
                        filter = state.filter,
                        order = state.order,
                        filterLanguage = state.language?.languageId,
                    ),
                )
            } else {
                queryTerms(
                    QueryTermsUseCase.Params(
                        projectId = projectId,
                        cursor = cursor,
                        query = query,
                        includeLanguageIds = includeLanguageIds,
                        filter = state.filter,
                        order = state.order,
                        filterLanguage = state.language?.languageId,
                    ),
                )
            }.onFailure {
                update { copy(error = it, loading = false) }
            }.getOrNull() ?: return

        update {
            copy(
                loading = false,
                termsPaged = terms,
            )
        }
    }

    suspend fun onSaveTranslation(
        termId: String,
        translation: String,
    ): Boolean {
        val projectId = state.projectId ?: return false
        val languageId = state.language?.languageId ?: return false
        val currentTranslation = state.translations.firstOrNull { it.termId == termId }

        if (translation.isEmpty() && currentTranslation != null) {
            deleteTranslation(
                DeleteTranslationUseCase.Params(
                    projectId = projectId,
                    languageId = languageId,
                    termId = termId,
                ),
            )
        } else {
            setTranslation(
                SetTranslationUseCase.Params(
                    projectId = projectId,
                    termId = termId,
                    translation =
                        TranslationUpdate(
                            languageId = languageId,
                            text = translation,
                        ),
                ),
            )
        }.onFailure {
            update { copy(error = it) }
            return false
        }.onSuccess { updated ->
            update {
                copy(
                    termsPaged =
                        termsPaged?.copy(
                            items =
                                termsPaged.items.map { term ->
                                    if (term.id == updated.id) {
                                        updated
                                    } else {
                                        term
                                    }
                                },
                        ),
                )
            }
        }

        return true
    }

    fun onSelectReferenceLanguage() {
        update { copy(selectingReferenceLanguage = true) }
    }

    fun onReferenceLanguageDialogDismiss() {
        update { copy(selectingReferenceLanguage = false) }
    }

    fun onReferenceLanguageSelected() {
        update { copy(selectingReferenceLanguage = false) }
    }

    fun onFilterClicked() {
        update { copy(filteringTerms = true) }
    }

    fun onFilterCancelled() {
        update { copy(filteringTerms = false) }
    }

    fun onFilterConfirmed() {
        update { copy(filteringTerms = false) }
    }

    data class State(
        val projectId: String? = null,
        val language: Language? = null,
        val termsPaged: Paging<Term>? = null,
        val referenceLanguage: Language? = null,
        val loading: Boolean = true,
        val error: Throwable? = null,
        val selectingReferenceLanguage: Boolean = false,
        val filteringTerms: Boolean = false,
        val filter: TermFilter = TermFilter.ALL,
        val order: Order = Order.UPDATED_DESC,
    ) {
        val translations =
            termsPaged?.items.orEmpty().map { term ->
                val referenceTranslation =
                    if (referenceLanguage == null) {
                        null
                    } else {
                        term.translations
                            ?.firstOrNull { tr ->
                                tr.languageId == referenceLanguage.languageId
                            }?.text
                    }

                val translation =
                    term.translations
                        ?.firstOrNull { tr ->
                            tr.languageId == language?.languageId
                        }?.text

                TranslationViewObject(
                    termId = term.id,
                    referenceTranslation = referenceTranslation,
                    translation = translation,
                    description = term.description,
                )
            }

        val nextPage: String? = termsPaged?.pagination?.next
        val previousPage: String? = termsPaged?.pagination?.previous
        val hasNextPage = nextPage != null
        val hasPreviousPage = previousPage != null
    }
}
