package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.model

import kotlinx.datetime.Clock
import kotlinx.serialization.Serializable

@Serializable
data class CacheKey<out T>(
    val timestamp: Long,
    val value: T,
) {
    constructor(value: T) : this(
        value = value,
        timestamp = Clock.System.now().epochSeconds,
    )
}
