package cz.cvut.fit.horanvoj.ribbon.unwinder.web.infrastructure

import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.usecase.GetAuthTokenUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.invoke
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.FirebaseApp
import dev.gitlive.firebase.auth.auth
import org.koin.dsl.module

val infrastructureModule =
    module {
        single {
            createHttpClient {
                get<GetAuthTokenUseCase>()().getOrNull()
            }
        }

        single { configureFirebase() }

        factory {
            get<FirebaseApp>() // Ensure firebase is configured
            Firebase.auth
        }
    }
