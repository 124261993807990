package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.repository.AuthRepository
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCaseNoParams

interface GetAuthTokenUseCase : UseCaseNoParams<String>

internal class GetAuthTokenUseCaseImpl(
    private val authRepository: AuthRepository,
) : GetAuthTokenUseCase {
    override suspend fun execute(): String {
        return authRepository.getToken()
    }
}
