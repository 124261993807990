package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.members

import cz.cvut.fit.horanvoj.ribbon.model.invite.Invite
import cz.cvut.fit.horanvoj.ribbon.model.invite.InviteCreation
import cz.cvut.fit.horanvoj.ribbon.model.project.*
import cz.cvut.fit.horanvoj.ribbon.model.user.User
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.usecase.GetLoggedInUserUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase.*
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.invoke
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.ViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.domain.hasPermission
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.members.MemberViewModel.State
import kotlinx.coroutines.flow.first

internal class MemberViewModel(
    private val getProject: GetProjectUseCase,
    private val inviteMember: InviteMemberUseCase,
    private val getProjectMembers: GetProjectMembersUseCase,
    private val removeProjectMember: RemoveMemberUseCase,
    private val modifyMemberRole: ModifyMemberRoleUseCase,
    private val getLoggedInUser: GetLoggedInUserUseCase,
    private val getProjectInvites: GetProjectInvitesUseCase,
    private val removeInvite: RemoveInviteUseCase,
) : ViewModel<State>(State()) {
    fun onAppear(projectId: String) {
        update { copy(projectId = projectId) }
        launch { fetchUser() }
        launch { fetchMembers() }
        launch { fetchInvites() }
        launch { fetchProject() }
    }

    private suspend fun fetchUser() {
        getLoggedInUser()
            .onSuccess {
                update { copy(currentUser = it) }
            }
            .onFailure {
                update { copy(error = it) }
            }
    }

    private suspend fun fetchMembers() {
        val projectId = state.projectId ?: return

        update { copy(membersLoading = true, error = null) }
        getProjectMembers(
            GetProjectMembersUseCase.Params(
                projectId = projectId,
            ),
        ).onSuccess {
            update { copy(membersLoading = false, members = it) }
        }.onFailure {
            update { copy(membersLoading = false, error = it) }
        }
    }

    private suspend fun fetchInvites() {
        val projectId = state.projectId ?: return

        update { copy(invitesLoading = true, error = null) }
        getProjectInvites(
            GetProjectInvitesUseCase.Params(
                projectId = projectId,
            ),
        ).onSuccess {
            update { copy(invitesLoading = false, invites = it) }
        }.onFailure {
            update { copy(invitesLoading = false, error = it) }
        }
    }

    private suspend fun fetchProject() {
        val projectId = state.projectId ?: return
        update { copy(projectLoading = true, error = null) }
        getProject(
            GetProjectUseCase.Params(
                projectId = projectId,
            ),
        ).onSuccess { projectFlow ->
            val project = projectFlow.first()
            update { copy(project = project, projectLoading = false) }
        }.onFailure {
            update { copy(error = it, projectLoading = false) }
        }
    }

    fun onRemoveMemberClick(user: User) {
        update { copy(removingMember = user) }
    }

    fun onRemoveMemberCancelled() {
        update { copy(removingMember = null) }
    }

    fun onRemoveMemberConfirmed() {
        val projectId = state.projectId ?: return
        val user = state.removingMember ?: return
        update { copy(removingMember = null, membersLoading = true) }
        launch {
            removeProjectMember(
                RemoveMemberUseCase.Params(
                    projectId = projectId,
                    userId = user.id,
                ),
            ).onSuccess {
                if (user.id == state.currentUser?.id) {
                    update { copy(removedSelf = true) }
                } else {
                    update { copy(members = it, membersLoading = false) }
                }
            }.onFailure {
                update { copy(error = it, membersLoading = false) }
            }
        }
    }

    fun onInviteMemberClick() {
        update {
            copy(
                invitingMember = true,
                invitedMember = false,
            )
        }
    }

    fun onInviteMemberCancelled() {
        update { copy(invitingMember = false) }
    }

    fun onMemberInviteConfirmed(
        userEmail: String,
        role: MemberRole,
    ) {
        val projectId = state.projectId ?: return
        update {
            copy(
                invitingMember = false,
                invitesLoading = true,
            )
        }
        launch {
            inviteMember(
                InviteMemberUseCase.Params(
                    invite =
                        InviteCreation(
                            projectId = projectId,
                            userEmail = userEmail.trim(),
                            role = role,
                        ),
                ),
            ).onSuccess {
                fetchInvites()
                update { copy(invitedMember = true, invitesLoading = false) }
            }.onFailure {
                update { copy(error = it, invitesLoading = false) }
            }
        }
    }

    fun onModifyMemberRoleClick(user: User) {
        val modifyingMember = state.members
            .firstOrNull { it.user.id == user.id }
        update { copy(modifyingMember = modifyingMember) }
    }

    fun onModifyMemberCancelled() {
        update { copy(modifyingMember = null) }
    }

    fun onModifyMemberConfirmed(role: MemberRole) {
        val projectId = state.projectId ?: return
        val user = state.modifyingMember ?: return
        update { copy(modifyingMember = null, membersLoading = true) }
        launch {
            modifyMemberRole(
                ModifyMemberRoleUseCase.Params(
                    projectId = projectId,
                    userId = user.user.id,
                    modification =
                        MemberModification(
                            role = role,
                        ),
                ),
            ).onSuccess {
                update { copy(members = it, membersLoading = false) }
            }.onFailure {
                update { copy(error = it, membersLoading = false) }
            }
        }
    }

    fun onRemoveInviteClick(invite: Invite) {
        update { copy(removingInvite = invite) }
    }

    fun onRemoveInviteCancelled() {
        update { copy(removingInvite = null) }
    }

    fun onRemoveInviteConfirmed() {
        val invite = state.removingInvite ?: return
        val projectId = state.projectId ?: return
        update { copy(removingInvite = null) }

        launch {
            update { copy(invitesLoading = true) }
            removeInvite(
                RemoveInviteUseCase.Params(
                    projectId = projectId,
                    inviteId = invite.inviteId,
                ),
            ).onSuccess {
                fetchInvites()
            }.onFailure {
                update { copy(invitesLoading = false, error = it) }
            }
        }
    }

    data class State(
        val currentUser: User? = null,
        val project: ProjectWithMembership? = null,
        val members: List<ProjectMember> = emptyList(),
        val invites: List<Invite> = emptyList(),
        val projectId: String? = null,
        val invitedMember: Boolean = false,
        val invitingMember: Boolean = false,
        val modifyingMember: ProjectMember? = null,
        val removingMember: User? = null,
        val removingInvite: Invite? = null,
        val membersLoading: Boolean = true,
        val invitesLoading: Boolean = true,
        val projectLoading: Boolean = true,
        val removedSelf: Boolean = false,
        val error: Throwable? = null,
    ) {
        val loading = projectLoading || membersLoading || invitesLoading

        val canModifyMembers = project?.membership.hasPermission(RolePermission.MODIFY_PROJECT_MEMBERS)
        val canInviteMembers = project?.membership.hasPermission(RolePermission.MODIFY_PROJECT_MEMBERS)

        val modifiableMembers =
            members.map { member ->
                member.user.id
            }.filter { userId ->
                canModify(userId)
            }.toSet()

        private fun canModify(userId: String): Boolean {
            if (members.size == 1) {
                return false
            }

            val admins = members.filter { it.role == MemberRole.ADMINISTRATOR }
            if (canModifyMembers) {
                // If project has only one admin, and it's this [userId]
                return !admins.all { admin -> admin.user.id == userId }
            }
            return false
        }
    }
}
