package cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.core.rememberPageContext
import cz.cvut.fit.horanvoj.ribbon.model.project.MemberRole
import cz.cvut.fit.horanvoj.ribbon.model.project.RolePermission
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.usecase.GetIsLoggedInUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase.GetProjectUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.invoke
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.routeToLogin
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.routeToProjects
import kotlinx.coroutines.flow.first
import org.koin.compose.koinInject

@Composable
internal fun RequireAuthenticationEffect() {
    val ctx = rememberPageContext()
    val getIsLoggedIn = koinInject<GetIsLoggedInUseCase>()

    LaunchedEffect(Unit) {
        val isLoggedIn =
            getIsLoggedIn()
                .getOrElse { false }

        if (!isLoggedIn) {
            ctx.router.routeToLogin(returnTo = ctx.route.pathQueryAndFragment)
        }
    }
}

@Composable
internal fun RequireProjectRoleEffect(vararg roles: MemberRole) {
    val ctx = rememberPageContext()
    val getProjectUseCase = koinInject<GetProjectUseCase>()
    val projectId = ctx.route.params["projectId"]

    LaunchedEffect(Unit) {
        if (roles.isNotEmpty() && projectId != null) {
            val hasRole =
                getProjectUseCase(
                    GetProjectUseCase.Params(
                        projectId = projectId,
                    ),
                ).map {
                    it.first().membership.role in roles
                }.getOrElse { false }

            if (!hasRole) {
                ctx.router.routeToProjects()
            }
        }
    }
}

@Composable
internal fun RequireProjectPermissionEffect(vararg permissions: RolePermission) {
    val ctx = rememberPageContext()
    val getProjectUseCase = koinInject<GetProjectUseCase>()
    val projectId = ctx.route.params["projectId"]

    LaunchedEffect(Unit) {
        if (permissions.isNotEmpty() && projectId != null) {
            val hasPermissions =
                getProjectUseCase(
                    GetProjectUseCase.Params(
                        projectId = projectId,
                    ),
                ).map {
                    it.first().membership.permissions
                        .containsAll(permissions.toList())
                }.getOrElse { false }

            if (!hasPermissions) {
                ctx.router.routeToProjects()
            }
        }
    }
}
