package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.members

import Res
import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.silk.components.text.SpanText
import cz.cvut.fit.horanvoj.ribbon.model.project.MemberRole
import cz.cvut.fit.horanvoj.ribbon.model.project.ProjectMember
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.*
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.domain.displayName
import org.jetbrains.compose.web.dom.Text

@Composable
fun ModifyMemberDialog(
    member: ProjectMember,
    onConfirmed: (MemberRole) -> Unit,
    onCancelled: () -> Unit,
) {
    var role: MemberRole by remember { mutableStateOf(member.role) }

    MaterialDialog(
        headline = {
            Text(Res.string.title_modify_member)
        },
        onDismiss = onCancelled,
        buttons = {
            MaterialTextButton(
                onClick = {
                    onCancelled()
                },
            ) {
                Text(Res.string.button_cancel)
            }
            MaterialTextButton(
                onClick = {
                    onConfirmed(role)
                },
            ) {
                Text(Res.string.button_confirm)
            }
        },
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .gap(SpaceMedium),
        ) {
            SpanText(
                text = Res.string.label_modify_member.format(name = member.user.name),
                modifier = Modifier
                    .typography(BodyMedium),
            )

            RadioButtonItemGroup {
                MemberRole.entries.forEach { thisRole ->
                    RadioButtonItem(
                        isChecked = thisRole == role,
                        onSelect = {
                            role = thisRole
                        },
                    ) {
                        Text(thisRole.displayName)
                    }
                }
            }
        }
    }
}
