package cz.cvut.fit.horanvoj.ribbon.unwinder.web.infrastructure

import io.ktor.client.plugins.auth.*
import io.ktor.client.request.*
import io.ktor.http.*
import io.ktor.http.auth.*

fun Auth.firebase(block: FirebaseAuthConfig.() -> Unit) {
    with(FirebaseAuthConfig().apply(block)) {
        this@firebase.providers.add(FirebaseAuthProvider(getTokenFn))
    }
}

class FirebaseAuthConfig {
    internal var getTokenFn: suspend () -> String? = { null }

    fun getToken(block: suspend () -> String?) {
        getTokenFn = block
    }
}

class FirebaseAuthProvider(
    private val getToken: suspend () -> String?,
) : AuthProvider {
    @Deprecated(
        "Please use sendWithoutRequest function instead",
        ReplaceWith("sendWithoutRequest(request)"),
    )
    override val sendWithoutRequest: Boolean
        get() = true

    override fun sendWithoutRequest(request: HttpRequestBuilder) = true

    override suspend fun addRequestHeaders(
        request: HttpRequestBuilder,
        authHeader: HttpAuthHeader?,
    ) {
        val token = buildAuthToken() ?: return

        request.headers {
            if (contains(HttpHeaders.Authorization)) {
                remove(HttpHeaders.Authorization)
            }
            append(HttpHeaders.Authorization, token)
        }
    }

    private suspend fun buildAuthToken(): String? =
        getToken()
            ?.let { token -> "${AuthScheme.Bearer} $token" }

    override fun isApplicable(auth: HttpAuthHeader) = true
}
