package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.infrastructure.source

import com.russhwolf.settings.ExperimentalSettingsApi
import com.russhwolf.settings.Settings
import com.russhwolf.settings.serialization.nullableSerializedValue
import cz.cvut.fit.horanvoj.ribbon.model.user.User
import cz.cvut.fit.horanvoj.ribbon.model.user.UserCreation
import cz.cvut.fit.horanvoj.ribbon.model.user.UserModification
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.data.source.AuthSource
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.infrastructure.api.AuthApi
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.exception.AuthException
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.model.AuthRequest
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.model.RegistrationRequest
import dev.gitlive.firebase.auth.ActionCodeSettings
import dev.gitlive.firebase.auth.FirebaseAuth
import dev.gitlive.firebase.auth.FirebaseUser
import kotlinx.coroutines.flow.first
import kotlinx.serialization.ExperimentalSerializationApi

@OptIn(ExperimentalSerializationApi::class, ExperimentalSettingsApi::class)
internal class FirebaseAuthSource(
    private val firebaseAuth: FirebaseAuth,
    private val authApi: AuthApi,
    settings: Settings,
) : AuthSource {
    private var loggedInUser: User? by settings
        .nullableSerializedValue(User.serializer(), LOGGED_IN_USER)

    override suspend fun login(request: AuthRequest) {
        val result =
            when (request) {
                is AuthRequest.Credentials ->
                    firebaseAuth.signInWithEmailAndPassword(
                        email = request.email,
                        password = request.password,
                    )
            }

        if (result.user == null) {
            throw AuthException.Unauthorized
        }

        if (result.user?.isEmailVerified == false) {
            result.user?.sendEmailVerification(
                actionCodeSettings = ActionCodeSettings(
                    url = "https://ribbon.vojtechh.eu/login",
                    canHandleCodeInApp = true,
                ),
            )
            firebaseAuth.signOut()
            throw AuthException.NotVerified
        }

        // Token is handled by ktor...
        loggedInUser = authApi.login()
    }

    override suspend fun getToken(): String {
        return getCurrentUser()?.getIdToken(false)
            ?: throw AuthException.Unauthorized
    }

    private suspend fun getCurrentUser(): FirebaseUser? = firebaseAuth.idTokenChanged.first()

    override suspend fun logout() {
        firebaseAuth.signOut()
        loggedInUser = null
    }

    override suspend fun resetPassword(email: String?) {
        val sendTo =
            if (email == null) {
                val user = getCurrentUser() ?: throw AuthException.Unauthorized
                user.email ?: throw AuthException.Unauthorized
            } else {
                email
            }

        firebaseAuth.sendPasswordResetEmail(sendTo)
    }

    override suspend fun isLoggedIn(): Boolean {
        return getCurrentUser() != null && loggedInUser != null
    }

    override suspend fun getUser(): User {
        val user = loggedInUser
        if (getCurrentUser() == null || user == null) {
            throw AuthException.Unauthorized
        }

        return user
    }

    override suspend fun register(request: RegistrationRequest) {
        when (request) {
            is RegistrationRequest.Credentials -> {
                loggedInUser =
                    authApi.register(
                        UserCreation(
                            name = request.displayName,
                            email = request.email,
                            password = request.password,
                        ),
                    )

                // Only sign in to send email verification...
                // Admin SDK does not allow sending verification emails,
                // we have to do it on client
                firebaseAuth.signInWithEmailAndPassword(
                    email = request.email,
                    password = request.password,
                )

                getCurrentUser()?.sendEmailVerification()

                firebaseAuth.signOut()
            }
        }
    }

    override suspend fun updateLoggedInUser(modification: UserModification) {
        val user = loggedInUser ?: return

        loggedInUser = modification.toUser(user)
    }

    private fun UserModification.toUser(withCurrent: User) =
        User(
            name = this.name ?: withCurrent.name,
            id = withCurrent.id,
            emailAddress = withCurrent.emailAddress,
        )

    private companion object {
        const val LOGGED_IN_USER = "loggedInUser"
    }
}
