package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.repository.ProjectRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase.RemoveApiKeyUseCase.Params
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase

interface RemoveApiKeyUseCase : UseCase<Params, Unit> {
    data class Params(
        val projectId: String,
    )
}

internal class RemoveApiKeyUseCaseImpl(
    private val projectRepository: ProjectRepository,
) : RemoveApiKeyUseCase {
    override suspend fun execute(params: Params) {
        return projectRepository.removeApiKey(
            projectId = params.projectId,
        )
    }
}
