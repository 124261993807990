package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.model.language.Language
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.domain.repository.LanguageRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.domain.usecase.RemoveLanguageFromProjectUseCase.Params
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase

interface RemoveLanguageFromProjectUseCase : UseCase<Params, List<Language>> {
    data class Params(
        val projectId: String,
        val languageId: String,
    )
}

internal class RemoveLanguageFromProjectUseCaseImpl(
    private val languageRepository: LanguageRepository,
) : RemoveLanguageFromProjectUseCase {
    override suspend fun execute(params: Params): List<Language> {
        return languageRepository.removeLanguageFromProject(
            projectId = params.projectId,
            languageId = params.languageId,
        )
    }
}
