package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.domain.repository.UserRepository
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCaseNoParams

interface RemoveUserUseCase : UseCaseNoParams<Unit>

internal class RemoveUserUseCaseImpl(
    private val userRepository: UserRepository,
) : RemoveUserUseCase {
    override suspend fun execute() {
        userRepository.removeUser()
    }
}
