package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.export

import cz.cvut.fit.horanvoj.ribbon.model.batch.BatchFormat
import cz.cvut.fit.horanvoj.ribbon.model.language.Language
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.domain.usecase.GetProjectLanguagesUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase.CreateOneTimeExportUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.invoke
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.ViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.infrastructure.getApiUrl
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.export.ExportViewModel.State

internal class ExportViewModel(
    private val getProjectLanguages: GetProjectLanguagesUseCase,
    private val createOneTimeExport: CreateOneTimeExportUseCase,
) : ViewModel<State>(State()) {
    fun onAppear(projectId: String) {
        update { copy(projectId = projectId) }
        launch { fetchLanguages() }
    }

    private suspend fun fetchLanguages() {
        val projectId = state.projectId ?: return
        update { copy(loading = true) }
        getProjectLanguages(GetProjectLanguagesUseCase.Params(projectId = projectId))
            .onSuccess { languages ->
                update { copy(languages = languages, loading = false) }
            }
            .onFailure {
                update { copy(loading = false, error = it) }
            }
    }

    fun onLanguageSelected(languageId: String) {
        if (state.selectedLanguage == languageId) {
            update { copy(selectedLanguage = null) }
        } else {
            update { copy(selectedLanguage = languageId) }
        }
    }

    fun onFormatSelected(format: BatchFormat) {
        update { copy(selectedFormat = format) }
    }

    fun onExportDownloaded() {
        update { copy(exportUrl = null) }
    }

    fun onExportClick() {
        val projectId = state.projectId ?: return
        val format = state.selectedFormat ?: return
        val languageId = state.selectedLanguage ?: return
        update { copy(exporting = true) }
        launch {
            createOneTimeExport(
                CreateOneTimeExportUseCase.Params(
                    projectId = projectId,
                    format = format,
                    languageId = languageId,
                ),
            ).onSuccess { export ->
                update {
                    copy(
                        exporting = false,
                        exportUrl = "${getApiUrl()}/${export.exportPath}",
                    )
                }
            }.onFailure {
                update { copy(exporting = false, error = it) }
            }
        }
    }

    data class State(
        val selectedFormat: BatchFormat? = null,
        val selectedLanguage: String? = null,
        val loading: Boolean = true,
        val projectId: String? = null,
        val error: Throwable? = null,
        val exporting: Boolean = false,
        val exportUrl: String? = null,
        val languages: List<Language> = emptyList(),
    ) {
        val isExportEnabled: Boolean =
            selectedLanguage != null &&
                selectedFormat != null
    }
}
