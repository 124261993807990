package strings

import io.github.skeptick.libres.strings.PluralForms
import io.github.skeptick.libres.strings.getCurrentLanguageCode
import kotlin.String
import kotlin.collections.Map

public object ResStrings {
  private val baseLocale: StringsEn = StringsEn

  private val locales: Map<String, Strings> = mapOf("en" to StringsEn)

  public val app_name: String
    get() = locales[getCurrentLanguageCode()]?.app_name ?: baseLocale.app_name

  public val title_user: String
    get() = locales[getCurrentLanguageCode()]?.title_user ?: baseLocale.title_user

  public val title_your_account: String
    get() = locales[getCurrentLanguageCode()]?.title_your_account ?: baseLocale.title_your_account

  public val label_password_reset_email_sent: String
    get() = locales[getCurrentLanguageCode()]?.label_password_reset_email_sent ?:
        baseLocale.label_password_reset_email_sent

  public val label_email_address: String
    get() = locales[getCurrentLanguageCode()]?.label_email_address ?: baseLocale.label_email_address

  public val label_full_name: String
    get() = locales[getCurrentLanguageCode()]?.label_full_name ?: baseLocale.label_full_name

  public val button_delete: String
    get() = locales[getCurrentLanguageCode()]?.button_delete ?: baseLocale.button_delete

  public val button_reset_password: String
    get() = locales[getCurrentLanguageCode()]?.button_reset_password ?:
        baseLocale.button_reset_password

  public val button_save: String
    get() = locales[getCurrentLanguageCode()]?.button_save ?: baseLocale.button_save

  public val button_cancel: String
    get() = locales[getCurrentLanguageCode()]?.button_cancel ?: baseLocale.button_cancel

  public val button_confirm: String
    get() = locales[getCurrentLanguageCode()]?.button_confirm ?: baseLocale.button_confirm

  public val title_are_you_sure: String
    get() = locales[getCurrentLanguageCode()]?.title_are_you_sure ?: baseLocale.title_are_you_sure

  public val label_delete_account_info: String
    get() = locales[getCurrentLanguageCode()]?.label_delete_account_info ?:
        baseLocale.label_delete_account_info

  public val title_register: String
    get() = locales[getCurrentLanguageCode()]?.title_register ?: baseLocale.title_register

  public val button_login_instead: String
    get() = locales[getCurrentLanguageCode()]?.button_login_instead ?:
        baseLocale.button_login_instead

  public val button_register_instead: String
    get() = locales[getCurrentLanguageCode()]?.button_register_instead ?:
        baseLocale.button_register_instead

  public val button_register: String
    get() = locales[getCurrentLanguageCode()]?.button_register ?: baseLocale.button_register

  public val label_email: String
    get() = locales[getCurrentLanguageCode()]?.label_email ?: baseLocale.label_email

  public val label_password: String
    get() = locales[getCurrentLanguageCode()]?.label_password ?: baseLocale.label_password

  public val label_repeat_password: String
    get() = locales[getCurrentLanguageCode()]?.label_repeat_password ?:
        baseLocale.label_repeat_password

  public val title_projects: String
    get() = locales[getCurrentLanguageCode()]?.title_projects ?: baseLocale.title_projects

  public val button_create_project: String
    get() = locales[getCurrentLanguageCode()]?.button_create_project ?:
        baseLocale.button_create_project

  public val button_add_language: String
    get() = locales[getCurrentLanguageCode()]?.button_add_language ?: baseLocale.button_add_language

  public val title_login: String
    get() = locales[getCurrentLanguageCode()]?.title_login ?: baseLocale.title_login

  public val button_login: String
    get() = locales[getCurrentLanguageCode()]?.button_login ?: baseLocale.button_login

  public val button_translate: String
    get() = locales[getCurrentLanguageCode()]?.button_translate ?: baseLocale.button_translate

  public val button_manage: String
    get() = locales[getCurrentLanguageCode()]?.button_manage ?: baseLocale.button_manage

  public val label_logout: String
    get() = locales[getCurrentLanguageCode()]?.label_logout ?: baseLocale.label_logout

  public val label_empty: String
    get() = locales[getCurrentLanguageCode()]?.label_empty ?: baseLocale.label_empty

  public val error_unknown: String
    get() = locales[getCurrentLanguageCode()]?.error_unknown ?: baseLocale.error_unknown

  public val title_looking_for_registration: String
    get() = locales[getCurrentLanguageCode()]?.title_looking_for_registration ?:
        baseLocale.title_looking_for_registration

  public val title_looking_for_login: String
    get() = locales[getCurrentLanguageCode()]?.title_looking_for_login ?:
        baseLocale.title_looking_for_login

  public val button_forgotten_password: String
    get() = locales[getCurrentLanguageCode()]?.button_forgotten_password ?:
        baseLocale.button_forgotten_password

  public val title_forgotten_password: String
    get() = locales[getCurrentLanguageCode()]?.title_forgotten_password ?:
        baseLocale.title_forgotten_password

  public val title_add_language: String
    get() = locales[getCurrentLanguageCode()]?.title_add_language ?: baseLocale.title_add_language

  public val button_add: String
    get() = locales[getCurrentLanguageCode()]?.button_add ?: baseLocale.button_add

  public val label_search: String
    get() = locales[getCurrentLanguageCode()]?.label_search ?: baseLocale.label_search

  public val label_languages_search: String
    get() = locales[getCurrentLanguageCode()]?.label_languages_search ?:
        baseLocale.label_languages_search

  public val label_languages_empty_search: String
    get() = locales[getCurrentLanguageCode()]?.label_languages_empty_search ?:
        baseLocale.label_languages_empty_search

  public val title_translate: String
    get() = locales[getCurrentLanguageCode()]?.title_translate ?: baseLocale.title_translate

  public val button_set_reference_language: String
    get() = locales[getCurrentLanguageCode()]?.button_set_reference_language ?:
        baseLocale.button_set_reference_language

  public val title_reference_language: String
    get() = locales[getCurrentLanguageCode()]?.title_reference_language ?:
        baseLocale.title_reference_language

  public val label_no_translation: String
    get() = locales[getCurrentLanguageCode()]?.label_no_translation ?:
        baseLocale.label_no_translation

  public val button_next_page: String
    get() = locales[getCurrentLanguageCode()]?.button_next_page ?: baseLocale.button_next_page

  public val button_prev_page: String
    get() = locales[getCurrentLanguageCode()]?.button_prev_page ?: baseLocale.button_prev_page

  public val title_create_project: String
    get() = locales[getCurrentLanguageCode()]?.title_create_project ?:
        baseLocale.title_create_project

  public val button_create: String
    get() = locales[getCurrentLanguageCode()]?.button_create ?: baseLocale.button_create

  public val label_project_name: String
    get() = locales[getCurrentLanguageCode()]?.label_project_name ?: baseLocale.label_project_name

  public val label_project_creation: String
    get() = locales[getCurrentLanguageCode()]?.label_project_creation ?:
        baseLocale.label_project_creation

  public val button_view_more: String
    get() = locales[getCurrentLanguageCode()]?.button_view_more ?: baseLocale.button_view_more

  public val title_terms: String
    get() = locales[getCurrentLanguageCode()]?.title_terms ?: baseLocale.title_terms

  public val title_languages: String
    get() = locales[getCurrentLanguageCode()]?.title_languages ?: baseLocale.title_languages

  public val title_members: String
    get() = locales[getCurrentLanguageCode()]?.title_members ?: baseLocale.title_members

  public val title_manage: String
    get() = locales[getCurrentLanguageCode()]?.title_manage ?: baseLocale.title_manage

  public val title_options: String
    get() = locales[getCurrentLanguageCode()]?.title_options ?: baseLocale.title_options

  public val button_delete_project: String
    get() = locales[getCurrentLanguageCode()]?.button_delete_project ?:
        baseLocale.button_delete_project

  public val button_edit_project: String
    get() = locales[getCurrentLanguageCode()]?.button_edit_project ?: baseLocale.button_edit_project

  public val button_generate_api_key: String
    get() = locales[getCurrentLanguageCode()]?.button_generate_api_key ?:
        baseLocale.button_generate_api_key

  public val button_remove_api_key: String
    get() = locales[getCurrentLanguageCode()]?.button_remove_api_key ?:
        baseLocale.button_remove_api_key

  public val button_copy_api_key: String
    get() = locales[getCurrentLanguageCode()]?.button_copy_api_key ?: baseLocale.button_copy_api_key

  public val button_import: String
    get() = locales[getCurrentLanguageCode()]?.button_import ?: baseLocale.button_import

  public val button_export: String
    get() = locales[getCurrentLanguageCode()]?.button_export ?: baseLocale.button_export

  public val label_role_admin: String
    get() = locales[getCurrentLanguageCode()]?.label_role_admin ?: baseLocale.label_role_admin

  public val label_role_developer: String
    get() = locales[getCurrentLanguageCode()]?.label_role_developer ?:
        baseLocale.label_role_developer

  public val label_role_translator: String
    get() = locales[getCurrentLanguageCode()]?.label_role_translator ?:
        baseLocale.label_role_translator

  public val label_api_key_has_been_copied: String
    get() = locales[getCurrentLanguageCode()]?.label_api_key_has_been_copied ?:
        baseLocale.label_api_key_has_been_copied

  public val label_delete_api_key_info: String
    get() = locales[getCurrentLanguageCode()]?.label_delete_api_key_info ?:
        baseLocale.label_delete_api_key_info

  public val label_delete_project_info: String
    get() = locales[getCurrentLanguageCode()]?.label_delete_project_info ?:
        baseLocale.label_delete_project_info

  public val title_edit_project: String
    get() = locales[getCurrentLanguageCode()]?.title_edit_project ?: baseLocale.title_edit_project

  public val label_edit_project: String
    get() = locales[getCurrentLanguageCode()]?.label_edit_project ?: baseLocale.label_edit_project

  public val title_export: String
    get() = locales[getCurrentLanguageCode()]?.title_export ?: baseLocale.title_export

  public val batch_json: String
    get() = locales[getCurrentLanguageCode()]?.batch_json ?: baseLocale.batch_json

  public val batch_android: String
    get() = locales[getCurrentLanguageCode()]?.batch_android ?: baseLocale.batch_android

  public val batch_apple: String
    get() = locales[getCurrentLanguageCode()]?.batch_apple ?: baseLocale.batch_apple

  public val label_export_info: String
    get() = locales[getCurrentLanguageCode()]?.label_export_info ?: baseLocale.label_export_info

  public val button_invite_member: String
    get() = locales[getCurrentLanguageCode()]?.button_invite_member ?:
        baseLocale.button_invite_member

  public val label_user_has_been_invited_info: String
    get() = locales[getCurrentLanguageCode()]?.label_user_has_been_invited_info ?:
        baseLocale.label_user_has_been_invited_info

  public val title_invite: String
    get() = locales[getCurrentLanguageCode()]?.title_invite ?: baseLocale.title_invite

  public val label_invite_member: String
    get() = locales[getCurrentLanguageCode()]?.label_invite_member ?: baseLocale.label_invite_member

  public val label_invite_member_role: String
    get() = locales[getCurrentLanguageCode()]?.label_invite_member_role ?:
        baseLocale.label_invite_member_role

  public val label_invited_to: LibresFormatLabelInvitedTo
    get() = locales[getCurrentLanguageCode()]?.label_invited_to ?: baseLocale.label_invited_to

  public val button_accept: String
    get() = locales[getCurrentLanguageCode()]?.button_accept ?: baseLocale.button_accept

  public val button_decline: String
    get() = locales[getCurrentLanguageCode()]?.button_decline ?: baseLocale.button_decline

  public val label_remove_member: LibresFormatLabelRemoveMember
    get() = locales[getCurrentLanguageCode()]?.label_remove_member ?: baseLocale.label_remove_member

  public val title_modify_member: String
    get() = locales[getCurrentLanguageCode()]?.title_modify_member ?: baseLocale.title_modify_member

  public val label_modify_member: LibresFormatLabelModifyMember
    get() = locales[getCurrentLanguageCode()]?.label_modify_member ?: baseLocale.label_modify_member

  public val label_remove_language: LibresFormatLabelRemoveLanguage
    get() = locales[getCurrentLanguageCode()]?.label_remove_language ?:
        baseLocale.label_remove_language

  public val label_show_statistics: String
    get() = locales[getCurrentLanguageCode()]?.label_show_statistics ?:
        baseLocale.label_show_statistics

  public val label_last_updated: LibresFormatLabelLastUpdated
    get() = locales[getCurrentLanguageCode()]?.label_last_updated ?: baseLocale.label_last_updated

  public val label_last_updated_no_author: LibresFormatLabelLastUpdatedNoAuthor
    get() = locales[getCurrentLanguageCode()]?.label_last_updated_no_author ?:
        baseLocale.label_last_updated_no_author

  public val label_description: String
    get() = locales[getCurrentLanguageCode()]?.label_description ?: baseLocale.label_description

  public val label_author: String
    get() = locales[getCurrentLanguageCode()]?.label_author ?: baseLocale.label_author

  public val label_translations: String
    get() = locales[getCurrentLanguageCode()]?.label_translations ?: baseLocale.label_translations

  public val label_translated_in: LibresFormatLabelTranslatedIn
    get() = locales[getCurrentLanguageCode()]?.label_translated_in ?: baseLocale.label_translated_in

  public val button_terms: String
    get() = locales[getCurrentLanguageCode()]?.button_terms ?: baseLocale.button_terms

  public val title_create_term: String
    get() = locales[getCurrentLanguageCode()]?.title_create_term ?: baseLocale.title_create_term

  public val label_create_term: String
    get() = locales[getCurrentLanguageCode()]?.label_create_term ?: baseLocale.label_create_term

  public val label_term_id: String
    get() = locales[getCurrentLanguageCode()]?.label_term_id ?: baseLocale.label_term_id

  public val label_term_description: String
    get() = locales[getCurrentLanguageCode()]?.label_term_description ?:
        baseLocale.label_term_description

  public val button_create_term: String
    get() = locales[getCurrentLanguageCode()]?.button_create_term ?: baseLocale.button_create_term

  public val label_remove_term: LibresFormatLabelRemoveTerm
    get() = locales[getCurrentLanguageCode()]?.label_remove_term ?: baseLocale.label_remove_term

  public val title_update_term: String
    get() = locales[getCurrentLanguageCode()]?.title_update_term ?: baseLocale.title_update_term

  public val label_update_term: String
    get() = locales[getCurrentLanguageCode()]?.label_update_term ?: baseLocale.label_update_term

  public val title_filter: String
    get() = locales[getCurrentLanguageCode()]?.title_filter ?: baseLocale.title_filter

  public val label_order_by: String
    get() = locales[getCurrentLanguageCode()]?.label_order_by ?: baseLocale.label_order_by

  public val label_filter: String
    get() = locales[getCurrentLanguageCode()]?.label_filter ?: baseLocale.label_filter

  public val label_order_updated_asc: String
    get() = locales[getCurrentLanguageCode()]?.label_order_updated_asc ?:
        baseLocale.label_order_updated_asc

  public val label_order_updated_desc: String
    get() = locales[getCurrentLanguageCode()]?.label_order_updated_desc ?:
        baseLocale.label_order_updated_desc

  public val label_order_name_asc: String
    get() = locales[getCurrentLanguageCode()]?.label_order_name_asc ?:
        baseLocale.label_order_name_asc

  public val label_order_name_desc: String
    get() = locales[getCurrentLanguageCode()]?.label_order_name_desc ?:
        baseLocale.label_order_name_desc

  public val label_term_filter_all: String
    get() = locales[getCurrentLanguageCode()]?.label_term_filter_all ?:
        baseLocale.label_term_filter_all

  public val label_term_filter_translated: String
    get() = locales[getCurrentLanguageCode()]?.label_term_filter_translated ?:
        baseLocale.label_term_filter_translated

  public val label_term_filter_not_translated: String
    get() = locales[getCurrentLanguageCode()]?.label_term_filter_not_translated ?:
        baseLocale.label_term_filter_not_translated

  public val title_import: String
    get() = locales[getCurrentLanguageCode()]?.title_import ?: baseLocale.title_import

  public val label_select_file: String
    get() = locales[getCurrentLanguageCode()]?.label_select_file ?: baseLocale.label_select_file

  public val label_drop_now: String
    get() = locales[getCurrentLanguageCode()]?.label_drop_now ?: baseLocale.label_drop_now

  public val label_override_existing: String
    get() = locales[getCurrentLanguageCode()]?.label_override_existing ?:
        baseLocale.label_override_existing

  public val label_import_info: String
    get() = locales[getCurrentLanguageCode()]?.label_import_info ?: baseLocale.label_import_info

  public val label_import_languages_info: String
    get() = locales[getCurrentLanguageCode()]?.label_import_languages_info ?:
        baseLocale.label_import_languages_info

  public val label_import_format_info: String
    get() = locales[getCurrentLanguageCode()]?.label_import_format_info ?:
        baseLocale.label_import_format_info

  public val label_import_override_info: String
    get() = locales[getCurrentLanguageCode()]?.label_import_override_info ?:
        baseLocale.label_import_override_info

  public val label_format_unknown: String
    get() = locales[getCurrentLanguageCode()]?.label_format_unknown ?:
        baseLocale.label_format_unknown

  public val title_import_successful: String
    get() = locales[getCurrentLanguageCode()]?.title_import_successful ?:
        baseLocale.title_import_successful

  public val label_import_count: LibresFormatLabelImportCount
    get() = locales[getCurrentLanguageCode()]?.label_import_count ?: baseLocale.label_import_count

  public val label_import_skipped: LibresFormatLabelImportSkipped
    get() = locales[getCurrentLanguageCode()]?.label_import_skipped ?:
        baseLocale.label_import_skipped

  public val label_import_nothing_skipped: String
    get() = locales[getCurrentLanguageCode()]?.label_import_nothing_skipped ?:
        baseLocale.label_import_nothing_skipped

  public val title_importing: String
    get() = locales[getCurrentLanguageCode()]?.title_importing ?: baseLocale.title_importing

  public val label_importing: String
    get() = locales[getCurrentLanguageCode()]?.label_importing ?: baseLocale.label_importing

  public val title_error: String
    get() = locales[getCurrentLanguageCode()]?.title_error ?: baseLocale.title_error

  public val label_user_avatar_info: String
    get() = locales[getCurrentLanguageCode()]?.label_user_avatar_info ?:
        baseLocale.label_user_avatar_info

  public val label_password_criteria: String
    get() = locales[getCurrentLanguageCode()]?.label_password_criteria ?:
        baseLocale.label_password_criteria

  public val label_registration_email: String
    get() = locales[getCurrentLanguageCode()]?.label_registration_email ?:
        baseLocale.label_registration_email

  public val label_invited_by: LibresFormatLabelInvitedBy
    get() = locales[getCurrentLanguageCode()]?.label_invited_by ?: baseLocale.label_invited_by

  public val error_unknown_user: String
    get() = locales[getCurrentLanguageCode()]?.error_unknown_user ?: baseLocale.error_unknown_user

  public val label_remove_invite: LibresFormatLabelRemoveInvite
    get() = locales[getCurrentLanguageCode()]?.label_remove_invite ?: baseLocale.label_remove_invite

  public val label_languages_empty: String
    get() = locales[getCurrentLanguageCode()]?.label_languages_empty ?:
        baseLocale.label_languages_empty

  public val label_terms_empty: String
    get() = locales[getCurrentLanguageCode()]?.label_terms_empty ?: baseLocale.label_terms_empty

  public val label_projects_empty: String
    get() = locales[getCurrentLanguageCode()]?.label_projects_empty ?:
        baseLocale.label_projects_empty

  public val label_translations_empty: String
    get() = locales[getCurrentLanguageCode()]?.label_translations_empty ?:
        baseLocale.label_translations_empty

  public val error_invalid_credentials: String
    get() = locales[getCurrentLanguageCode()]?.error_invalid_credentials ?:
        baseLocale.error_invalid_credentials

  public val error_too_many_requests: String
    get() = locales[getCurrentLanguageCode()]?.error_too_many_requests ?:
        baseLocale.error_too_many_requests

  public val error_login_failed: String
    get() = locales[getCurrentLanguageCode()]?.error_login_failed ?: baseLocale.error_login_failed

  public val error_not_verified: String
    get() = locales[getCurrentLanguageCode()]?.error_not_verified ?: baseLocale.error_not_verified

  public val title_registration_successful: String
    get() = locales[getCurrentLanguageCode()]?.title_registration_successful ?:
        baseLocale.title_registration_successful

  public val label_registered_verify_email: String
    get() = locales[getCurrentLanguageCode()]?.label_registered_verify_email ?:
        baseLocale.label_registered_verify_email

  public val label_export_generated: String
    get() = locales[getCurrentLanguageCode()]?.label_export_generated ?:
        baseLocale.label_export_generated

  public val title_export_successful: String
    get() = locales[getCurrentLanguageCode()]?.title_export_successful ?:
        baseLocale.title_export_successful

  public val title_exporting: String
    get() = locales[getCurrentLanguageCode()]?.title_exporting ?: baseLocale.title_exporting

  public val label_exporting: String
    get() = locales[getCurrentLanguageCode()]?.label_exporting ?: baseLocale.label_exporting

  public val button_download: String
    get() = locales[getCurrentLanguageCode()]?.button_download ?: baseLocale.button_download

  public val label_export_format_info: String
    get() = locales[getCurrentLanguageCode()]?.label_export_format_info ?:
        baseLocale.label_export_format_info
}
