package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.repository.CommonRepository
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCaseNoParams

interface ClearCacheUseCase : UseCaseNoParams<Unit>

internal class ClearCacheUseCaseImpl(
    private val commonRepository: CommonRepository,
) : ClearCacheUseCase {
    override suspend fun execute() {
        commonRepository.clearCaches()
    }
}
