package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.projects

import Res
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.mdi.MdiAddCircle
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceSmall
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.PageLayout
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.RequireAuthenticationEffect
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.ExtendedFloatingActionButton
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.MaterialFilledButton
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.OutlinedCard
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.rememberViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.routeToLanguages
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.EmptyView
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.ErrorSnackbar
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.PrimaryListItem
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.domain.displayName
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.projects.createProject.CreateProjectDialog
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.toSitePalette
import org.jetbrains.compose.web.dom.Text

@Page("/projects")
@Composable
fun ProjectsPage() {
    RequireAuthenticationEffect()
    val viewModel = rememberViewModel<ProjectsViewModel>()
    val style = ColorMode.current.toSitePalette()
    val ctx = rememberPageContext()

    LaunchedEffect(viewModel) {
        viewModel.onAppear()
    }

    if (viewModel.state.creatingProject) {
        CreateProjectDialog(
            onCancelled = viewModel::onProjectCreationCancelled,
            onCreated = viewModel::onProjectCreated,
        )
    }

    ErrorSnackbar(viewModel.state.error)

    PageLayout(
        title = Res.string.title_projects,
        loading = viewModel.state.loading,
        retainContentOnLoading = true,
    ) {
        if (viewModel.state.empty) {
            EmptyView(
                text = Res.string.label_projects_empty,
            )
        } else {
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .gap(SpaceMedium)
                    .padding(SpaceMedium),
            ) {
                viewModel.state.invites.forEach { invite ->
                    OutlinedCard(
                        modifier = Modifier
                            .fillMaxWidth(),
                    ) {
                        Row(
                            verticalAlignment = Alignment.CenterVertically,
                            modifier = Modifier
                                .fillMaxWidth()
                                .gap(SpaceSmall),
                        ) {
                            Text(
                                Res.string.label_invited_to.format(
                                    user = invite.invitedBy?.name.orEmpty(),
                                    project = invite.project.name,
                                    role = invite.role.displayName,
                                ),
                            )

                            Spacer()

                            MaterialFilledButton(
                                backgroundColor = style.error,
                                color = style.onError,
                                onClick = {
                                    viewModel.onInviteDeclineClick(invite)
                                },
                            ) {
                                Text(Res.string.button_decline)
                            }

                            MaterialFilledButton(
                                onClick = {
                                    viewModel.onInviteAcceptClick(invite)
                                },
                            ) {
                                Text(Res.string.button_accept)
                            }
                        }
                    }
                }

                viewModel.state.projects.forEach { project ->
                    PrimaryListItem(
                        onClick = {
                            ctx.router.routeToLanguages(project.id)
                        },
                    ) {
                        SpanText(
                            text = project.name,
                        )
                    }
                }
            }
        }

        ExtendedFloatingActionButton(
            text = Res.string.button_create_project,
            onClick = viewModel::onCreateProject,
        ) {
            MdiAddCircle()
        }
    }
}
