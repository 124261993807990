package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.languages

import Res
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.mdi.MdiAddCircle
import com.varabyte.kobweb.silk.components.icons.mdi.MdiDelete
import com.varabyte.kobweb.silk.components.text.SpanText
import cz.cvut.fit.horanvoj.ribbon.model.project.MemberRole
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.PageLayout
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.ProjectTab
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.RequireAuthenticationEffect
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.RequireProjectRoleEffect
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.*
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.rememberViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.EmptyView
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.ErrorSnackbar
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.domain.displayName
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.languages.addLanguage.AddLanguageDialog

@Page("/project/{projectId}/manage/languages")
@Composable
fun ManageLanguagesPage() {
    RequireAuthenticationEffect()
    RequireProjectRoleEffect(
        MemberRole.ADMINISTRATOR,
        MemberRole.DEVELOPER,
    )

    val ctx = rememberPageContext()
    val projectId = ctx.route.params.getValue("projectId")
    val viewModel = rememberViewModel<ManageLanguagesViewModel>()

    LaunchedEffect(viewModel) {
        viewModel.onAppear(projectId)
    }

    ErrorSnackbar(viewModel.state.error)

    if (viewModel.state.addingLanguage) {
        AddLanguageDialog(
            onAdded = viewModel::onAddedLanguage,
            onCancelled = viewModel::onAddingLanguageCancel,
        )
    }

    val removingLanguage = viewModel.state.removingLanguage
    if (removingLanguage != null) {
        RemoveLanguagesDialog(
            language = removingLanguage,
            onConfirmed = viewModel::onRemoveLanguageConfirmed,
            onCancelled = viewModel::onRemoveLanguageCancelled,
        )
    }

    PageLayout(
        title = Res.string.title_languages,
        loading = viewModel.state.loading,
        activeTab = ProjectTab.MANAGE,
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .gap(SpaceMedium)
                .padding(SpaceMedium),
        ) {
            if (viewModel.state.languages.isEmpty()) {
                EmptyView(
                    text = Res.string.label_languages_empty,
                )
            } else {
                viewModel.state.languages.forEach { language ->
                    OutlinedCard(
                        modifier = Modifier
                            .fillMaxWidth(),
                    ) {
                        Row(
                            verticalAlignment = Alignment.CenterVertically,
                            modifier = Modifier
                                .fillMaxWidth()
                                .gap(SpaceMedium),
                        ) {
                            SpanText(
                                text = language.displayName,
                                modifier = Modifier
                                    .typography(TitleMedium),
                            )

                            Spacer()

                            if (viewModel.state.canRemoveLanguages) {
                                MaterialIconButton(
                                    onClick = {
                                        viewModel.onRemoveLanguageClick(language)
                                    },
                                ) {
                                    MdiDelete()
                                }
                            }
                        }
                    }
                }
            }
        }

        if (viewModel.state.canAddLanguage) {
            ExtendedFloatingActionButton(
                text = Res.string.button_add_language,
                onClick = viewModel::onAddLanguageClick,
            ) {
                MdiAddCircle()
            }
        }
    }
}
