package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.register

import Res
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.dom.ref
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.mdi.MdiAccountCircle
import com.varabyte.kobweb.silk.components.icons.mdi.MdiAlternateEmail
import com.varabyte.kobweb.silk.components.icons.mdi.MdiCheckCircle
import com.varabyte.kobweb.silk.components.icons.mdi.MdiLock
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceLarge
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.PageLayout
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.*
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.rememberViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.routeToLogin
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.ErrorSnackbar
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.fadeInAnimation
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.toSitePalette
import org.jetbrains.compose.web.attributes.AutoComplete
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.*

@Page("/register")
@Composable
fun RegisterPage() {
    val viewModel = rememberViewModel<RegisterViewModel>()
    val ctx = rememberPageContext()

    LaunchedEffect(viewModel) {
        viewModel.onAppear()
    }

    val snackbarState = rememberSnackbarState()
    val style = ColorMode.current.toSitePalette()

    ErrorSnackbar(
        throwable = viewModel.state.error,
    )

    Snackbar(snackbarState)

    PageLayout(
        title = Res.string.title_register,
        loading = viewModel.state.loading,
        retainContentOnLoading = true,
    ) {
        Column(
            verticalArrangement = Arrangement.Center,
            modifier = Modifier
                .padding(SpaceMedium)
                .minWidth(35.percent)
                .maxWidth(400.px)
                .width(100.percent)
                .gap(SpaceMedium),
        ) {
            if (viewModel.state.isRegistered) {
                OutlinedCard(
                    modifier = Modifier
                        .setVariable(OutlinedCardColor, style.onSuccessContainer)
                        .setVariable(OutlinedCardBackground, style.successContainer)
                        .fillMaxWidth()
                        .fadeInAnimation()
                        .typography(BodyLarge),
                    title = {
                        Text(Res.string.title_registration_successful)
                    },
                    icon = {
                        MdiCheckCircle(
                            modifier = Modifier
                                .fontSize(24.px)
                                .color(style.onSuccessContainer),
                        )
                    },
                    ref = ref {
                        it.scrollIntoView(
                            arg = ScrollIntoViewOptions(
                                behavior = ScrollBehavior.SMOOTH,
                                block = ScrollLogicalPosition.NEAREST,
                            ),
                        )
                    },
                ) {
                    SpanText(
                        Res.string.label_registered_verify_email,
                    )
                }
            }

            SpanText(
                text = Res.string.title_register,
                modifier = Modifier
                    .typography(TitleLarge),
            )

            MaterialTextInput(
                text = viewModel.state.displayName,
                onTextChanged = viewModel::onDisplayNameChange,
                placeholder = Res.string.label_full_name,
                required = true,
                autoComplete = AutoComplete.name,
                icon = {
                    MdiAccountCircle()
                },
                modifier = Modifier
                    .fillMaxWidth(),
            )

            SpanText(
                text = Res.string.label_registration_email,
                modifier = Modifier
                    .typography(LabelMedium),
            )

            MaterialTextInput(
                text = viewModel.state.email,
                valid = viewModel.state.emailValid,
                onTextChanged = viewModel::onEmailChange,
                placeholder = Res.string.label_email,
                required = true,
                autoComplete = AutoComplete.email,
                icon = {
                    MdiAlternateEmail()
                },
                modifier = Modifier
                    .fillMaxWidth(),
            )

            SpanText(
                text = Res.string.label_password_criteria,
                modifier = Modifier
                    .typography(LabelMedium),
            )

            MaterialTextInput(
                text = viewModel.state.password,
                onTextChanged = viewModel::onPasswordChange,
                password = true,
                placeholder = Res.string.label_password,
                required = true,
                valid = viewModel.state.passwordValid,
                autoComplete = AutoComplete.newPassword,
                icon = {
                    MdiLock()
                },
                modifier = Modifier
                    .fillMaxWidth(),
            )

            MaterialTextInput(
                text = viewModel.state.repeatPassword,
                onTextChanged = viewModel::onRepeatPasswordChange,
                onCommit = viewModel::register,
                password = true,
                placeholder = Res.string.label_repeat_password,
                required = true,
                valid = viewModel.state.repeatPasswordValid,
                autoComplete = AutoComplete.newPassword,
                icon = {
                    MdiLock()
                },
                modifier = Modifier
                    .fillMaxWidth(),
            )

            Row(
                modifier = Modifier
                    .fillMaxWidth(),
            ) {
                Spacer()
                MaterialFilledButton(
                    enabled = viewModel.state.registerButtonEnabled,
                    onClick = { viewModel.register() },
                ) {
                    Text(Res.string.button_register)
                }
            }

            SpanText(
                text = Res.string.title_looking_for_login,
                modifier = Modifier
                    .typography(TitleLarge)
                    .margin(top = SpaceLarge),
            )

            Row(
                horizontalArrangement = Arrangement.End,
                modifier = Modifier.fillMaxWidth(),
            ) {
                Spacer()
                MaterialTonalButton(
                    onClick = {
                        ctx.router.routeToLogin()
                    },
                ) {
                    Text(Res.string.button_login_instead)
                }
            }
        }
    }
}
