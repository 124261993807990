package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.infrastructure.api

import cz.cvut.fit.horanvoj.ribbon.model.invite.Invite
import cz.cvut.fit.horanvoj.ribbon.model.user.User
import cz.cvut.fit.horanvoj.ribbon.model.user.UserModification
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.use
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*

internal interface UserApi {
    suspend fun getUser(userId: String): User

    suspend fun removeUser()

    suspend fun modifyUser(userModification: UserModification): User

    suspend fun getInvites(): List<Invite>

    suspend fun acceptInvite(inviteId: String)

    suspend fun declineInvite(inviteId: String)
}

internal class UserApiImpl(
    private val httpClient: HttpClient,
) : UserApi {
    override suspend fun getUser(userId: String): User {
        return httpClient.use {
            get("api/v1/user/$userId")
                .body()
        }
    }

    override suspend fun removeUser() {
        return httpClient.use {
            delete("api/v1/user").body()
        }
    }

    override suspend fun modifyUser(userModification: UserModification): User {
        return httpClient.use {
            post("api/v1/user") {
                setBody(userModification)
            }.body()
        }
    }

    override suspend fun getInvites(): List<Invite> {
        return httpClient.use {
            get("api/v1/invite")
                .body()
        }
    }

    override suspend fun acceptInvite(inviteId: String) {
        return httpClient.use {
            post("api/v1/invite/accept") {
                parameter("inviteId", inviteId)
            }.body()
        }
    }

    override suspend fun declineInvite(inviteId: String) {
        return httpClient.use {
            post("api/v1/invite/decline") {
                parameter("inviteId", inviteId)
            }.body()
        }
    }
}
