import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.components.animation.registerKeyframes
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.Index() }
        ctx.router.register("/forgottenPassword") {
                cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.login.ForgottenPasswordPage() }
        ctx.router.register("/login") {
                cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.login.LoginPage() }
        ctx.router.register("/project/{projectId}/languages") {
                cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.languages.LanguagesPage() }
        ctx.router.register("/project/{projectId}/languages/{languageId}") {
                cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.translate.TranslatePage() }
        ctx.router.register("/project/{projectId}/manage") {
                cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.ManagePage() }
        ctx.router.register("/project/{projectId}/manage/export") {
                cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.export.ExportPage() }
        ctx.router.register("/project/{projectId}/manage/import") {
                cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.import.ImportPage() }
        ctx.router.register("/project/{projectId}/manage/languages") {
                cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.languages.ManageLanguagesPage()
                }
        ctx.router.register("/project/{projectId}/manage/members") {
                cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.members.MembersPage()
                }
        ctx.router.register("/project/{projectId}/manage/terms") {
                cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.terms.TermsPage() }
        ctx.router.register("/projects") {
                cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.projects.ProjectsPage() }
        ctx.router.register("/register") {
                cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.register.RegisterPage() }
        ctx.router.register("/user") {
                cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.user.UserPage() }

    }
    router.setLegacyRouteRedirectStrategy(Router.LegacyRouteRedirectStrategy.DISALLOW)
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.NavHeaderStyle)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.PageContentStyle)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.ElevatedOnHoverButtonStyle)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.OutlinedCardStyle)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.OutlinedCardContentStyle)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.CheckboxStyle)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.CheckboxItemStyle)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.MaterialDialogStyle)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.MaterialHorizontalDividerStyle)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.MaterialVerticalDividerStyle)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.ExtendedFloatingActionButtonStyle)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.RadioStyle)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.RadioButtonItemStyle)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.SnackbarStyle)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.TabStyle)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.DisplayLarge)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.DisplayMedium)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.DisplaySmall)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.HeadlineLarge)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.HeadlineMedium)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.HeadlineSmall)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.TitleLarge)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.TitleMedium)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.TitleSmall)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.BodyLarge)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.BodyMedium)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.BodySmall)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.LabelLarge)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.LabelLargeProminent)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.LabelMedium)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.LabelMediumProminent)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.LabelSmall)
        ctx.theme.registerComponentStyle(cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.terms.TranslationTextStyle)
        ctx.theme.registerComponentVariants(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.OutlinedCardStyleWithClick)
        ctx.theme.registerComponentVariants(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.CheckboxWithHover)
        ctx.theme.registerComponentVariants(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.RadioWithHover)
        ctx.stylesheet.registerKeyframes(cz.cvut.fit.horanvoj.ribbon.unwinder.web.AnimationFadeIn)
        ctx.stylesheet.registerKeyframes(cz.cvut.fit.horanvoj.ribbon.unwinder.web.AnimationEnterVertical)
        ctx.stylesheet.registerKeyframes(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.ProgressAnimation)
        ctx.stylesheet.registerKeyframes(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.SnackbarAnimation)
        ctx.stylesheet.registerKeyframes(cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.EmptyImageKeyframes)
        cz.cvut.fit.horanvoj.ribbon.unwinder.web.initColorMode(ctx)
        cz.cvut.fit.horanvoj.ribbon.unwinder.web.initSiteStyles(ctx)
        cz.cvut.fit.horanvoj.ribbon.unwinder.web.initTheme(ctx)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    AppGlobals.initialize(mapOf("title" to "Ribbon"))
    renderComposable(rootElementId = "root") {
        cz.cvut.fit.horanvoj.ribbon.unwinder.web.AppEntry {
            router.renderActivePage { renderWithDeferred { it() } }
        }
    }
}
