package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.model.project.Project
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.repository.ProjectRepository
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCaseNoParams

interface GetProjectsUseCase : UseCaseNoParams<List<Project>>

internal class GetProjectsUseCaseImpl(
    private val projectRepository: ProjectRepository,
) : GetProjectsUseCase {
    override suspend fun execute(): List<Project> {
        return projectRepository.getProjects()
    }
}
