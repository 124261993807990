package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage

import Res
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.css.AlignSelf
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.mdi.MdiLanguage
import com.varabyte.kobweb.silk.components.icons.mdi.MdiPeople
import com.varabyte.kobweb.silk.components.text.SpanText
import cz.cvut.fit.horanvoj.ribbon.model.project.MemberRole
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceBig
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceSmall
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.PageLayout
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.ProjectTab
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.RequireAuthenticationEffect
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.RequireProjectRoleEffect
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.*
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.rememberViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.routeToManageLanguages
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.routeToMembers
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.ErrorSnackbar
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.UserImage
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.domain.displayName
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.enterVerticallyAnimation
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.fadeInAnimation
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.options.OptionsCard
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.fr
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

@Page("/project/{projectId}/manage")
@Composable
fun ManagePage() {
    RequireAuthenticationEffect()
    RequireProjectRoleEffect(
        MemberRole.ADMINISTRATOR,
        MemberRole.DEVELOPER,
    )

    val ctx = rememberPageContext()
    val projectId = ctx.route.params.getValue("projectId")

    val languagesVm = rememberViewModel<LanguagesCardViewModel>()
    val membersVm = rememberViewModel<MembersCardViewModel>()

    LaunchedEffect(languagesVm, projectId) { languagesVm.onAppear(projectId) }
    LaunchedEffect(membersVm, projectId) { membersVm.onAppear(projectId) }

    val error =
        languagesVm.state.error
            ?: membersVm.state.error

    ErrorSnackbar(throwable = error)

    PageLayout(
        title = Res.string.title_manage,
        activeTab = ProjectTab.MANAGE,
    ) {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .padding(SpaceMedium)
                .gap(SpaceMedium)
                .flexWrap(FlexWrap.Wrap),
        ) {
            Div(
                attrs =
                    Modifier
                        .flex(2)
                        .grid {
                            columns { repeat(autoFill) { minmax(320.px, 1.fr) } }
                        }
                        .gap(SpaceMedium)
                        .toAttrs(),
            ) {
                ManageCard(
                    title = Res.string.title_members,
                    loading = membersVm.state.loading,
                    onMoreButtonClick = {
                        ctx.router.routeToMembers(projectId)
                    },
                    icon = {
                        MdiPeople(
                            modifier = Modifier
                                .fontSize(24.px),
                        )
                    },
                ) {
                    ManageItems(
                        items = membersVm.state.members,
                        emptyText = Res.string.label_empty,
                    ) {
                        UserImage(it.user, size = SpaceBig)
                        Text("${it.user.name} (${it.role.displayName})")
                    }
                }

                ManageCard(
                    title = Res.string.title_languages,
                    loading = languagesVm.state.loading,
                    onMoreButtonClick = {
                        ctx.router.routeToManageLanguages(projectId)
                    },
                    icon = {
                        MdiLanguage(
                            modifier = Modifier
                                .fontSize(24.px),
                        )
                    },
                ) {
                    ManageItems(
                        items = languagesVm.state.languages,
                        emptyText = Res.string.label_languages_empty,
                    ) {
                        Text(it.displayName)
                    }
                }
            }

            OptionsCard()
        }
    }
}

@Composable
fun ManageCard(
    title: String,
    icon: @Composable () -> Unit,
    loading: Boolean,
    onMoreButtonClick: (() -> Unit)?,
    modifier: Modifier = Modifier,
    content: @Composable ColumnScope.() -> Unit,
) {
    OutlinedCard(
        modifier = modifier
            .fillMaxWidth(),
        title = {
            Text(title)
        },
        icon = {
            icon()
        },
    ) {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .gap(SpaceMedium),
        ) {
            if (loading) {
                HorizontalProgressBar(
                    modifier = Modifier
                        .fillMaxWidth()
                        .enterVerticallyAnimation(),
                )
            } else {
                Column(
                    modifier = Modifier
                        .fadeInAnimation()
                        .fillMaxWidth(),
                ) {
                    content()
                }
            }

            Spacer()

            if (onMoreButtonClick != null) {
                Row(
                    horizontalArrangement = Arrangement.End,
                    modifier = Modifier
                        .fillMaxWidth(),
                ) {
                    MaterialTextButton(
                        onClick = {
                            onMoreButtonClick()
                        },
                    ) {
                        Text(Res.string.button_view_more)
                    }
                }
            }
        }
    }
}

@Composable
fun <T> ManageItems(
    items: List<T>,
    emptyText: String,
    modifier: Modifier = Modifier,
    content: @Composable (T) -> Unit,
) {
    Column(
        modifier = modifier
            .gap(SpaceSmall)
            .fillMaxWidth(),
    ) {
        if (items.isEmpty()) {
            SpanText(
                text = emptyText,
                modifier = Modifier
                    .typography(BodyLarge)
                    .alignSelf(AlignSelf.Center)
                    .textAlign(TextAlign.Center),
            )
        } else {
            items.forEach { item ->
                Row(
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier
                        .gap(SpaceMedium)
                        .typography(BodyLarge),
                ) {
                    content(item)
                }
            }
        }
    }
}
