package cz.cvut.fit.horanvoj.ribbon.unwinder.web.domain

import Res
import cz.cvut.fit.horanvoj.ribbon.model.project.MemberRole
import cz.cvut.fit.horanvoj.ribbon.model.project.MemberRole.*

val MemberRole.displayName: String
    get() =
        when (this) {
            ADMINISTRATOR -> Res.string.label_role_admin
            DEVELOPER -> Res.string.label_role_developer
            TRANSLATOR -> Res.string.label_role_translator
        }
