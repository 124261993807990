package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.languages.addLanguage

import cz.cvut.fit.horanvoj.ribbon.model.language.Language
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.domain.usecase.AddLanguageToProjectUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.domain.usecase.GetProjectLanguagesUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.domain.usecase.GetSupportedLanguagesUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.invoke
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.ViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.languages.addLanguage.AddLanguageViewModel.State

internal class AddLanguageViewModel(
    private val getSupportedLanguages: GetSupportedLanguagesUseCase,
    private val getProjectLanguages: GetProjectLanguagesUseCase,
    private val addLanguageToProject: AddLanguageToProjectUseCase,
) : ViewModel<State>(State()) {
    fun onAppear(projectId: String) {
        update { copy(projectId = projectId) }
        launch {
            getSupportedLanguages()
                .onSuccess { supported ->
                    getProjectLanguages(
                        GetProjectLanguagesUseCase.Params(
                            projectId = projectId,
                        ),
                    ).onSuccess { added ->
                        val addedIds = added.map { it.languageId }
                        val supportedNotAdded = supported.filterNot { s -> s.languageId in addedIds }
                        update { copy(allSupportedLanguages = supportedNotAdded, loading = false) }
                    }.onFailure {
                        update { copy(error = it, loading = false) }
                    }
                }
                .onFailure {
                    update { copy(error = it, loading = false) }
                }
        }
    }

    fun onQueryChange(query: String) {
        update { copy(query = query) }
    }

    fun onAddLanguageClick(language: Language) {
        val projectId = state.projectId ?: return
        update { copy(loading = true) }
        launch {
            addLanguageToProject(
                AddLanguageToProjectUseCase.Params(
                    projectId = projectId,
                    languageId = language.languageId,
                ),
            ).onSuccess {
                update {
                    copy(
                        loading = false,
                        addedLanguage = true,
                    )
                }
            }.onFailure {
                update {
                    copy(
                        loading = false,
                        error = it,
                    )
                }
            }
        }
    }

    data class State(
        val projectId: String? = null,
        val allSupportedLanguages: List<Language> = emptyList(),
        val loading: Boolean = true,
        val query: String = "",
        val error: Throwable? = null,
        val addedLanguage: Boolean = false,
    ) {
        val isQueryInvalid = query.length <= 1
        val supportedLanguages: List<Language> =
            if (isQueryInvalid) {
                emptyList()
            } else {
                allSupportedLanguages.filter { l -> l.matches(query) }
            }

        private fun Language.matches(query: String): Boolean {
            return listOfNotNull(languageId, languageName, localLanguageName, countryEmoji)
                .any { it.contains(query, ignoreCase = true) }
        }
    }
}
