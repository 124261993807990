package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.repository.AuthRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.usecase.LoginUseCase.Params
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.model.AuthRequest
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase

interface LoginUseCase : UseCase<Params, Unit> {
    data class Params(
        val request: AuthRequest,
    )
}

internal class LoginUseCaseImpl(
    private val authRepository: AuthRepository,
) : LoginUseCase {
    override suspend fun execute(params: Params) {
        authRepository.login(params.request)
    }
}
