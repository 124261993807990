package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.infrastructure.source

import cz.cvut.fit.horanvoj.ribbon.model.language.Language
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.data.source.LanguageSource
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.infrastructure.api.LanguageApi

internal class LanguageSourceImpl(
    private val languageApi: LanguageApi,
) : LanguageSource {
    override suspend fun getSupportedLanguages(): List<Language> {
        return languageApi.getSupportedLanguages()
    }

    override suspend fun getProjectLanguages(projectId: String): List<Language> {
        return languageApi.getProjectLanguages(projectId)
    }

    override suspend fun addLanguageToProject(
        projectId: String,
        languageId: String,
    ): List<Language> {
        return languageApi.addLanguageToProject(projectId, languageId)
    }

    override suspend fun removeLanguageFromProject(
        projectId: String,
        languageId: String,
    ): List<Language> {
        return languageApi.removeLanguageFromProject(projectId, languageId)
    }
}
