package cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.UserSelect
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.mdi.MdiCheckBox
import com.varabyte.kobweb.silk.components.icons.mdi.MdiCheckBoxOutlineBlank
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.addVariant
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.shapes.Circle
import com.varabyte.kobweb.silk.theme.shapes.clip
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.BackgroundTransition
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.CornerSizeRound
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceSmall
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.fadeInAnimation
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.toSitePalette
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Span

val CheckboxStyle by ComponentStyle {
    base {
        Modifier
            .size(40.px)
            .fontSize(18.px)
            .color(colorMode.toSitePalette().primary)
            .transition(BackgroundTransition)
            .clip(Circle())
            .cursor(Cursor.Pointer)
            .userSelect(UserSelect.None)
    }
}

val CheckboxWithHover by CheckboxStyle.addVariant {
    hover {
        Modifier
            .background(
                colorMode.toSitePalette().onSurface.toRgb()
                    .copy(alpha = 20),
            )
    }
}

val CheckboxItemStyle by ComponentStyle {
    base {
        Modifier
            .transition(BackgroundTransition)
            .borderRadius(CornerSizeRound)
            .cursor(Cursor.Pointer)
            .userSelect(UserSelect.None)
            .gap(SpaceSmall)
            .padding(
                right = SpaceMedium,
                left = SpaceSmall,
            )
    }

    hover {
        Modifier
            .background(
                colorMode.toSitePalette().onSurface.toRgb()
                    .copy(alpha = 20),
            )
    }
}

@Composable
fun Checkbox(
    checked: Boolean,
    onCheckedChange: (Boolean) -> Unit,
    modifier: Modifier = Modifier,
    clickable: Boolean = true,
) {
    val style = ColorMode.current.toSitePalette()

    Box(
        contentAlignment = Alignment.Center,
        modifier =
            CheckboxStyle
                .toModifier(
                    if (clickable) CheckboxWithHover else null,
                )
                .then(modifier)
                .thenIf(clickable) {
                    Modifier.onClick {
                        onCheckedChange(!checked)
                    }
                },
    ) {
        if (checked) {
            MdiCheckBox(
                modifier = Modifier
                    .color(style.primary)
                    .fadeInAnimation(),
            )
        } else {
            MdiCheckBoxOutlineBlank(
                modifier = Modifier
                    .color(style.onSurfaceVariant)
                    .fadeInAnimation(),
            )
        }
    }
}

@Composable
fun CheckboxItem(
    checked: Boolean,
    onCheckedChange: (Boolean) -> Unit,
    modifier: Modifier = Modifier,
    content: @Composable () -> Unit,
) {
    Row(
        modifier =
            CheckboxItemStyle
                .toModifier()
                .then(modifier)
                .onClick { onCheckedChange(!checked) },
        verticalAlignment = Alignment.CenterVertically,
    ) {
        Checkbox(
            checked = checked,
            clickable = false,
            onCheckedChange = { },
        )

        Span(
            attrs =
                Modifier
                    .typography(LabelLarge)
                    .toAttrs(),
        ) {
            content()
        }
    }
}
