package cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.StyleVariable
import com.varabyte.kobweb.compose.dom.ElementRefScope
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.style.*
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.CornerSizeMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.toSitePalette
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.w3c.dom.HTMLElement

val OutlinedCardBackground by StyleVariable<Color>()
val OutlinedCardColor by StyleVariable<Color>()

val OutlinedCardStyle by ComponentStyle {
    base {
        Modifier
            .background(OutlinedCardBackground.value(colorMode.toSitePalette().surfaceVariant))
            .color(OutlinedCardColor.value(colorMode.toSitePalette().onSurfaceVariant))
            .borderRadius(CornerSizeMedium)
            .border(1.px, LineStyle.Solid, colorMode.toSitePalette().outlineVariant)
    }
}

val OutlinedCardStyleWithClick by OutlinedCardStyle.addVariant {
    base {
        Modifier.transition(ElevationTransition)
    }

    hover {
        Modifier.materialShadow(level = MaterialShadowLevel.LEVEL_ONE)
    }
}

val OutlinedCardContentStyle by ComponentStyle.base {
    Modifier
        .padding(all = SpaceMedium)
        .fillMaxSize()
        .overflow(Overflow.Hidden)
}

@Composable
fun OutlinedCard(
    modifier: Modifier = Modifier,
    onClick: () -> Unit,
    isSelected: Boolean = false,
    ref: ElementRefScope<HTMLElement>? = null,
    content: @Composable () -> Unit,
) {
    Box(
        ref = ref,
        modifier = Modifier
            .onClick { onClick() }
            .then(OutlinedCardStyle.toModifier(OutlinedCardStyleWithClick))
            .then(modifier)
            .thenIf(isSelected) {
                Modifier.materialShadow(MaterialShadowLevel.LEVEL_ONE)
            }
            .cursor(Cursor.Pointer),
    ) {
        Box(OutlinedCardContentStyle.toModifier()) {
            content()
        }
    }
}

@Composable
fun OutlinedCard(
    modifier: Modifier = Modifier,
    ref: ElementRefScope<HTMLElement>? = null,
    content: @Composable () -> Unit,
) {
    Box(
        ref = ref,
        modifier =
            OutlinedCardStyle.toModifier()
                .then(modifier),
    ) {
        Box(OutlinedCardContentStyle.toModifier()) {
            content()
        }
    }
}

@Composable
fun OutlinedCard(
    modifier: Modifier = Modifier,
    ref: ElementRefScope<HTMLElement>? = null,
    icon: (@Composable () -> Unit)? = null,
    title: @Composable () -> Unit,
    content: @Composable () -> Unit,
) {
    Box(
        ref = ref,
        modifier =
            OutlinedCardStyle.toModifier()
                .then(modifier),
    ) {
        Box(OutlinedCardContentStyle.toModifier()) {
            Column(
                modifier = Modifier
                    .fillMaxSize(),
            ) {
                if (icon != null) {
                    Box(
                        modifier = Modifier
                            .fontSize(24.px)
                            .padding(bottom = SpaceMedium)
                            .color(ColorMode.current.toSitePalette().primary),
                    ) { icon() }
                }

                Box(
                    modifier = Modifier
                        .typography(HeadlineSmall),
                ) {
                    title()
                }

                Box(
                    modifier = Modifier
                        .fillMaxSize()
                        .typography(BodyMedium)
                        .padding(top = SpaceMedium),
                ) {
                    content()
                }
            }
        }
    }
}
