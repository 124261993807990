package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.model.user.User
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.repository.AuthRepository
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCaseNoParams

interface GetLoggedInUserUseCase : UseCaseNoParams<User?>

internal class GetLoggedInUserUseCaseImpl(
    private val authRepository: AuthRepository,
) : GetLoggedInUserUseCase {
    override suspend fun execute(): User {
        return authRepository.getUser()
    }
}
