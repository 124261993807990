package cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets

import Res
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.Snackbar
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.rememberSnackbarState
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.toSitePalette

@Composable
fun ErrorSnackbar(throwable: Throwable?) {
    val style = ColorMode.current.toSitePalette()
    val state = rememberSnackbarState()

    LaunchedEffect(throwable) {
        if (throwable != null) {
            state.show(throwable.message ?: Res.string.error_unknown)
        }
    }

    Snackbar(
        state = state,
        color = style.onErrorContainer,
        backgroundColor = style.errorContainer,
    )
}
