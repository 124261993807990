package cz.cvut.fit.horanvoj.ribbon.model.batch

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
enum class BatchFormat {
    @SerialName("json")
    JSON,

    @SerialName("android")
    ANDROID,

    @SerialName("apple")
    APPLE,
}

val BatchFormat.extension: String
    get() =
        when (this) {
            BatchFormat.JSON -> "json"
            BatchFormat.ANDROID -> "xml"
            BatchFormat.APPLE -> "strings"
        }
