package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.batch

import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.batch.data.repository.BatchRepositoryImpl
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.batch.data.source.BatchSource
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.batch.domain.repository.BatchRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.batch.domain.usecase.ImportUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.batch.domain.usecase.ImportUseCaseImpl
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.batch.infrastructure.api.BatchApi
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.batch.infrastructure.api.BatchApiImpl
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.batch.infrastructure.source.BatchSourceImpl
import org.koin.core.module.dsl.factoryOf
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.bind
import org.koin.dsl.module

val featureBatchModule =
    module {
        singleOf(::BatchApiImpl) bind BatchApi::class
        singleOf(::BatchSourceImpl) bind BatchSource::class
        singleOf(::BatchRepositoryImpl) bind BatchRepository::class

        factoryOf(::ImportUseCaseImpl) bind ImportUseCase::class
    }
