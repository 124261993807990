package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.model.language.Language
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.domain.repository.LanguageRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.domain.usecase.AddLanguageToProjectUseCase.Params
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase

interface AddLanguageToProjectUseCase : UseCase<Params, List<Language>> {
    data class Params(
        val projectId: String,
        val languageId: String,
    )
}

internal class AddLanguageToProjectUseCaseImpl(
    private val languageRepository: LanguageRepository,
) : AddLanguageToProjectUseCase {
    override suspend fun execute(params: Params): List<Language> {
        return languageRepository.addLanguageToProject(
            projectId = params.projectId,
            languageId = params.languageId,
        )
    }
}
