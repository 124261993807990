package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.routeToProjects

@Page
@Composable
fun Index() {
    val ctx = rememberPageContext()

    LaunchedEffect(ctx) {
        ctx.router.routeToProjects()
    }
}
