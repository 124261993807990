package cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.base
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.cssRem

val Number.sp
    get() = (this.toFloat() / 16f).cssRem

private const val TYPOGRAPHY_PREFIX = "material-typography"

@Composable
fun Modifier.typography(style: ComponentStyle) = this.then(style.toModifier())

fun Modifier.singleLine() =
    this
        .textOverflow(TextOverflow.Ellipsis)
        .overflow(Overflow.Hidden)
        .whiteSpace(WhiteSpace.NoWrap)

val DisplayLarge by ComponentStyle
    .base(prefix = TYPOGRAPHY_PREFIX) {
        Modifier
            .fontSize(57.sp)
            .fontWeight(FontWeight.Normal)
            .letterSpacing((-0.25).sp)
            .lineHeight(64.sp)
    }

val DisplayMedium by ComponentStyle
    .base(prefix = TYPOGRAPHY_PREFIX) {
        Modifier
            .fontSize(45.sp)
            .fontWeight(FontWeight.Normal)
            .letterSpacing(0.sp)
            .lineHeight(52.sp)
    }

val DisplaySmall by ComponentStyle
    .base(prefix = TYPOGRAPHY_PREFIX) {
        Modifier
            .fontSize(36.sp)
            .fontWeight(FontWeight.Normal)
            .letterSpacing(0.sp)
            .lineHeight(44.sp)
    }

val HeadlineLarge by ComponentStyle
    .base(prefix = TYPOGRAPHY_PREFIX) {
        Modifier
            .fontSize(32.sp)
            .fontWeight(FontWeight.Normal)
            .letterSpacing(0.sp)
            .lineHeight(40.sp)
    }

val HeadlineMedium by ComponentStyle
    .base(prefix = TYPOGRAPHY_PREFIX) {
        Modifier
            .fontSize(28.sp)
            .fontWeight(FontWeight.Normal)
            .letterSpacing(0.sp)
            .lineHeight(36.sp)
    }

val HeadlineSmall by ComponentStyle
    .base(prefix = TYPOGRAPHY_PREFIX) {
        Modifier
            .fontSize(24.sp)
            .fontWeight(FontWeight.Normal)
            .letterSpacing(0.sp)
            .lineHeight(32.sp)
    }

val TitleLarge by ComponentStyle
    .base(prefix = TYPOGRAPHY_PREFIX) {
        Modifier
            .fontSize(22.sp)
            .fontWeight(FontWeight.Normal)
            .letterSpacing(0.sp)
            .lineHeight(28.sp)
    }

val TitleMedium by ComponentStyle
    .base(prefix = TYPOGRAPHY_PREFIX) {
        Modifier
            .fontSize(16.sp)
            .fontWeight(FontWeight.Medium)
            .letterSpacing(0.15.sp)
            .lineHeight(24.sp)
    }

val TitleSmall by ComponentStyle
    .base(prefix = TYPOGRAPHY_PREFIX) {
        Modifier
            .fontSize(14.sp)
            .fontWeight(FontWeight.Medium)
            .letterSpacing(0.1.sp)
            .lineHeight(20.sp)
    }

val BodyLarge by ComponentStyle
    .base(prefix = TYPOGRAPHY_PREFIX) {
        Modifier
            .fontSize(16.sp)
            .fontWeight(FontWeight.Normal)
            .letterSpacing(0.5.sp)
            .lineHeight(24.sp)
    }

val BodyMedium by ComponentStyle
    .base(prefix = TYPOGRAPHY_PREFIX) {
        Modifier
            .fontSize(14.sp)
            .fontWeight(FontWeight.Normal)
            .letterSpacing(0.25.sp)
            .lineHeight(20.sp)
    }

val BodySmall by ComponentStyle
    .base(prefix = TYPOGRAPHY_PREFIX) {
        Modifier
            .fontSize(12.sp)
            .fontWeight(FontWeight.Normal)
            .letterSpacing(0.4.sp)
            .lineHeight(16.sp)
    }

val LabelLarge by ComponentStyle
    .base(prefix = TYPOGRAPHY_PREFIX) {
        Modifier
            .fontSize(14.sp)
            .fontWeight(FontWeight.Medium)
            .letterSpacing(0.1.sp)
            .lineHeight(20.sp)
    }

val LabelLargeProminent by ComponentStyle
    .base(prefix = TYPOGRAPHY_PREFIX) {
        Modifier
            .fontSize(14.sp)
            .fontWeight(FontWeight.Bold)
            .letterSpacing(0.1.sp)
            .lineHeight(20.sp)
    }

val LabelMedium by ComponentStyle
    .base(prefix = TYPOGRAPHY_PREFIX) {
        Modifier
            .fontSize(12.sp)
            .fontWeight(FontWeight.Medium)
            .letterSpacing(0.5.sp)
            .lineHeight(16.sp)
            .textTransform(TextTransform.None)
    }

val LabelMediumProminent by ComponentStyle
    .base(prefix = TYPOGRAPHY_PREFIX) {
        Modifier
            .fontSize(12.sp)
            .fontWeight(FontWeight.Bold)
            .letterSpacing(0.5.sp)
            .lineHeight(16.sp)
            .textTransform(TextTransform.None)
    }

val LabelSmall by ComponentStyle
    .base(prefix = TYPOGRAPHY_PREFIX) {
        Modifier
            .fontSize(11.sp)
            .fontWeight(FontWeight.Medium)
            .letterSpacing(0.5.sp)
            .lineHeight(16.sp)
    }
