package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.model.user.User
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.domain.repository.UserRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.domain.usecase.GetUserUseCase.Params
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase

interface GetUserUseCase : UseCase<Params, User> {
    data class Params(
        val userId: String,
    )
}

internal class GetUserUseCaseImpl(
    private val userRepository: UserRepository,
) : GetUserUseCase {
    override suspend fun execute(params: Params): User {
        return userRepository.getUser(params.userId)
    }
}
