package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.login

import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.usecase.ResetPasswordUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.invoke
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.ViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.login.ForgottenPasswordViewModel.State
import kotlinx.coroutines.delay
import kotlin.time.Duration.Companion.seconds

internal class ForgottenPasswordViewModel(
    private val resetPasswordUseCase: ResetPasswordUseCase,
) : ViewModel<State>(State()) {
    fun onResetPasswordClick() {
        launch {
            resetPasswordUseCase(
                ResetPasswordUseCase.Params(
                    state.email,
                ),
            )
            update { copy(hasResetPassword = true) }
            delay(1.seconds)
            update { copy(hasResetPassword = false) }
        }
    }

    fun onEmailChange(email: String) {
        update { copy(email = email) }
    }

    data class State(
        val hasResetPassword: Boolean = false,
        val email: String = "",
        val error: Throwable? = null,
    )
}
