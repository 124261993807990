package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.infrastructure.api

import cz.cvut.fit.horanvoj.ribbon.model.language.Language
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.use
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*

internal interface LanguageApi {
    suspend fun getSupportedLanguages(): List<Language>

    suspend fun getProjectLanguages(projectId: String): List<Language>

    suspend fun addLanguageToProject(
        projectId: String,
        languageId: String,
    ): List<Language>

    suspend fun removeLanguageFromProject(
        projectId: String,
        languageId: String,
    ): List<Language>
}

internal class LanguageApiImpl(
    private val httpClient: HttpClient,
) : LanguageApi {
    override suspend fun getSupportedLanguages(): List<Language> {
        return httpClient.use {
            get("api/v1/language").body()
        }
    }

    override suspend fun getProjectLanguages(projectId: String): List<Language> {
        return httpClient.use {
            get("api/v1/project/$projectId/language")
                .body()
        }
    }

    override suspend fun addLanguageToProject(
        projectId: String,
        languageId: String,
    ): List<Language> {
        return httpClient.use {
            post("api/v1/project/$projectId/language/$languageId")
                .body()
        }
    }

    override suspend fun removeLanguageFromProject(
        projectId: String,
        languageId: String,
    ): List<Language> {
        return httpClient.use {
            delete("api/v1/project/$projectId/language/$languageId")
                .body()
        }
    }
}
