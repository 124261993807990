package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.domain.repository.UserRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.domain.usecase.DeclineInviteUseCase.Params
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase

interface DeclineInviteUseCase : UseCase<Params, Unit> {
    data class Params(
        val inviteId: String,
    )
}

internal class DeclineInviteUseCaseImpl(
    private val userRepository: UserRepository,
) : DeclineInviteUseCase {
    override suspend fun execute(params: Params) {
        return userRepository.declineInvite(
            inviteId = params.inviteId,
        )
    }
}
