package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.model.invite.Invite
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.domain.repository.UserRepository
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCaseNoParams

interface GetInvitesUseCase : UseCaseNoParams<List<Invite>>

internal class GetInvitesUseCaseImpl(
    private val userRepository: UserRepository,
) : GetInvitesUseCase {
    override suspend fun execute(): List<Invite> {
        return userRepository.getInvites()
    }
}
