package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.data.repository

import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.data.source.CommonSource
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.repository.CommonRepository

internal class CommonRepositoryImpl(
    private val commonSource: CommonSource,
) : CommonRepository {
    override suspend fun clearCaches() {
        commonSource.clearCaches()
    }
}
