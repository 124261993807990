package cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.background
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.base
import com.varabyte.kobweb.silk.components.style.toModifier
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.toSitePalette
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div

val MaterialHorizontalDividerStyle by ComponentStyle.base {
    Modifier
        .width(100.percent)
        .height(1.px)
        .background(colorMode.toSitePalette().outlineVariant)
        .margin(0.px)
}

val MaterialVerticalDividerStyle by ComponentStyle.base {
    Modifier
        .height(100.percent)
        .width(1.px)
        .background(colorMode.toSitePalette().outlineVariant)
        .margin(0.px)
}

@Composable
fun MaterialHorizontalDivider(modifier: Modifier = Modifier) {
    Div(
        attrs =
            MaterialHorizontalDividerStyle.toModifier()
                .then(modifier)
                .toAttrs(),
    )
}

@Composable
fun MaterialVerticalDivider(modifier: Modifier = Modifier) {
    Div(
        attrs =
            MaterialVerticalDividerStyle.toModifier()
                .then(modifier)
                .toAttrs(),
    )
}
