package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.infrastructure.source

import cz.cvut.fit.horanvoj.ribbon.model.batch.BatchFormat
import cz.cvut.fit.horanvoj.ribbon.model.invite.Invite
import cz.cvut.fit.horanvoj.ribbon.model.invite.InviteCreation
import cz.cvut.fit.horanvoj.ribbon.model.project.*
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.data.source.ProjectRemoteSource
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.infrastructure.api.ProjectApi

internal class ProjectRemoteSourceImpl(
    private val projectApi: ProjectApi,
) : ProjectRemoteSource {
    override suspend fun getProjects(): List<Project> {
        return projectApi.getProjects()
    }

    override suspend fun getProject(projectId: String): ProjectWithMembership {
        return projectApi.getProject(projectId)
    }

    override suspend fun createProject(creation: ProjectCreation): Project {
        return projectApi.createProject(creation)
    }

    override suspend fun modifyProject(
        projectId: String,
        projectModification: ProjectModification,
    ): ProjectWithMembership {
        return projectApi.modifyProject(projectId, projectModification)
    }

    override suspend fun removeProject(projectId: String) {
        projectApi.removeProject(projectId)
    }

    override suspend fun createApiKey(projectId: String) {
        projectApi.createApiKey(projectId)
    }

    override suspend fun getApiKey(projectId: String): String? {
        return projectApi.getApiKey(projectId)
    }

    override suspend fun removeApiKey(projectId: String) {
        projectApi.removeApiKey(projectId)
    }

    override suspend fun getMembers(projectId: String): List<ProjectMember> {
        return projectApi.getMembers(projectId)
    }

    override suspend fun removeMember(
        projectId: String,
        userId: String,
    ): List<ProjectMember> {
        return projectApi.removeMember(projectId, userId)
    }

    override suspend fun modifyMemberRole(
        projectId: String,
        userId: String,
        modification: MemberModification,
    ): List<ProjectMember> {
        return projectApi.modifyMemberRole(projectId, userId, modification)
    }

    override suspend fun inviteMember(inviteCreation: InviteCreation) {
        projectApi.inviteMember(inviteCreation)
    }

    override suspend fun getInvites(projectId: String): List<Invite> {
        return projectApi.getInvites(projectId)
    }

    override suspend fun removeInvite(
        inviteId: String,
        projectId: String,
    ) {
        projectApi.removeInvite(inviteId, projectId)
    }

    override suspend fun createOneTimeExport(
        projectId: String,
        format: BatchFormat,
        languageId: String,
    ): OneTimeExport {
        return projectApi.createOneTimeExport(projectId, format, languageId)
    }
}
