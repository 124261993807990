package cz.cvut.fit.horanvoj.ribbon.unwinder.web.domain

import Res
import cz.cvut.fit.horanvoj.ribbon.model.term.TermFilter

val TermFilter.displayName: String
    get() =
        when (this) {
            TermFilter.ALL -> Res.string.label_term_filter_all
            TermFilter.TRANSLATED -> Res.string.label_term_filter_translated
            TermFilter.NOT_TRANSLATED -> Res.string.label_term_filter_not_translated
        }
