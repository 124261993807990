package cz.cvut.fit.horanvoj.ribbon.util

import kotlin.enums.enumEntries

/**
 * Find the enum value from the [E] enum corresponding to the [name].
 */
@OptIn(ExperimentalStdlibApi::class)
inline fun <reified E : Enum<E>> findEnumValue(name: String): E? {
    return enumEntries<E>().firstOrNull { entry -> entry.name.equals(name.lowercase(), ignoreCase = true) }
}

inline fun <reified E : Enum<E>> requireEnumValue(name: String): E = findEnumValue<E>(name) ?: error("Enum value not found")

inline fun <reified E : Enum<E>> E.serialized(): String = this.name.lowercase()
