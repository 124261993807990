package cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts

import Res
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.forms.ButtonVars
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.mdi.MdiLogout
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import cz.cvut.fit.horanvoj.ribbon.model.user.User
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.*
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceSmall
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.*
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.UserImage
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.toSitePalette
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px

val NavHeaderStyle by ComponentStyle {
    base {
        Modifier.fillMaxWidth()
            .padding(all = SpaceSmall)
    }

    Breakpoint.SM {
        Modifier
            .padding {
                left(SpaceMedium)
                right(SpaceMedium)
                top(SpaceSmall)
                bottom(SpaceSmall)
            }
    }
}

@Composable
fun NavHeader(
    title: String?,
    activeTab: ProjectTab? = null,
) {
    val ctx = rememberPageContext()
    val projectId = ctx.route.params["projectId"]

    val style = ColorMode.current.toSitePalette()
    val viewModel = rememberViewModel<NavHeaderViewModel>()

    LaunchedEffect(viewModel, projectId) {
        viewModel.onAppear(projectId)
    }

    LaunchedEffect(activeTab) {
        viewModel.setActiveTab(activeTab)
    }

    LaunchedEffect(viewModel.state.didLogOut) {
        if (viewModel.state.didLogOut) {
            ctx.router.routeToLogin()
        }
    }

    Column(
        modifier = Modifier.fillMaxWidth()
            .backgroundColor(style.background)
            .color(style.onBackground),
    ) {
        Row(
            modifier = NavHeaderStyle.toModifier(),
            verticalAlignment = Alignment.CenterVertically,
        ) {
            LogoAndTitle(
                section = title,
                project = viewModel.state.project?.name,
                onLogoClick = {
                    viewModel.clearCurrentProject()
                    ctx.router.routeToHomepage()
                },
            )

            Spacer()

            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier
                    .gap(SpaceSmall),
            ) {
                val user = viewModel.state.user
                if (user != null) {
                    UserInfo(
                        user = user,
                        onClick = {
                            ctx.router.routeToUser()
                        },
                    )

                    MaterialIconButton(
                        onClick = {
                            viewModel.logout()
                        },
                    ) {
                        MdiLogout(
                            modifier = Modifier
                                .color(style.onBackground),
                        )
                    }
                }
            }
        }

        if (projectId != null && viewModel.state.canManage) {
            Tabs {
                Tab(
                    text = Res.string.button_translate,
                    isActive = viewModel.state.isTranslateActive,
                    onClick = {
                        ctx.router.routeToLanguages(projectId)
                    },
                )

                Tab(
                    text = Res.string.button_terms,
                    isActive = viewModel.state.isTermsActive,
                    onClick = {
                        ctx.router.routeToManageTerms(projectId)
                    },
                )

                Tab(
                    text = Res.string.button_manage,
                    isActive = viewModel.state.isManageActive,
                    onClick = {
                        ctx.router.routeToManage(projectId)
                    },
                )
            }
        }
    }
}

@Composable
private fun LogoAndTitle(
    onLogoClick: () -> Unit,
    section: String?,
    project: String?,
) {
    Image(
        src = "/ribbon-logo.webp",
        description = Res.string.app_name,
        modifier = Modifier
            .height(2.cssRem)
            .display(DisplayStyle.Block)
            .cursor(Cursor.Pointer)
            .onClick {
                onLogoClick()
            },
    )

    Column(
        verticalArrangement = Arrangement.Center,
        modifier = Modifier
            .margin(left = SpaceMedium)
            .flexGrow(1),
    ) {
        if (project != null && section != null) {
            SpanText(
                text = project,
                modifier = Modifier
                    .typography(TitleLarge)
                    .singleLine(),
            )

            SpanText(
                text = section,
                modifier = Modifier
                    .typography(TitleSmall)
                    .singleLine(),
            )
        } else {
            SpanText(
                text = section ?: Res.string.app_name,
                modifier = Modifier
                    .typography(TitleLarge)
                    .singleLine(),
            )
        }
    }
}

@Composable
fun UserInfo(
    user: User,
    onClick: () -> Unit,
) {
    MaterialTextButton(
        onClick = {
            onClick()
        },
        modifier = Modifier
            .setVariable(ButtonVars.Color, ColorMode.current.toSitePalette().onBackground),
    ) {
        UserImage(
            user = user,
            size = 24.px,
        )

        SpanText(
            text = user.name,
            modifier = Modifier
                .margin(left = SpaceSmall)
                .displayIfAtLeast(Breakpoint.SM),
        )
    }
}
