package cz.cvut.fit.horanvoj.ribbon.unwinder.web

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.scrollBehavior
import com.varabyte.kobweb.compose.ui.modifiers.setVariable
import com.varabyte.kobweb.core.App
import com.varabyte.kobweb.silk.SilkApp
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.components.navigation.LinkVars
import com.varabyte.kobweb.silk.components.style.common.SmoothColorStyle
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.localStorage
import org.jetbrains.compose.web.css.vh
import org.koin.compose.KoinApplication

private const val COLOR_MODE_KEY = "unwinder:colorMode"

@InitSilk
fun initColorMode(ctx: InitSilkContext) {
    val colorModeValue = localStorage.getItem(COLOR_MODE_KEY)
    val colorMode =
        if (colorModeValue == null) {
            ColorMode.LIGHT
        } else {
            ColorMode.valueOf(colorModeValue)
        }

    ctx.config.initialColorMode = colorMode
}

@App
@Composable
fun AppEntry(content: @Composable () -> Unit) {
    KoinApplication(
        { modules(unwinderModule) },
    ) {
        SilkApp {
            val colorMode = ColorMode.current
            LaunchedEffect(colorMode) {
                localStorage.setItem(COLOR_MODE_KEY, colorMode.name)
            }

            val style = colorMode.toSitePalette()

            Surface(
                modifier =
                    SmoothColorStyle.toModifier()
                        .minHeight(100.vh)
                        .scrollBehavior(ScrollBehavior.Smooth)
                        .setVariable(LinkVars.DefaultColor, style.primary)
                        .setVariable(LinkVars.VisitedColor, style.primary),
            ) {
                content()
            }
        }
    }
}
