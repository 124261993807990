package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.translate

import Res
import androidx.compose.runtime.*
import com.varabyte.kobweb.browser.dom.clearFocus
import com.varabyte.kobweb.compose.css.FontStyle
import com.varabyte.kobweb.compose.css.WordBreak
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.mdi.MdiArrowRightAlt
import com.varabyte.kobweb.silk.components.icons.mdi.MdiFilterList
import com.varabyte.kobweb.silk.components.icons.mdi.MdiNotes
import com.varabyte.kobweb.silk.components.text.SpanText
import cz.cvut.fit.horanvoj.ribbon.model.common.Order
import cz.cvut.fit.horanvoj.ribbon.model.language.Language
import cz.cvut.fit.horanvoj.ribbon.model.project.RolePermission
import cz.cvut.fit.horanvoj.ribbon.model.term.TermFilter
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.EscapeKeyEffect
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceSmall
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.filter.TermFilterDialog
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.PageLayout
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.ProjectTab
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.RequireAuthenticationEffect
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.RequireProjectPermissionEffect
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.*
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.rememberViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.routeToTranslations
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.EmptyView
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.ErrorSnackbar
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.PageControls
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.TranslationTextArea
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.domain.displayName
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.enterVerticallyAnimation
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.translate.referenceLanguage.ReferenceLanguageDialog
import cz.cvut.fit.horanvoj.ribbon.util.findEnumValue
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.css.vw
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLTextAreaElement

@Page("/project/{projectId}/languages/{languageId}")
@Composable
fun TranslatePage() {
    RequireAuthenticationEffect()
    RequireProjectPermissionEffect(
        RolePermission.CREATE_TRANSLATION,
        RolePermission.MODIFY_TRANSLATION,
        RolePermission.REMOVE_TRANSLATION,
    )

    val ctx = rememberPageContext()
    val projectId = ctx.route.params.getValue("projectId")
    val languageId = ctx.route.params.getValue("languageId")

    val query = ctx.route.params["query"]
    val cursor = ctx.route.params["cursor"]
    val referenceLanguage = ctx.route.params["referenceLanguage"]
    val order = ctx.route.params["order"]?.let { findEnumValue<Order>(it) }
    val filter = ctx.route.params["filter"]?.let { findEnumValue<TermFilter>(it) }

    val viewModel = rememberViewModel<TranslateViewModel>()

    LaunchedEffect(viewModel, projectId, languageId, query, cursor, referenceLanguage, order, filter) {
        viewModel.onAppear(projectId, languageId, query, cursor, referenceLanguage, order, filter)
    }

    if (viewModel.state.selectingReferenceLanguage) {
        ReferenceLanguageDialog(
            onSelected = {
                viewModel.onReferenceLanguageSelected()
                ctx.router.routeToTranslations(
                    projectId = projectId,
                    languageId = languageId,
                    query = query,
                    cursor = cursor,
                    referenceLanguage = it.languageId,
                    order = order,
                    filter = filter,
                )
            },
            onCancelled = viewModel::onReferenceLanguageDialogDismiss,
        )
    }

    if (viewModel.state.filteringTerms) {
        TermFilterDialog(
            filter = viewModel.state.filter,
            order = viewModel.state.order,
            onCancelled = viewModel::onFilterCancelled,
            onConfirmed = { newFilter, newOrder ->
                viewModel.onFilterConfirmed()

                ctx.router.routeToTranslations(
                    projectId = projectId,
                    languageId = languageId,
                    cursor = cursor,
                    query = query,
                    referenceLanguage = referenceLanguage,
                    order = newOrder,
                    filter = newFilter,
                )
            },
        )
    }

    ErrorSnackbar(
        throwable = viewModel.state.error,
    )

    PageLayout(
        title = Res.string.title_translate,
        activeTab = ProjectTab.TRANSLATE,
        loading = viewModel.state.loading,
    ) {
        Column(
            modifier = Modifier
                .padding(SpaceMedium)
                .fillMaxWidth()
                .gap(SpaceMedium),
        ) {
            TranslateControls(
                projectId = projectId,
                query = query,
                language = viewModel.state.language,
                referenceLanguage = viewModel.state.referenceLanguage,
                onSelectReferenceLanguage = {
                    viewModel.onSelectReferenceLanguage()
                },
                onFilterClick = viewModel::onFilterClicked,
                filter = viewModel.state.filter,
                order = viewModel.state.order,
            )

            OutlinedCard(
                modifier = Modifier
                    .fillMaxWidth()
                    .minHeight(70.vh),
            ) {
                Column(Modifier.fillMaxWidth()) {
                    if (viewModel.state.translations.isEmpty()) {
                        EmptyView(
                            text = Res.string.label_translations_empty,
                        )
                    } else {
                        viewModel.state.translations.forEachIndexed { index, translation ->
                            Term(
                                translation = translation,
                                onSaveTranslation = {
                                    viewModel.onSaveTranslation(
                                        termId = translation.termId,
                                        translation = it,
                                    )
                                },
                                hasReferenceLanguage = viewModel.state.referenceLanguage != null,
                            )

                            if (index != viewModel.state.translations.lastIndex) {
                                MaterialHorizontalDivider()
                            }
                        }
                    }
                }
            }

            PageControls(
                hasPreviousPage = viewModel.state.hasPreviousPage,
                hasNextPage = viewModel.state.hasNextPage,
                onPreviousPageClicked = {
                    ctx.router.routeToTranslations(
                        projectId = projectId,
                        languageId = languageId,
                        cursor = viewModel.state.previousPage,
                        query = query,
                        referenceLanguage = referenceLanguage,
                        order = order,
                        filter = filter,
                    )
                },
                onNextPageClicked = {
                    ctx.router.routeToTranslations(
                        projectId = projectId,
                        languageId = languageId,
                        cursor = viewModel.state.nextPage,
                        query = query,
                        referenceLanguage = referenceLanguage,
                        order = order,
                        filter = filter,
                    )
                },
            )
        }
    }
}

@Composable
fun TranslateControls(
    projectId: String,
    query: String?,
    language: Language?,
    referenceLanguage: Language?,
    order: Order,
    filter: TermFilter,
    onFilterClick: () -> Unit,
    onSelectReferenceLanguage: () -> Unit,
) {
    val ctx = rememberPageContext()

    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier
            .fillMaxWidth()
            .flexWrap(FlexWrap.WrapReverse)
            .gap(SpaceSmall),
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier
                .gap(SpaceSmall),
        ) {
            MaterialTonalButton(
                onClick = {
                    onSelectReferenceLanguage()
                },
            ) {
                if (referenceLanguage != null) {
                    Text(referenceLanguage.displayName)
                } else {
                    Text(Res.string.button_set_reference_language)
                }
            }

            if (language != null) {
                MdiArrowRightAlt()

                SpanText(
                    text = language.displayName,
                    modifier = Modifier
                        .typography(LabelLarge),
                )
            }
        }

        Spacer()

        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier
                .gap(SpaceSmall),
        ) {
            MaterialTonalButton(
                onClick = { onFilterClick() },
                modifier = Modifier
                    .margin(right = SpaceSmall),
            ) {
                MdiFilterList()
                Text(Res.string.label_filter)
            }

            SpanText(
                text = order.displayName + ", " + filter.displayName,
                modifier = Modifier
                    .typography(TitleMedium)
                    .margin(right = SpaceSmall),
            )
        }

        var inputQuery by remember { mutableStateOf(query.orEmpty()) }

        SearchInputOutlined(
            text = inputQuery,
            onTextChanged = {
                inputQuery = it
            },
            onCommit = {
                if (language != null) {
                    ctx.router.routeToTranslations(
                        projectId = projectId,
                        languageId = language.languageId,
                        query = inputQuery,
                        referenceLanguage = referenceLanguage?.languageId,
                        order = order,
                        filter = filter,
                    )
                }
            },
        )
    }
}

@Composable
private fun Term(
    hasReferenceLanguage: Boolean,
    translation: TranslationViewObject,
    onSaveTranslation: suspend (String) -> Boolean,
    modifier: Modifier = Modifier,
) {
    val context = rememberCoroutineScope()
    var isTextInputFocused by remember {
        mutableStateOf(false)
    }

    var editedInput by remember(translation.translation) {
        mutableStateOf(translation.translation.orEmpty())
    }

    var isSaving by remember { mutableStateOf(false) }

    Column(
        modifier = modifier
            .fillMaxWidth(),
    ) {
        if (isSaving) {
            HorizontalProgressBar(
                Modifier.enterVerticallyAnimation()
                    .fillMaxWidth(),
            )
        }

        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier
                .fillMaxWidth()
                .flexWrap(FlexWrap.Wrap),
        ) {
            Column(
                verticalArrangement = Arrangement.Center,
                modifier = Modifier
                    .minWidth(35.vw)
                    .padding(topBottom = SpaceSmall)
                    .flex(1),
            ) {
                if (hasReferenceLanguage) {
                    if (translation.referenceTranslation != null) {
                        SpanText(
                            text = translation.referenceTranslation,
                            modifier = Modifier
                                .typography(BodyLarge),
                        )
                    } else {
                        SpanText(
                            text = Res.string.label_no_translation,
                            modifier = Modifier
                                .typography(BodyLarge)
                                .color(Colors.Red)
                                .fontStyle(FontStyle.Italic),
                        )
                    }

                    SpanText(
                        text = translation.termId,
                        modifier = Modifier
                            .typography(LabelMediumProminent)
                            .wordBreak(WordBreak.BreakAll),
                    )
                } else {
                    SpanText(
                        text = translation.termId,
                        modifier = Modifier
                            .typography(BodyLarge)
                            .wordBreak(WordBreak.BreakAll),
                    )
                }

                translation.description?.let {
                    Row(
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier
                            .gap(SpaceSmall)
                            .typography(BodySmall),
                    ) {
                        MdiNotes(
                            modifier = Modifier
                                .title(Res.string.label_description),
                        )

                        SpanText(text = it)
                    }
                }
            }

            Box(
                modifier = Modifier
                    .minWidth(50.vw)
                    .padding(topBottom = SpaceSmall)
                    .flex(2),
            ) {
                var textAreaRef: HTMLTextAreaElement? by remember { mutableStateOf(null) }

                if (isTextInputFocused) {
                    EscapeKeyEffect {
                        editedInput = translation.translation.orEmpty()
                        textAreaRef?.clearFocus()
                        isTextInputFocused = false
                    }
                }

                TranslationTextArea(
                    enabled = !isSaving,
                    value = editedInput,
                    isFocused = isTextInputFocused,
                    onInput = { input ->
                        editedInput = input
                    },
                    onBlur = {
                        if (editedInput != translation.translation &&
                            !(editedInput.isEmpty() && translation.translation == null)
                        ) {
                            context.launch {
                                isSaving = true
                                if (onSaveTranslation(editedInput)) {
                                    isSaving = false
                                    isTextInputFocused = false
                                }
                            }
                        } else {
                            isTextInputFocused = false
                        }
                    },
                    onClick = {
                        isTextInputFocused = true
                    },
                    onFocus = {
                        isTextInputFocused = true
                    },
                    ref = {
                        textAreaRef = it

                        onDispose {
                            textAreaRef = null
                        }
                    },
                )

                if (!isTextInputFocused && translation.translation == null) {
                    SpanText(
                        text = Res.string.label_no_translation,
                        modifier = Modifier
                            .padding(SpaceMedium)
                            .typography(BodyLarge)
                            .color(Colors.Red)
                            .fontStyle(FontStyle.Italic)
                            .onClick {
                                isTextInputFocused = true
                                textAreaRef?.focus()
                            },
                    )
                }
            }
        }
    }
}
