package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.infrastructure.api

import cz.cvut.fit.horanvoj.ribbon.model.batch.BatchFormat
import cz.cvut.fit.horanvoj.ribbon.model.invite.Invite
import cz.cvut.fit.horanvoj.ribbon.model.invite.InviteCreation
import cz.cvut.fit.horanvoj.ribbon.model.project.*
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.use
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.http.*

internal interface ProjectApi {
    suspend fun getProjects(): List<Project>

    suspend fun getProject(projectId: String): ProjectWithMembership

    suspend fun createProject(creation: ProjectCreation): Project

    suspend fun modifyProject(
        projectId: String,
        projectModification: ProjectModification,
    ): ProjectWithMembership

    suspend fun removeProject(projectId: String)

    suspend fun createApiKey(projectId: String)

    suspend fun getApiKey(projectId: String): String?

    suspend fun removeApiKey(projectId: String)

    suspend fun getMembers(projectId: String): List<ProjectMember>

    suspend fun removeMember(
        projectId: String,
        userId: String,
    ): List<ProjectMember>

    suspend fun modifyMemberRole(
        projectId: String,
        userId: String,
        modification: MemberModification,
    ): List<ProjectMember>

    suspend fun getInvites(projectId: String): List<Invite>

    suspend fun removeInvite(
        inviteId: String,
        projectId: String,
    )

    suspend fun inviteMember(inviteCreation: InviteCreation)

    suspend fun createOneTimeExport(
        projectId: String,
        format: BatchFormat,
        languageId: String,
    ): OneTimeExport
}

internal class ProjectApiImpl(
    private val httpClient: HttpClient,
) : ProjectApi {
    override suspend fun getProjects(): List<Project> {
        return httpClient.use {
            get("api/v1/project")
                .body()
        }
    }

    override suspend fun getProject(projectId: String): ProjectWithMembership {
        return httpClient.use {
            get("api/v1/project/$projectId")
                .body()
        }
    }

    override suspend fun createProject(creation: ProjectCreation): Project {
        return httpClient.use {
            post("api/v1/project") {
                setBody(creation)
            }.body()
        }
    }

    override suspend fun modifyProject(
        projectId: String,
        projectModification: ProjectModification,
    ): ProjectWithMembership {
        return httpClient.use {
            post("api/v1/project/$projectId") {
                setBody(projectModification)
            }.body()
        }
    }

    override suspend fun removeProject(projectId: String) {
        return httpClient.use {
            delete("api/v1/project/$projectId")
                .body()
        }
    }

    override suspend fun createApiKey(projectId: String) {
        return httpClient.use {
            post("api/v1/project/$projectId/apiKey")
                .body()
        }
    }

    override suspend fun getApiKey(projectId: String): String? {
        return httpClient.use {
            try {
                get("api/v1/project/$projectId/apiKey")
                    .body<ApiKey>()
                    .key
            } catch (e: ClientRequestException) {
                if (e.response.status == HttpStatusCode.NotFound) {
                    null
                } else {
                    throw e
                }
            }
        }
    }

    override suspend fun removeApiKey(projectId: String) {
        return httpClient.use {
            delete("api/v1/project/$projectId/apiKey")
                .body()
        }
    }

    override suspend fun getMembers(projectId: String): List<ProjectMember> {
        return httpClient.use {
            get("api/v1/project/$projectId/member")
                .body()
        }
    }

    override suspend fun removeMember(
        projectId: String,
        userId: String,
    ): List<ProjectMember> {
        return httpClient.use {
            delete("api/v1/project/$projectId/member/$userId")
                .body()
        }
    }

    override suspend fun modifyMemberRole(
        projectId: String,
        userId: String,
        modification: MemberModification,
    ): List<ProjectMember> {
        return httpClient.use {
            post("api/v1/project/$projectId/member/$userId") {
                setBody(modification)
            }.body()
        }
    }

    override suspend fun getInvites(projectId: String): List<Invite> {
        return httpClient.use {
            get("api/v1/project/$projectId/invite")
                .body()
        }
    }

    override suspend fun removeInvite(
        inviteId: String,
        projectId: String,
    ) {
        return httpClient.use {
            delete("api/v1/project/$projectId/invite/$inviteId")
                .body()
        }
    }

    override suspend fun inviteMember(inviteCreation: InviteCreation) {
        return httpClient.use {
            post("api/v1/invite") {
                setBody(inviteCreation)
            }.body()
        }
    }

    override suspend fun createOneTimeExport(
        projectId: String,
        format: BatchFormat,
        languageId: String,
    ): OneTimeExport {
        return httpClient.use {
            post("api/v1/project/$projectId/oneTimeExport") {
                parameter("format", format.name.lowercase())
                parameter("languageId", languageId)
            }.body()
        }
    }
}
