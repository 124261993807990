package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.projects

import cz.cvut.fit.horanvoj.ribbon.model.invite.Invite
import cz.cvut.fit.horanvoj.ribbon.model.project.Project
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase.GetProjectsUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.invoke
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.domain.usecase.AcceptInviteUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.domain.usecase.DeclineInviteUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.domain.usecase.GetInvitesUseCase
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.ViewModel

internal class ProjectsViewModel(
    private val getProjects: GetProjectsUseCase,
    private val getInvites: GetInvitesUseCase,
    private val acceptInvite: AcceptInviteUseCase,
    private val declineInvite: DeclineInviteUseCase,
) : ViewModel<ProjectsViewModel.State>(State()) {
    fun onAppear() {
        launch { fetchProjects() }
        launch { fetchInvites() }
    }

    private suspend fun fetchProjects() {
        update { copy(projectsLoading = true) }
        getProjects()
            .onSuccess { projects ->
                update {
                    copy(
                        projectsLoading = false,
                        error = null,
                        projects = projects,
                    )
                }
            }
            .onFailure { error ->
                update {
                    copy(
                        error = error,
                        projectsLoading = false,
                    )
                }
            }
    }

    private suspend fun fetchInvites() {
        update { copy(invitesLoading = true) }
        getInvites()
            .onSuccess {
                update { copy(invites = it, invitesLoading = false) }
            }
            .onFailure {
                update { copy(error = it, invitesLoading = false) }
            }
    }

    fun onCreateProject() {
        update { copy(creatingProject = true) }
    }

    fun onProjectCreated() {
        update { copy(creatingProject = false) }
        launch { fetchProjects() }
    }

    fun onProjectCreationCancelled() {
        update { copy(creatingProject = false) }
    }

    fun onInviteAcceptClick(invite: Invite) {
        launch {
            update { copy(inviteLoading = true) }
            acceptInvite(
                AcceptInviteUseCase.Params(
                    inviteId = invite.inviteId,
                ),
            ).onSuccess {
                update { copy(inviteLoading = false) }
                fetchInvites()
                fetchProjects()
            }.onFailure {
                update { copy(error = it, inviteLoading = false) }
            }
        }
    }

    fun onInviteDeclineClick(invite: Invite) {
        launch {
            update { copy(inviteLoading = true) }
            declineInvite(
                DeclineInviteUseCase.Params(
                    inviteId = invite.inviteId,
                ),
            ).onSuccess {
                update { copy(inviteLoading = false) }
                fetchInvites()
            }.onFailure {
                update { copy(error = it, inviteLoading = false) }
            }
        }
    }

    data class State(
        val inviteLoading: Boolean = false,
        val projectsLoading: Boolean = true,
        val invitesLoading: Boolean = true,
        val projects: List<Project> = emptyList(),
        val invites: List<Invite> = emptyList(),
        val creatingProject: Boolean = false,
        val error: Throwable? = null,
    ) {
        val loading = projectsLoading || invitesLoading || inviteLoading
        val empty =
            !projectsLoading &&
                projects.isEmpty() &&
                invites.isEmpty() &&
                !invitesLoading
    }
}
