package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth

import com.russhwolf.settings.Settings
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.data.repository.AuthRepositoryImpl
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.data.source.AuthSource
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.repository.AuthRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.usecase.*
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.infrastructure.api.AuthApi
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.infrastructure.api.AuthApiImpl
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.infrastructure.source.FirebaseAuthSource
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.usecase.ModifyLoggedInUseUseCase
import org.koin.core.module.dsl.factoryOf
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.bind
import org.koin.dsl.module

val featureAuthModule =
    module {
        singleOf(::Settings)

        singleOf(::AuthApiImpl) bind AuthApi::class
        singleOf(::FirebaseAuthSource) bind AuthSource::class
        singleOf(::AuthRepositoryImpl) bind AuthRepository::class

        factoryOf(::GetAuthTokenUseCaseImpl) bind GetAuthTokenUseCase::class
        factoryOf(::LoginUseCaseImpl) bind LoginUseCase::class
        factoryOf(::GetLoggedInUserUseCaseImpl) bind GetLoggedInUserUseCase::class
        factoryOf(::LogoutUseCaseImpl) bind LogoutUseCase::class
        factoryOf(::GetIsLoggedInUseCaseImpl) bind GetIsLoggedInUseCase::class
        factoryOf(::RegisterUseCaseImpl) bind RegisterUseCase::class
        factoryOf(::ResetPasswordUseCaseImpl) bind ResetPasswordUseCase::class
        factoryOf(::ModifyLoggedInUseUseCaseImpl) bind ModifyLoggedInUseUseCase::class
    }
