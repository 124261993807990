package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.members

import Res
import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.silk.components.text.SpanText
import cz.cvut.fit.horanvoj.ribbon.model.project.MemberRole
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.usecase.ValidateEmailUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.invoke
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.*
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.domain.displayName
import org.jetbrains.compose.web.dom.Text
import org.koin.compose.koinInject

@Composable
fun InviteMemberDialog(
    onInvite: (String, MemberRole) -> Unit,
    onCancelled: () -> Unit,
) {
    val validateEmail = koinInject<ValidateEmailUseCase>()
    var email by remember { mutableStateOf("") }
    var role: MemberRole? by remember { mutableStateOf(null) }
    var emailValid by remember { mutableStateOf(true) }
    val isInviteButtonEnabled = email.isNotBlank() && role != null && emailValid

    LaunchedEffect(email) {
        if (email.isNotEmpty()) {
            emailValid =
                validateEmail(
                    ValidateEmailUseCase.Params(
                        email = email,
                    ),
                ).getOrElse { false }
        }
    }

    MaterialDialog(
        headline = {
            Text(Res.string.title_invite)
        },
        onDismiss = onCancelled,
        buttons = {
            MaterialTextButton(
                onClick = {
                    onCancelled()
                },
            ) {
                Text(Res.string.button_cancel)
            }
            MaterialTextButton(
                enabled = isInviteButtonEnabled,
                onClick = {
                    role?.let { memberRole ->
                        onInvite(email, memberRole)
                    }
                },
            ) {
                Text(Res.string.button_invite_member)
            }
        },
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .gap(SpaceMedium),
        ) {
            SpanText(
                text = Res.string.label_invite_member,
                modifier = Modifier
                    .typography(BodyMedium),
            )

            MaterialTextInput(
                text = email,
                onTextChanged = {
                    email = it
                },
                valid = emailValid,
                placeholder = Res.string.label_email,
                modifier = Modifier
                    .fillMaxWidth(),
            )

            SpanText(
                text = Res.string.label_invite_member_role,
                modifier = Modifier
                    .typography(BodyMedium),
            )

            RadioButtonItemGroup {
                MemberRole.entries.forEach { thisRole ->
                    RadioButtonItem(
                        isChecked = thisRole == role,
                        onSelect = {
                            role = thisRole
                        },
                    ) {
                        Text(thisRole.displayName)
                    }
                }
            }
        }
    }
}
