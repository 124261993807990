package cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.toModifier
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.CornerSizeLarge
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.toSitePalette
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

val ExtendedFloatingActionButtonStyle by ComponentStyle {
    base {
        val style = colorMode.toSitePalette()
        Modifier
            .position(Position.Fixed)
            .right(0.px)
            .bottom(0.px)
            .cursor(Cursor.Pointer)
            .backgroundColor(style.primaryContainer)
            .color(style.onPrimaryContainer)
            .borderRadius(CornerSizeLarge)
            .height(56.px)
            .margin(
                right = 16.px,
                bottom = 16.px,
            )
            .padding(left = SpaceMedium, right = SpaceMedium)
            .minWidth(80.px)
            .zIndex(99)
            .materialShadow(MaterialShadowLevel.LEVEL_THREE)
            .transition(ElevationTransition)
    }

    hover {
        Modifier
            .materialShadow(MaterialShadowLevel.LEVEL_FOUR)
    }
}

@Composable
fun ExtendedFloatingActionButton(
    text: String,
    onClick: () -> Unit,
    icon: @Composable () -> Unit,
) {
    Row(
        modifier =
            ExtendedFloatingActionButtonStyle
                .toModifier()
                .typography(LabelLarge)
                .onClick { onClick() },
        verticalAlignment = Alignment.CenterVertically,
    ) {
        Box(
            Modifier
                .size(24.px)
                .margin(right = 12.px)
                .fontSize(24.px),
            contentAlignment = Alignment.Center,
        ) { icon() }

        Span(
            attrs =
                Modifier
                    .singleLine()
                    .toAttrs(),
        ) {
            Text(text)
        }
    }
}
