package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.translation.data.repository

import cz.cvut.fit.horanvoj.ribbon.model.term.Term
import cz.cvut.fit.horanvoj.ribbon.model.translation.TranslationUpdate
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.translation.data.source.TranslationSource
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.translation.domain.repository.TranslationRepository

internal class TranslationRepositoryImpl(
    private val translationSource: TranslationSource,
) : TranslationRepository {
    override suspend fun setTranslation(
        projectId: String,
        termId: String,
        translation: TranslationUpdate,
    ): Term {
        return translationSource.setTranslation(projectId, termId, translation)
    }

    override suspend fun deleteTranslation(
        projectId: String,
        termId: String,
        languageId: String,
    ): Term {
        return translationSource.deleteTranslation(projectId, termId, languageId)
    }
}
