package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.model.common.Order
import cz.cvut.fit.horanvoj.ribbon.model.common.Paging
import cz.cvut.fit.horanvoj.ribbon.model.term.Term
import cz.cvut.fit.horanvoj.ribbon.model.term.TermFilter
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term.domain.repository.TermRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term.domain.usecase.QueryTermsUseCase.Params
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase

interface QueryTermsUseCase : UseCase<Params, Paging<Term>> {
    data class Params(
        val projectId: String,
        val query: String,
        val includeLanguageIds: List<String>,
        val size: Int = DEFAULT_SIZE,
        val cursor: String?,
        val filter: TermFilter? = null,
        val filterLanguage: String? = null,
        val order: Order? = null,
    )

    companion object {
        const val DEFAULT_SIZE = 10
    }
}

internal class QueryTermsUseCaseImpl(
    private val termRepository: TermRepository,
) : QueryTermsUseCase {
    override suspend fun execute(params: Params): Paging<Term> {
        return termRepository.queryTerms(
            projectId = params.projectId,
            query = params.query,
            size = params.size,
            cursor = params.cursor,
            includeLanguageIds = params.includeLanguageIds,
            filter = params.filter,
            filterLanguage = params.filterLanguage,
            order = params.order,
        )
    }
}
