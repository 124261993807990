package cz.cvut.fit.horanvoj.ribbon.unwinder.web.infrastructure

import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.FirebaseApp
import dev.gitlive.firebase.FirebaseOptions
import dev.gitlive.firebase.auth.FirebaseAuthException
import dev.gitlive.firebase.auth.FirebaseAuthInvalidCredentialsException
import dev.gitlive.firebase.initialize

internal fun configureFirebase(): FirebaseApp {
    val options =
        FirebaseOptions(
            apiKey = "AIzaSyDdA4IsGh6Qt5yJsASv5kPmNMAJjj9g5oc",
            authDomain = "ribbon-90ac7.firebaseapp.com",
            projectId = "ribbon-90ac7",
            storageBucket = "ribbon-90ac7.appspot.com",
            gcmSenderId = "1027996612193",
            applicationId = "1:1027996612193:web:18333d62b2bfd4c5ca1c20",
            gaTrackingId = "G-G344M4W2LE",
        )

    return Firebase.initialize(
        context = null,
        options = options,
    )
}

fun FirebaseAuthException.mapToException(): Exception {
    val message =
        when (this) {
            is FirebaseAuthInvalidCredentialsException -> Res.string.error_invalid_credentials
            else -> {
                val message = this.message
                when {
                    message == null -> null
                    message.contains("invalid-login-credentials") -> Res.string.error_invalid_credentials
                    message.contains("too-many-requests") -> Res.string.error_too_many_requests
                    else -> Res.string.error_login_failed
                }
            }
        }

    return message?.let { Exception(it) } ?: this
}
