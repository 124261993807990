package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.login

import Res
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.usecase.GetIsLoggedInUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.usecase.LoginUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.exception.AuthException
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.model.AuthRequest
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.usecase.ValidateEmailUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.invoke
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.ViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.infrastructure.mapToException
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.login.LoginViewModel.State
import dev.gitlive.firebase.auth.FirebaseAuthException

internal class LoginViewModel(
    private val loginUseCase: LoginUseCase,
    private val isLoggedIn: GetIsLoggedInUseCase,
    private val validateEmail: ValidateEmailUseCase,
) : ViewModel<State>(State()) {
    fun onAppear() {
        launch {
            if (isLoggedIn().getOrElse { false }) {
                update { copy(isLoggedIn = true) }
            }
        }
    }

    fun login() {
        launch {
            update { copy(loading = true) }
            loginUseCase(
                LoginUseCase.Params(
                    request =
                        AuthRequest.Credentials(
                            email = state.email,
                            password = state.password,
                        ),
                ),
            ).onFailure { throwable ->
                val error =
                    when (throwable) {
                        is AuthException.NotVerified -> Exception(Res.string.error_not_verified)
                        is FirebaseAuthException -> throwable.mapToException()
                        else -> throwable
                    }

                update { copy(error = error, loading = false) }
            }.onSuccess {
                update { copy(isLoggedIn = true, loading = false) }
            }
        }
    }

    fun onEmailChange(email: String) {
        update { copy(email = email) }
        validateEmail(email)
    }

    private fun validateEmail(email: String) {
        launch {
            val isValid =
                validateEmail(ValidateEmailUseCase.Params(email))
                    .getOrElse { false }
            update { copy(isEmailValid = isValid) }
        }
    }

    fun onPasswordChange(password: String) {
        update {
            copy(
                password = password,
                isPasswordValid = password.isNotBlank(),
            )
        }
    }

    data class State(
        val email: String = "",
        val password: String = "",
        val isEmailValid: Boolean = true,
        val isPasswordValid: Boolean = true,
        val isLoggedIn: Boolean = false,
        val loading: Boolean = false,
        val error: Throwable? = null,
    ) {
        val isLoginButtonEnabled =
            isEmailValid && isPasswordValid &&
                email.isNotBlank() && password.isNotBlank()
    }
}
