package cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts

import Res
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.toModifier
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.HorizontalProgressBar
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.MaterialHorizontalDivider
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.fadeInAnimation
import kotlinx.browser.document
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vw

val PageContentStyle by ComponentStyle {
    base {
        Modifier
            .fillMaxSize()
            .margin(bottom = 72.px)
    }
}

enum class ProjectTab {
    TRANSLATE,
    MANAGE,
    TERMS,
}

@Composable
fun PageLayout(
    title: String,
    loading: Boolean = false,
    retainContentOnLoading: Boolean = false,
    activeTab: ProjectTab? = null,
    content: @Composable ColumnScope.() -> Unit,
) {
    LaunchedEffect(title) {
        document.title = Res.string.app_name + if (title.isNotBlank()) " - $title" else ""
    }

    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
        modifier = Modifier
            .fillMaxSize()
            .minHeight(100.percent),
    ) {
        NavHeader(
            title = title.ifBlank { null },
            activeTab = activeTab,
        )

        if (loading) {
            HorizontalProgressBar(
                modifier = Modifier
                    .width(99.vw)
                    .fadeInAnimation(),
            )
        } else {
            MaterialHorizontalDivider(
                modifier = Modifier
                    .margin(bottom = 3.px) // Don't jump content
                    .fadeInAnimation(),
            )
        }

        if (!loading || retainContentOnLoading) {
            Column(
                modifier =
                    PageContentStyle.toModifier()
                        .fadeInAnimation(),
                horizontalAlignment = Alignment.CenterHorizontally,
            ) {
                content()
            }
        }
    }
}
