package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.model.language.Language
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.domain.repository.LanguageRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.domain.usecase.GetProjectLanguagesUseCase.Params
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase

interface GetProjectLanguagesUseCase : UseCase<Params, List<Language>> {
    data class Params(
        val projectId: String,
    )
}

internal class GetProjectLanguagesUseCaseImpl(
    private val languageRepository: LanguageRepository,
) : GetProjectLanguagesUseCase {
    override suspend fun execute(params: Params): List<Language> {
        return languageRepository.getProjectLanguages(
            projectId = params.projectId,
        )
    }
}
