package cz.cvut.fit.horanvoj.ribbon.model.translation

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class Translation(
    val languageId: String,
    val text: String,
    val updated: Instant,
    val authorEmail: String?,
    val authorId: String?,
)
