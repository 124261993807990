package cz.cvut.fit.horanvoj.ribbon.model.invite

import cz.cvut.fit.horanvoj.ribbon.model.project.MemberRole
import kotlinx.serialization.Serializable

@Serializable
data class InviteCreation(
    val projectId: String,
    val userEmail: String,
    val role: MemberRole,
)
