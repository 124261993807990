package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.login

import Res
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.minWidth
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.icons.mdi.MdiAlternateEmail
import com.varabyte.kobweb.silk.components.text.SpanText
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.PageLayout
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.*
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.rememberViewModel
import org.jetbrains.compose.web.attributes.AutoComplete
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.dom.Text

@Page("/forgottenPassword")
@Composable
fun ForgottenPasswordPage() {
    val viewModel = rememberViewModel<ForgottenPasswordViewModel>()
    val snackbarState = rememberSnackbarState()

    LaunchedEffect(viewModel.state.hasResetPassword) {
        if (viewModel.state.hasResetPassword) {
            snackbarState.show(Res.string.label_password_reset_email_sent)
        }
    }

    Snackbar(
        state = snackbarState,
    )

    PageLayout(
        title = Res.string.title_forgotten_password,
    ) {
        Column(
            verticalArrangement = Arrangement.Center,
            modifier = Modifier
                .margin(SpaceMedium)
                .minWidth(35.percent)
                .gap(SpaceMedium),
        ) {
            SpanText(
                text = Res.string.title_forgotten_password,
                modifier = Modifier
                    .typography(TitleLarge),
            )

            MaterialTextInput(
                text = viewModel.state.email,
                onTextChanged = viewModel::onEmailChange,
                placeholder = Res.string.label_email,
                required = true,
                autoComplete = AutoComplete.email,
                icon = {
                    MdiAlternateEmail()
                },
                modifier = Modifier
                    .fillMaxWidth(),
                onCommit = {
                    viewModel.onResetPasswordClick()
                },
            )

            Row(
                modifier = Modifier
                    .fillMaxWidth(),
            ) {
                Spacer()
                MaterialFilledButton(
                    onClick = { viewModel.onResetPasswordClick() },
                ) {
                    Text(Res.string.button_reset_password)
                }
            }
        }
    }
}
