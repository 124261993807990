package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.export

import Res
import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.functions.max
import com.varabyte.kobweb.compose.dom.ref
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.mdi.MdiCheckCircle
import com.varabyte.kobweb.silk.components.icons.mdi.MdiHourglassTop
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import cz.cvut.fit.horanvoj.ribbon.model.batch.BatchFormat
import cz.cvut.fit.horanvoj.ribbon.model.project.RolePermission
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceBig
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceSmall
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceTiny
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.PageLayout
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.ProjectTab
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.RequireAuthenticationEffect
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.RequireProjectPermissionEffect
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.*
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.rememberViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.EmptyView
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.ErrorSnackbar
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.PrimaryListItem
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.domain.Icon
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.domain.displayName
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.fadeInAnimation
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.toSitePalette
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.*

@Page("/project/{projectId}/manage/export")
@Composable
internal fun ExportPage() {
    RequireAuthenticationEffect()
    RequireProjectPermissionEffect(
        RolePermission.IMPORT,
    )

    val style = ColorMode.current.toSitePalette()
    val ctx = rememberPageContext()
    val projectId = ctx.route.params.getValue("projectId")

    val viewModel = rememberViewModel<ExportViewModel>()

    LaunchedEffect(viewModel) {
        viewModel.onAppear(projectId)
    }

    ErrorSnackbar(viewModel.state.error)

    PageLayout(
        title = Res.string.title_export,
        activeTab = ProjectTab.MANAGE,
        loading = viewModel.state.loading,
    ) {
        Row(
            horizontalArrangement = Arrangement.Center,
            modifier = Modifier
                .fillMaxWidth()
                .padding(SpaceMedium)
                .gap(SpaceBig)
                .fillMaxWidth()
                .grid {
                    columns { repeat(autoFit) { minmax(max(300.px, 40.vw), 1.fr) } }
                },
        ) {
            Column(
                modifier = Modifier
                    .gap(SpaceMedium),
            ) {
                SpanText(
                    text = Res.string.label_export_info,
                    modifier = Modifier
                        .typography(TitleMedium),
                )

                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .gap(SpaceMedium),
                ) {
                    if (viewModel.state.languages.isEmpty()) {
                        EmptyView(
                            text = Res.string.label_languages_empty,
                        )
                    } else {
                        viewModel.state.languages.forEach { language ->
                            PrimaryListItem(
                                onClick = {
                                    viewModel.onLanguageSelected(
                                        languageId = language.languageId,
                                    )
                                },
                                isSelected = viewModel.state.selectedLanguage == language.languageId,
                                isSelectable = true,
                            ) {
                                Text(language.displayName)
                            }
                        }
                    }
                }
            }

            Column(
                modifier = Modifier
                    .gap(SpaceMedium),
            ) {
                SpanText(
                    text = Res.string.label_export_format_info,
                    modifier = Modifier
                        .typography(TitleMedium),
                )

                BatchFormat.entries.forEach { format ->
                    PrimaryListItem(
                        onClick = {
                            viewModel.onFormatSelected(format)
                        },
                        isSelected = format == viewModel.state.selectedFormat,
                        isSelectable = true,
                    ) {
                        Row(
                            modifier = Modifier
                                .gap(SpaceSmall),
                            verticalAlignment = Alignment.CenterVertically,
                        ) {
                            format.Icon()
                            Text(format.displayName)
                        }
                    }
                }

                Row(
                    horizontalArrangement = Arrangement.End,
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(bottom = SpaceMedium),
                ) {
                    MaterialFilledButton(
                        enabled = viewModel.state.isExportEnabled,
                        onClick = {
                            viewModel.onExportClick()
                        },
                    ) {
                        Text(Res.string.button_export)
                    }
                }

                if (viewModel.state.exporting) {
                    OutlinedCard(
                        modifier = Modifier
                            .fillMaxWidth()
                            .fadeInAnimation()
                            .typography(BodyLarge),
                        icon = {
                            MdiHourglassTop(
                                modifier = Modifier
                                    .fontSize(24.px),
                            )
                        },
                        title = {
                            Text(Res.string.title_exporting)
                        },
                        ref = ref {
                            it.scrollIntoView(
                                arg = ScrollIntoViewOptions(
                                    behavior = ScrollBehavior.SMOOTH,
                                    block = ScrollLogicalPosition.NEAREST,
                                ),
                            )
                        },
                    ) {
                        Column(
                            modifier = Modifier
                                .fillMaxWidth(),
                        ) {
                            SpanText(
                                Res.string.label_exporting,
                                modifier = Modifier
                                    .padding(bottom = SpaceMedium),
                            )

                            HorizontalProgressBar(
                                modifier = Modifier
                                    .fillMaxWidth(),
                            )
                        }
                    }
                }

                val exportUrl = viewModel.state.exportUrl
                if (exportUrl != null) {
                    OutlinedCard(
                        modifier = Modifier
                            .setVariable(OutlinedCardColor, style.onSuccessContainer)
                            .setVariable(OutlinedCardBackground, style.successContainer)
                            .fillMaxWidth()
                            .fadeInAnimation()
                            .typography(BodyLarge),
                        title = {
                            Text(Res.string.title_export_successful)
                        },
                        icon = {
                            MdiCheckCircle(
                                modifier = Modifier
                                    .fontSize(24.px)
                                    .color(style.onSuccessContainer),
                            )
                        },
                        ref = ref {
                            it.scrollIntoView(
                                arg = ScrollIntoViewOptions(
                                    behavior = ScrollBehavior.SMOOTH,
                                    block = ScrollLogicalPosition.NEAREST,
                                ),
                            )
                        },
                    ) {
                        Column(
                            modifier = Modifier
                                .fillMaxWidth(),
                        ) {
                            SpanText(
                                Res.string.label_export_generated,
                                modifier = Modifier
                                    .padding(bottom = SpaceTiny),
                            )

                            var linkRef: HTMLElement? by remember { mutableStateOf(null) }
                            Link(
                                path = exportUrl,
                                ref = ref {
                                    linkRef = it
                                },
                                modifier = Modifier.attrsModifier {
                                    attr("download", exportUrl)
                                    attr("target", "_blank")
                                }.styleModifier {
                                    property("display", "none")
                                },
                            )

                            Row(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .justifyContent(JustifyContent.End),
                            ) {
                                MaterialTextButton(
                                    onClick = {
                                        linkRef?.let {
                                            it.click()
                                            viewModel.onExportDownloaded()
                                        }
                                    },
                                ) {
                                    Text(Res.string.button_download)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
