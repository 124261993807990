package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage

import cz.cvut.fit.horanvoj.ribbon.model.project.ProjectMember
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase.GetProjectMembersUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.invoke
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.ViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.MembersCardViewModel.State

internal class MembersCardViewModel(
    private val getProjectMembers: GetProjectMembersUseCase,
) : ViewModel<State>(State()) {
    fun onAppear(projectId: String) {
        update { copy(projectId = projectId) }
        launch { fetchMembers() }
    }

    private suspend fun fetchMembers() {
        val projectId = state.projectId ?: return
        update { copy(loading = true) }
        getProjectMembers(
            GetProjectMembersUseCase.Params(
                projectId = projectId,
            ),
        ).onSuccess {
            update { copy(members = it.take(10), loading = false) }
        }.onFailure {
            update { copy(error = it, loading = false) }
        }
    }

    data class State(
        val projectId: String? = null,
        val members: List<ProjectMember> = emptyList(),
        val loading: Boolean = false,
        val error: Throwable? = null,
    )
}
