package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.import

import Res
import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.functions.max
import com.varabyte.kobweb.compose.dom.ref
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.mdi.*
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import cz.cvut.fit.horanvoj.ribbon.model.batch.BatchFormat
import cz.cvut.fit.horanvoj.ribbon.model.project.RolePermission
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceBig
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceSmall
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceTiny
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.PageLayout
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.ProjectTab
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.RequireAuthenticationEffect
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.RequireProjectPermissionEffect
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.*
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.rememberViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.EmptyView
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.ErrorSnackbar
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.PrimaryListItem
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.domain.Icon
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.domain.displayName
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.fadeInAnimation
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.toSitePalette
import org.jetbrains.compose.web.attributes.accept
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.FileInput
import org.jetbrains.compose.web.dom.Li
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.Ul
import org.w3c.dom.*
import org.w3c.files.File
import org.w3c.files.get

@Page("/project/{projectId}/manage/import")
@Composable
fun ImportPage() {
    RequireAuthenticationEffect()
    RequireProjectPermissionEffect(
        RolePermission.IMPORT,
    )

    val style = ColorMode.current.toSitePalette()
    val ctx = rememberPageContext()
    val projectId = ctx.route.params.getValue("projectId")

    val viewModel = rememberViewModel<ImportViewModel>()

    LaunchedEffect(viewModel) {
        viewModel.onAppear(projectId)
    }

    ErrorSnackbar(viewModel.state.error)

    PageLayout(
        title = Res.string.title_import,
        activeTab = ProjectTab.MANAGE,
        loading = viewModel.state.loading,
    ) {
        Row(
            horizontalArrangement = Arrangement.Center,
            modifier = Modifier
                .fillMaxWidth()
                .padding(SpaceMedium)
                .gap(SpaceBig)
                .fillMaxWidth()
                .grid {
                    columns { repeat(autoFit) { minmax(max(300.px, 40.vw), 1.fr) } }
                },
        ) {
            Column(
                modifier = Modifier
                    .gap(SpaceMedium),
            ) {
                SpanText(
                    text = Res.string.label_import_info,
                    modifier = Modifier
                        .typography(TitleMedium),
                )

                FileSelection(
                    accept = viewModel.state.acceptTypes,
                    onFileSelected = viewModel::onFileSelected,
                    selectedFile = viewModel.state.importFile,
                    modifier = Modifier
                        .height(35.vmin)
                        .fillMaxWidth(),
                )

                if (!viewModel.state.isFormatKnown) {
                    OutlinedCard(
                        modifier = Modifier
                            .setVariable(OutlinedCardColor, style.onErrorContainer)
                            .setVariable(OutlinedCardBackground, style.errorContainer)
                            .fillMaxWidth()
                            .fadeInAnimation(),
                    ) {
                        SpanText(Res.string.label_format_unknown)
                    }
                }

                SpanText(
                    text = Res.string.label_import_languages_info,
                    modifier = Modifier
                        .typography(TitleMedium),
                )

                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .gap(SpaceMedium),
                ) {
                    if (viewModel.state.languages.isEmpty()) {
                        EmptyView(
                            text = Res.string.label_languages_empty,
                        )
                    } else {
                        viewModel.state.languages.forEach { language ->
                            PrimaryListItem(
                                onClick = {
                                    viewModel.onLanguageSelected(
                                        languageId = language.languageId,
                                    )
                                },
                                isSelected = viewModel.state.selectedLanguage == language.languageId,
                                isSelectable = true,
                            ) {
                                Text(language.displayName)
                            }
                        }
                    }
                }
            }

            Column(
                modifier = Modifier
                    .gap(SpaceMedium),
            ) {
                SpanText(
                    text = Res.string.label_import_format_info,
                    modifier = Modifier
                        .typography(TitleMedium),
                )

                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .gap(SpaceMedium),
                ) {
                    BatchFormat.entries.forEach { format ->
                        PrimaryListItem(
                            onClick = {
                                viewModel.onFormatSelected(format)
                            },
                            isSelected = format == viewModel.state.selectedFormat,
                            isSelectable = true,
                        ) {
                            Row(
                                modifier = Modifier
                                    .gap(SpaceSmall),
                                verticalAlignment = Alignment.CenterVertically,
                            ) {
                                format.Icon()
                                Text(format.displayName)
                            }
                        }
                    }
                }

                SpanText(
                    text = Res.string.label_import_override_info,
                    modifier = Modifier
                        .typography(TitleMedium),
                )

                CheckboxItem(
                    checked = viewModel.state.overrideExisting,
                    onCheckedChange = viewModel::onOverrideExistingChanged,
                ) {
                    Text(Res.string.label_override_existing)
                }

                Row(
                    horizontalArrangement = Arrangement.End,
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(bottom = SpaceMedium),
                ) {
                    MaterialFilledButton(
                        enabled = viewModel.state.isImportEnabled,
                        onClick = {
                            viewModel.onImportClicked()
                        },
                    ) {
                        Text(Res.string.button_import)
                    }
                }

                if (viewModel.state.importing) {
                    OutlinedCard(
                        modifier = Modifier
                            .fillMaxWidth()
                            .fadeInAnimation()
                            .typography(BodyLarge),
                        icon = {
                            MdiHourglassTop(
                                modifier = Modifier
                                    .fontSize(24.px),
                            )
                        },
                        title = {
                            Text(Res.string.title_importing)
                        },
                        ref = ref {
                            it.scrollIntoView(
                                arg = ScrollIntoViewOptions(
                                    behavior = ScrollBehavior.SMOOTH,
                                    block = ScrollLogicalPosition.NEAREST,
                                ),
                            )
                        },
                    ) {
                        Column(
                            modifier = Modifier
                                .fillMaxWidth(),
                        ) {
                            SpanText(
                                Res.string.label_importing,
                                modifier = Modifier
                                    .padding(bottom = SpaceMedium),
                            )

                            HorizontalProgressBar(
                                modifier = Modifier
                                    .fillMaxWidth(),
                            )
                        }
                    }
                }

                val importError = viewModel.state.importError
                if (importError != null) {
                    OutlinedCard(
                        modifier = Modifier
                            .setVariable(OutlinedCardColor, style.onErrorContainer)
                            .setVariable(OutlinedCardBackground, style.errorContainer)
                            .fillMaxWidth()
                            .fadeInAnimation()
                            .typography(BodyLarge),
                        title = {
                            Text(Res.string.title_error)
                        },
                        icon = {
                            MdiError(
                                modifier = Modifier
                                    .color(style.onErrorContainer)
                                    .fontSize(24.px),
                            )
                        },
                        ref = ref {
                            it.scrollIntoView(
                                arg = ScrollIntoViewOptions(
                                    behavior = ScrollBehavior.SMOOTH,
                                    block = ScrollLogicalPosition.NEAREST,
                                ),
                            )
                        },
                    ) {
                        SpanText(
                            importError.message ?: Res.string.error_unknown,
                        )
                    }
                }

                val importResult = viewModel.state.importResult
                if (importResult != null) {
                    OutlinedCard(
                        modifier = Modifier
                            .setVariable(OutlinedCardColor, style.onSuccessContainer)
                            .setVariable(OutlinedCardBackground, style.successContainer)
                            .fillMaxWidth()
                            .fadeInAnimation()
                            .typography(BodyLarge),
                        title = {
                            Text(Res.string.title_import_successful)
                        },
                        icon = {
                            MdiCheckCircle(
                                modifier = Modifier
                                    .fontSize(24.px)
                                    .color(style.onSuccessContainer),
                            )
                        },
                        ref = ref {
                            it.scrollIntoView(
                                arg = ScrollIntoViewOptions(
                                    behavior = ScrollBehavior.SMOOTH,
                                    block = ScrollLogicalPosition.NEAREST,
                                ),
                            )
                        },
                    ) {
                        Column(
                            modifier = Modifier
                                .fillMaxWidth(),
                        ) {
                            SpanText(
                                Res.string.label_import_count.format(
                                    count = importResult.totalImportedCount.toString(),
                                ),
                                modifier = Modifier
                                    .padding(bottom = SpaceTiny),
                            )

                            if (importResult.skipped.isEmpty()) {
                                SpanText(Res.string.label_import_nothing_skipped)
                            } else {
                                SpanText(
                                    Res.string.label_import_skipped.format(
                                        count = importResult.skipped.size.toString(),
                                    ),
                                )

                                Column(
                                    modifier = Modifier
                                        .gap(SpaceSmall)
                                        .padding(left = SpaceMedium),
                                ) {
                                    Ul {
                                        importResult.skipped.forEach { skipped ->
                                            Li {
                                                SpanText(skipped)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@Composable
private fun FileSelection(
    selectedFile: File?,
    accept: String,
    onFileSelected: (File) -> Unit,
    modifier: Modifier = Modifier,
) {
    var inputRef: HTMLInputElement? by remember { mutableStateOf(null) }
    var isEntered by remember { mutableStateOf(false) }

    OutlinedCard(
        onClick = { inputRef?.click() },
        modifier = modifier,
        ref =
            ref {
                it.ondragenter = { event ->
                    isEntered = true
                    event.stopPropagation()
                    event.preventDefault()
                }

                it.ondragleave = {
                    isEntered = false
                    Unit
                }

                it.ondragover = { event ->
                    event.stopPropagation()
                    event.preventDefault()
                }

                it.ondrop = { event ->
                    event.stopPropagation()
                    event.preventDefault()

                    isEntered = false
                    val file = event.dataTransfer?.files?.get(0)
                    if (file != null) {
                        onFileSelected(file)
                    }
                }
            },
    ) {
        Column(
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.CenterHorizontally,
            modifier = Modifier
                .gap(SpaceMedium)
                .fillMaxSize(),
        ) {
            if (isEntered) {
                MdiUploadFile(
                    modifier = Modifier
                        .fontSize(48.px),
                )
            } else if (selectedFile != null) {
                MdiFilePresent(
                    modifier = Modifier
                        .fontSize(48.px),
                )
            } else {
                MdiAttachFile(
                    modifier = Modifier
                        .fontSize(48.px),
                )
            }

            val text =
                if (isEntered) {
                    Res.string.label_drop_now
                } else {
                    selectedFile?.name ?: Res.string.label_select_file
                }

            SpanText(
                text = text,
                modifier = Modifier
                    .typography(BodyLarge)
                    .textAlign(TextAlign.Center),
            )
        }
    }

    FileInput {
        ref {
            inputRef = it
            onDispose {
                inputRef = null
            }
        }
        style { display(DisplayStyle.None) }
        accept(accept)
        onChange {
            val file = inputRef?.files?.get(0)
            if (file != null) {
                onFileSelected(file)
            }
        }
    }
}
