package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.repository.ProjectRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase.CreateApiKeyUseCase.Params
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase

interface CreateApiKeyUseCase : UseCase<Params, Unit> {
    data class Params(
        val projectId: String,
    )
}

internal class CreateApiKeyUseCaseImpl(
    private val projectRepository: ProjectRepository,
) : CreateApiKeyUseCase {
    override suspend fun execute(params: Params) {
        return projectRepository.createApiKey(
            projectId = params.projectId,
        )
    }
}
