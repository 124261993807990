package cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.StyleVariable
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.animation.Keyframes
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.CornerSizeExtraSmall
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.toSitePalette
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.keywords.auto
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds

internal enum class SnackbarAnimationState {
    SHOWN,
    CLOSING,
    CLOSED,
}

class SnackbarState(
    private val scope: CoroutineScope,
) {
    internal var state by mutableStateOf(SnackbarAnimationState.CLOSED)
    internal var text by mutableStateOf("")
    internal var duration by mutableStateOf(2.seconds)

    fun show(
        message: String,
        duration: Duration = 2.seconds,
    ) {
        this.duration = duration
        text = message
        state = SnackbarAnimationState.SHOWN
    }

    fun hide() {
        if (state != SnackbarAnimationState.SHOWN) {
            return
        }
        text = ""
        state = SnackbarAnimationState.CLOSING
    }

    fun onAnimationEnd() {
        if (state == SnackbarAnimationState.SHOWN) {
            scope.launch {
                delay(duration)
                state = SnackbarAnimationState.CLOSING
            }
        } else {
            state = SnackbarAnimationState.CLOSED
            text = ""
        }
    }
}

@Composable
fun rememberSnackbarState(): SnackbarState {
    val scope = rememberCoroutineScope()
    return remember(scope) { SnackbarState(scope) }
}

val SnackbarColor by StyleVariable<Color>()
val SnackbarBackgroundColor by StyleVariable<Color>()

val SnackbarStyle by ComponentStyle {
    base {
        Modifier
            .position(Position.Fixed)
            .bottom(5.cssRem)
            .left(50.percent)
            .zIndex(9999)
            .transform {
                translateX(tx = (-50).percent)
            }
            .height(48.px)
            .color(SnackbarColor.value())
            .backgroundColor(SnackbarBackgroundColor.value())
            .borderRadius(CornerSizeExtraSmall)
            .materialShadow(MaterialShadowLevel.LEVEL_THREE)
            .padding {
                left(SpaceMedium)
                right(SpaceMedium)
            }
            .width(95.vw)
            .maxWidth(95.vw)
    }

    Breakpoint.MD {
        Modifier
            .width(auto)
            .minWidth(60.vw)
    }
}

@Composable
fun Snackbar(
    state: SnackbarState,
    modifier: Modifier = Modifier,
    backgroundColor: Color = ColorMode.current.toSitePalette().inverseSurface,
    color: Color = ColorMode.current.toSitePalette().inverseOnSurface,
) {
    if (state.state != SnackbarAnimationState.CLOSED) {
        key(state.state) {
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier =
                    SnackbarStyle.toModifier()
                        .setVariable(SnackbarColor, color)
                        .setVariable(SnackbarBackgroundColor, backgroundColor)
                        .then(modifier)
                        .animation(
                            SnackbarAnimation.toAnimation(
                                duration = 200.ms,
                                timingFunction =
                                    if (state.state == SnackbarAnimationState.SHOWN) {
                                        AnimationTimingFunction.EaseOut
                                    } else {
                                        AnimationTimingFunction.EaseIn
                                    },
                                direction =
                                    if (state.state == SnackbarAnimationState.SHOWN) {
                                        AnimationDirection.Normal
                                    } else {
                                        AnimationDirection.Reverse
                                    },
                                fillMode = AnimationFillMode.Forwards,
                            ),
                        )
                        .onAnimationEnd {
                            state.onAnimationEnd()
                        },
            ) {
                SpanText(
                    text = state.text,
                    modifier = Modifier
                        .singleLine()
                        .typography(BodyMedium),
                )
            }
        }
    }
}

val SnackbarAnimation by Keyframes {
    from { Modifier.opacity(0f).height(0.px) }
    to { Modifier.opacity(1f) }
}
