package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.batch.infrastructure.source

import cz.cvut.fit.horanvoj.ribbon.model.batch.BatchFormat
import cz.cvut.fit.horanvoj.ribbon.model.batch.ImportResult
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.batch.data.source.BatchSource
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.batch.infrastructure.api.BatchApi

internal class BatchSourceImpl(
    private val batchApi: BatchApi,
) : BatchSource {
    override suspend fun import(
        projectId: String,
        languageId: String,
        format: BatchFormat,
        importData: String,
        overrideExisting: Boolean,
    ): ImportResult {
        return batchApi.import(
            projectId = projectId,
            languageId = languageId,
            format = format,
            importData = importData,
            overrideExisting = overrideExisting,
        )
    }
}
