package cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.AnimationIterationCount
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.animation.Keyframes
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.CornerSizeRound
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.toSitePalette
import org.jetbrains.compose.web.css.AnimationTimingFunction
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.s
import org.jetbrains.compose.web.dom.Div

@Composable
fun HorizontalProgressBar(modifier: Modifier = Modifier) {
    val style = ColorMode.current.toSitePalette()
    Div(
        attrs =
            modifier
                .height(4.px)
                .borderRadius(CornerSizeRound)
                .overflow(Overflow.Hidden)
                .backgroundColor(style.secondaryContainer)
                .toAttrs(),
    ) {
        Div(
            attrs =
                Modifier
                    .width(100.percent)
                    .height(100.percent)
                    .borderRadius(CornerSizeRound)
                    .backgroundColor(style.primary)
                    .animation(
                        ProgressAnimation.toAnimation(
                            duration = 1.s,
                            iterationCount = AnimationIterationCount.Infinite,
                            timingFunction = AnimationTimingFunction.EaseOut,
                        ),
                    )
                    .styleModifier { property("transform-origin", "0% 50%") }
                    .toAttrs(),
        )
    }
}

val ProgressAnimation by Keyframes {
    from {
        Modifier.transform {
            translateX(0.percent)
            scaleX(0)
        }
    }

    each(40.percent) {
        Modifier.transform {
            translateX(0.percent)
            scaleX(0.4)
        }
    }

    to {
        Modifier.transform {
            translateX(100.percent)
            scaleX(0.5)
        }
    }
}
