package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.members

import Res
import androidx.compose.runtime.Composable
import com.varabyte.kobweb.silk.components.icons.mdi.MdiDelete
import cz.cvut.fit.horanvoj.ribbon.model.invite.Invite
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.MaterialDialog
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.MaterialTextButton
import org.jetbrains.compose.web.dom.Text

@Composable
fun RemoveInviteDialog(
    invite: Invite,
    onConfirmed: () -> Unit,
    onCancelled: () -> Unit,
) {
    MaterialDialog(
        icon = {
            MdiDelete()
        },
        content = {
            Text(Res.string.label_remove_invite.format(email = invite.invitedUserEmail))
        },
        headline = {
            Text(Res.string.title_are_you_sure)
        },
        onDismiss = onCancelled,
        buttons = {
            MaterialTextButton(
                onClick = { onCancelled() },
            ) {
                Text(Res.string.button_cancel)
            }
            MaterialTextButton(
                onClick = { onConfirmed() },
            ) {
                Text(Res.string.button_confirm)
            }
        },
    )
}
