package cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.functions.blur
import com.varabyte.kobweb.compose.css.functions.dropShadow
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.animation.Keyframes
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceLarge
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.HeadlineMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.typography
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.toSitePalette
import org.jetbrains.compose.web.css.*

// Some fun with animations...
val EmptyImageKeyframes by Keyframes {
    val style = colorMode.toSitePalette()

    from {
        Modifier
            .transform {
                rotateY(0.deg)
            }
            .filter(
                blur(radius = 0.px),
                dropShadow(offsetX = 0.px, offsetY = 0.px, blurRadius = 4.px, color = style.primary),
            )
    }

    each(50.percent) {
        Modifier
            .transform {
                rotateY(180.deg)
            }
            .filter(blur(4.px))
    }

    to {
        Modifier
            .transform {
                rotateY(0.deg)
            }
            .filter(
                blur(0.px),
                dropShadow(offsetX = 0.px, offsetY = 0.px, blurRadius = 4.px, color = style.primary),
            )
    }
}

@Composable
fun EmptyView(
    text: String,
    withLogo: Boolean = false,
) {
    Box(
        modifier = Modifier
            .fillMaxSize()
            .padding(SpaceMedium),
        contentAlignment = Alignment.Center,
    ) {
        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            modifier = Modifier
                .gap(SpaceLarge),
        ) {
            if (withLogo) {
                Image(
                    src = "/ribbon-logo.webp",
                    modifier = Modifier
                        .size(30.vmin)
                        .borderRadius(50.percent)
                        .animation(
                            EmptyImageKeyframes.toAnimation(
                                duration = 5.s,
                                fillMode = AnimationFillMode.Forwards,
                            ),
                        ),
                )
            }

            SpanText(
                text = text,
                modifier = Modifier
                    .fillMaxWidth()
                    .textAlign(TextAlign.Center)
                    .typography(HeadlineMedium),
            )
        }
    }
}
