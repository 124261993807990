package cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.overlay.Overlay
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.base
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.EscapeKeyEffect
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.CornerSizeExtraLarge
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceBig
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.fadeInAnimation
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.toSitePalette
import org.jetbrains.compose.web.css.px

val MaterialDialogStyle by ComponentStyle.base {
    val style = colorMode.toSitePalette()

    Modifier
        .backgroundColor(style.secondaryContainer)
        .materialShadow(MaterialShadowLevel.LEVEL_THREE)
        .borderRadius(CornerSizeExtraLarge)
        .padding(SpaceBig)
        .overflow(Overflow.Clip)
        .minWidth(280.px)
        .maxWidth(MaterialDialogMaxWidth)
}

val MaterialDialogMaxWidth = 560.px

@Composable
fun MaterialDialog(
    modifier: Modifier = Modifier,
    maximize: Boolean = false,
    icon: (@Composable () -> Unit)? = null,
    onDismiss: () -> Unit,
    headline: @Composable () -> Unit,
    buttons: @Composable RowScope.() -> Unit,
    content: @Composable () -> Unit,
) {
    val style = ColorMode.current.toSitePalette()

    EscapeKeyEffect(onDismiss)

    Overlay(
        contentAlignment = Alignment.Center,
        modifier = modifier
            .zIndex(999)
            .fadeInAnimation(),
    ) {
        Column(
            modifier =
                MaterialDialogStyle
                    .toModifier()
                    .thenIf(maximize, Modifier.fillMaxWidth()),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            if (icon != null) {
                Box(
                    modifier = Modifier
                        .fontSize(24.px)
                        .size(24.px)
                        .color(style.secondary)
                        .margin(bottom = SpaceMedium),
                    contentAlignment = Alignment.Center,
                ) {
                    icon()
                }
            }

            Box(
                modifier = Modifier
                    .margin(bottom = SpaceMedium)
                    .typography(HeadlineSmall)
                    .color(style.onSurface),
            ) {
                headline()
            }

            Box(
                modifier = Modifier
                    .margin(bottom = SpaceBig)
                    .typography(BodyMedium)
                    .color(style.onSurfaceVariant)
                    .fillMaxWidth(),
            ) {
                content()
            }

            Row(
                horizontalArrangement = Arrangement.End,
                modifier = Modifier
                    .gap(8.px)
                    .fillMaxWidth(),
            ) {
                buttons()
            }
        }
    }
}
