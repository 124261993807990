package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.terms

import cz.cvut.fit.horanvoj.ribbon.model.common.Order
import cz.cvut.fit.horanvoj.ribbon.model.common.Paging
import cz.cvut.fit.horanvoj.ribbon.model.language.Language
import cz.cvut.fit.horanvoj.ribbon.model.term.Term
import cz.cvut.fit.horanvoj.ribbon.model.term.TermCreation
import cz.cvut.fit.horanvoj.ribbon.model.term.TermFilter
import cz.cvut.fit.horanvoj.ribbon.model.term.TermModification
import cz.cvut.fit.horanvoj.ribbon.model.translation.Translation
import cz.cvut.fit.horanvoj.ribbon.model.translation.TranslationUpdate
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.domain.usecase.GetProjectLanguagesUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.invoke
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term.domain.usecase.*
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.translation.domain.usecase.DeleteTranslationUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.translation.domain.usecase.SetTranslationUseCase
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.ViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.terms.TermTranslationViewObject.NotTranslated
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.terms.TermsViewModel.State

internal class TermsViewModel(
    private val getTerms: GetTermsUseCase,
    private val queryTerms: QueryTermsUseCase,
    private val removeTerm: RemoveTermUseCase,
    private val updateTerm: UpdateTermUseCase,
    private val getTerm: GetTermUseCase,
    private val getProjectLanguages: GetProjectLanguagesUseCase,
    private val createTerm: CreateTermUseCase,
    private val setTranslation: SetTranslationUseCase,
    private val deleteTranslation: DeleteTranslationUseCase,
) : ViewModel<State>(State()) {
    fun onAppear(
        projectId: String,
        query: String?,
        cursor: String?,
        filter: TermFilter?,
        order: Order?,
    ) {
        update {
            copy(
                projectId = projectId,
                query = query,
                cursor = cursor.takeIf { !it.isNullOrEmpty() },
                filter = filter ?: this.filter,
                order = order ?: this.order,
            )
        }

        if (state.supportedLanguages == null) {
            launch { fetchSupportedLanguages() }
        }

        launch { loadPage() }
    }

    private suspend fun loadPage(
        query: String? = state.query,
        cursor: String? = state.cursor,
    ) {
        val projectId = state.projectId ?: return
        update { copy(loading = true) }

        val terms =
            if (query.isNullOrEmpty()) {
                getTerms(
                    GetTermsUseCase.Params(
                        projectId = projectId,
                        cursor = cursor,
                        includeLanguageIds = emptyList(),
                        filterLanguage = state.filterLanguage?.languageId,
                        filter = state.filter,
                        order = state.order,
                    ),
                )
            } else {
                queryTerms(
                    QueryTermsUseCase.Params(
                        projectId = projectId,
                        cursor = cursor,
                        query = query,
                        includeLanguageIds = emptyList(),
                        filterLanguage = state.filterLanguage?.languageId,
                        filter = state.filter,
                        order = state.order,
                    ),
                )
            }.onFailure {
                update { copy(error = it, loading = false) }
            }.getOrNull() ?: return

        update {
            copy(
                loading = false,
                termsPaged = terms,
            )
        }
    }

    private suspend fun fetchSupportedLanguages() {
        val projectId = state.projectId ?: return

        getProjectLanguages(
            GetProjectLanguagesUseCase.Params(
                projectId = projectId,
            ),
        ).onSuccess {
            update { copy(supportedLanguages = it) }
        }.onFailure {
            update { copy(error = it) }
        }
    }

    fun onUpdateTermClick(term: Term) {
        update { copy(updatingTerm = term) }
    }

    fun onUpdateTermCancelled() {
        update { copy(updatingTerm = null) }
    }

    fun onUpdateTermConfirmed(termModification: TermModification) {
        val projectId = state.projectId ?: return
        val updatingTerm = state.updatingTerm ?: return
        update { copy(loadingTermId = updatingTerm.id, updatingTerm = null) }
        launch {
            updateTerm(
                UpdateTermUseCase.Params(
                    projectId = projectId,
                    termId = updatingTerm.id,
                    modification = termModification,
                ),
            ).onSuccess { term ->
                val redirectToCursor =
                    if (
                        updatingTerm.id != term.id &&
                        updatingTerm.id == state.cursor
                    ) {
                        // We modified the id of the first term
                        term.id
                    } else {
                        null
                    }

                update {
                    copy(
                        loadingTermId = null,
                        termsPaged = termsPaged?.update(term, updatingTerm.id),
                        redirectToCursor = redirectToCursor,
                    )
                }
            }.onFailure {
                update { copy(loadingTermId = null, error = it) }
            }
        }
    }

    private fun Paging<Term>.update(
        term: Term,
        termId: String = term.id,
    ): Paging<Term> {
        val newItems =
            items.map { oldTerm ->
                when {
                    oldTerm.id == termId -> term
                    else -> oldTerm
                }
            }

        return Paging(
            items = newItems,
            pagination = pagination,
        )
    }

    fun onRemoveTermClick(term: Term) {
        update { copy(removingTerm = term) }
    }

    fun onRemoveTermCancelled() {
        update { copy(removingTerm = null) }
    }

    fun onRemoveTermConfirmed() {
        val projectId = state.projectId ?: return
        val removingTerm = state.removingTerm ?: return
        update { copy(loadingTermId = removingTerm.id, removingTerm = null) }
        launch {
            removeTerm(
                RemoveTermUseCase.Params(
                    projectId = projectId,
                    termId = removingTerm.id,
                ),
            ).onSuccess {
                val redirectToCursor =
                    if (
                        removingTerm.id == state.cursor
                    ) {
                        // We removed the first term,
                        val nextTerm = state.termsPaged?.items?.getOrNull(1)
                        nextTerm?.id ?: (state.previousPage ?: "")
                    } else {
                        null
                    }

                update {
                    copy(
                        loadingTermId = null,
                        redirectToCursor = redirectToCursor,
                    )
                }

                if (redirectToCursor == null) {
                    loadPage()
                }
            }.onFailure {
                update { copy(loadingTermId = null, error = it) }
            }
        }
    }

    fun onCreateTermClicked() {
        update { copy(creatingTerm = true) }
    }

    fun onCreateTermCancelled() {
        update { copy(creatingTerm = false) }
    }

    fun onCreateTermConfirmed(creation: TermCreation) {
        update { copy(creatingTerm = false) }

        val projectId = state.projectId ?: return

        update { copy(loading = true) }
        launch {
            createTerm(
                CreateTermUseCase.Params(
                    projectId = projectId,
                    creation = creation,
                ),
            ).onSuccess {
                if (state.cursor == null) {
                    launch { loadPage() }
                } else {
                    update {
                        copy(
                            redirectToCursor = "",
                            loading = false,
                        )
                    }
                }
            }.onFailure {
                update {
                    copy(
                        loading = false,
                        error = it,
                    )
                }
            }
        }
    }

    fun onTranslateClicked(term: Term) {
        if (!state.translations.containsKey(term.id)) {
            launch { fetchTranslations(term.id) }
        }
    }

    private suspend fun fetchTranslations(termId: String) {
        val projectId = state.projectId ?: return

        val pagedTranslations = state.termsPaged?.items?.firstOrNull { it.id == termId }?.translations
        if (pagedTranslations != null) {
            updateTranslations(
                termId = termId,
                translations = pagedTranslations,
            )
        } else {
            update { copy(loadingTermId = termId) }

            getTerm(
                GetTermUseCase.Params(
                    projectId = projectId,
                    termId = termId,
                ),
            ).onSuccess { term ->
                term.translations?.let {
                    updateTranslations(
                        termId = term.id,
                        translations = it,
                    )
                }

                update { copy(loadingTermId = null) }
            }.onFailure {
                update { copy(error = it, loadingTermId = null) }
            }
        }
    }

    private suspend fun updateTranslations(
        termId: String,
        translations: List<Translation>,
    ) {
        val supportedLanguages =
            if (
                state.supportedLanguages == null
            ) {
                fetchSupportedLanguages()
                state.supportedLanguages
            } else {
                state.supportedLanguages
            } ?: return

        val translationMap = translations.associateBy { it.languageId }

        val allTranslations =
            supportedLanguages.map { language ->
                translationMap[language.languageId]
                    ?.toViewObject(language)
                    ?: NotTranslated(language)
            }

        val updated = state.translations + mapOf(termId to allTranslations)
        update { copy(translations = updated) }
    }

    fun onSaveTranslation(
        termId: String,
        languageId: String,
        translation: String,
    ) {
        val projectId = state.projectId ?: return
        update { copy(loadingTermId = termId) }
        val currentTranslation =
            state.translations[termId]?.firstOrNull {
                it.language.languageId == languageId
            }

        launch {
            if (translation.isEmpty() && currentTranslation != null) {
                deleteTranslation(
                    DeleteTranslationUseCase.Params(
                        projectId = projectId,
                        languageId = languageId,
                        termId = termId,
                    ),
                )
            } else {
                setTranslation(
                    SetTranslationUseCase.Params(
                        projectId = projectId,
                        termId = termId,
                        translation =
                            TranslationUpdate(
                                languageId = languageId,
                                text = translation,
                            ),
                    ),
                )
            }.onSuccess { term ->
                term.translations?.let { updateTranslations(term.id, it) }
                update {
                    copy(
                        loadingTermId = null,
                        termsPaged = termsPaged?.update(term),
                    )
                }
            }.onFailure {
                update { copy(loadingTermId = null) }
            }
        }
    }

    fun onFilterClicked() {
        update { copy(filteringTerms = true) }
    }

    fun onFilterCancelled() {
        update { copy(filteringTerms = false) }
    }

    fun onFilterConfirmed() {
        update { copy(filteringTerms = false) }
    }

    data class State(
        val projectId: String? = null,
        val query: String? = null,
        val cursor: String? = null,
        val filter: TermFilter = TermFilter.ALL,
        val order: Order = Order.UPDATED_DESC,
        val filterLanguage: Language? = null,
        val supportedLanguages: List<Language>? = null,
        val termsPaged: Paging<Term>? = null,
        val translations: Map<String, List<TermTranslationViewObject>> = emptyMap(),
        val removingTerm: Term? = null,
        val updatingTerm: Term? = null,
        val creatingTerm: Boolean = false,
        val filteringTerms: Boolean = false,
        val redirectToCursor: String? = null,
        val loading: Boolean = false,
        val loadingTermId: String? = null,
        val error: Throwable? = null,
    ) {
        val terms = termsPaged?.items.orEmpty()
        val isEmpty = termsPaged?.items?.isEmpty() == true
        val nextPage: String? = termsPaged?.pagination?.next
        val previousPage: String? = termsPaged?.pagination?.previous
        val hasNextPage = nextPage != null
        val hasPreviousPage = previousPage != null
    }
}
