package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.languages

import cz.cvut.fit.horanvoj.ribbon.model.language.Language
import cz.cvut.fit.horanvoj.ribbon.model.project.ProjectWithMembership
import cz.cvut.fit.horanvoj.ribbon.model.project.RolePermission
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.domain.usecase.GetProjectLanguagesUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.domain.usecase.RemoveLanguageFromProjectUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase.GetProjectUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.invoke
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.ViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.domain.hasPermission
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.languages.ManageLanguagesViewModel.State
import kotlinx.coroutines.flow.first

internal class ManageLanguagesViewModel(
    private val removeLanguage: RemoveLanguageFromProjectUseCase,
    private val getProjectLanguages: GetProjectLanguagesUseCase,
    private val getProject: GetProjectUseCase,
) : ViewModel<State>(State()) {
    fun onAppear(projectId: String) {
        update { copy(projectId = projectId) }
        launch { fetchLanguages() }
        launch { fetchProject() }
    }

    private suspend fun fetchLanguages() {
        val projectId = state.projectId ?: return

        update { copy(languagesLoading = true, error = null) }
        getProjectLanguages(
            GetProjectLanguagesUseCase.Params(
                projectId = projectId,
            ),
        ).onSuccess {
            update { copy(languagesLoading = false, languages = it) }
        }.onFailure {
            update { copy(languagesLoading = false, error = it) }
        }
    }

    private suspend fun fetchProject() {
        val projectId = state.projectId ?: return
        update { copy(projectLoading = true, error = null) }
        getProject(
            GetProjectUseCase.Params(
                projectId = projectId,
            ),
        ).onSuccess { projectFlow ->
            val project = projectFlow.first()
            update { copy(project = project, projectLoading = false) }
        }.onFailure {
            update { copy(error = it, projectLoading = false) }
        }
    }

    fun onRemoveLanguageClick(language: Language) {
        update { copy(removingLanguage = language) }
    }

    fun onRemoveLanguageCancelled() {
        update { copy(removingLanguage = null) }
    }

    fun onRemoveLanguageConfirmed() {
        val projectId = state.projectId ?: return
        val language = state.removingLanguage ?: return
        update { copy(removingLanguage = null, languagesLoading = true) }
        launch {
            removeLanguage(
                RemoveLanguageFromProjectUseCase.Params(
                    projectId = projectId,
                    languageId = language.languageId,
                ),
            ).onSuccess {
                update { copy(languages = it, languagesLoading = false) }
            }.onFailure {
                update { copy(error = it, languagesLoading = false) }
            }
        }
    }

    fun onAddLanguageClick() {
        update { copy(addingLanguage = true) }
    }

    fun onAddedLanguage() {
        update { copy(addingLanguage = false) }
        launch { fetchLanguages() }
    }

    fun onAddingLanguageCancel() {
        update { copy(addingLanguage = false) }
    }

    data class State(
        val projectId: String? = null,
        val project: ProjectWithMembership? = null,
        val languages: List<Language> = emptyList(),
        val removingLanguage: Language? = null,
        val addingLanguage: Boolean = false,
        val languagesLoading: Boolean = true,
        val projectLoading: Boolean = true,
        val error: Throwable? = null,
    ) {
        val loading = languagesLoading || projectLoading
        val canAddLanguage: Boolean = project?.membership.hasPermission(RolePermission.MODIFY_PROJECT_LANGUAGES)
        val canRemoveLanguages: Boolean = project?.membership.hasPermission(RolePermission.MODIFY_PROJECT_LANGUAGES)
    }
}
