package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.options

import Res
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.flex
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.minWidth
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.mdi.*
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.MaterialTextButton
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.Snackbar
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.rememberSnackbarState
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.rememberViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.routeToExport
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.routeToImport
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.routeToProjects
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.ManageCard
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.options.modify.ModifyProjectDialog
import kotlinx.browser.window
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Text

@Composable
internal fun OptionsCard() {
    val ctx = rememberPageContext()
    val projectId = ctx.route.params.getValue("projectId")

    val viewModel = rememberViewModel<OptionsViewModel>()
    val state = viewModel.state

    LaunchedEffect(viewModel, projectId) { viewModel.onAppear(projectId) }

    LaunchedEffect(state.removedProject) {
        if (state.removedProject) {
            ctx.router.routeToProjects()
        }
    }

    val snackbarState = rememberSnackbarState()

    Snackbar(state = snackbarState)

    if (state.removingApiKey) {
        RemoveApiKeyDialog(
            onConfirmClick = viewModel::onConfirmDeleteApiKey,
            onCancelClick = viewModel::onCancelDeleteApiKey,
        )
    } else if (state.removingProject) {
        RemoveProjectDialog(
            onConfirmClick = viewModel::onConfirmRemoveProject,
            onCancelClick = viewModel::onCancelRemoveProject,
        )
    } else if (state.modifyingProject) {
        ModifyProjectDialog(
            onCancelClick = viewModel::onProjectModificationCancelled,
            onModified = viewModel::onProjectModified,
        )
    }

    if (state.hasAnyOptions) {
        ManageCard(
            title = Res.string.title_options,
            onMoreButtonClick = null,
            loading = state.loading,
            icon = {
                MdiTune(
                    modifier = Modifier
                        .fontSize(24.px),
                )
            },
            modifier = Modifier
                .minWidth(300.px)
                .flex(1),
        ) {
            if (state.canEditProject) {
                MaterialTextButton(
                    onClick = {
                        viewModel.onModifyProjectClick()
                    },
                ) {
                    MdiEdit()
                    Text(Res.string.button_edit_project)
                }
            }

            if (state.canRemoveProject) {
                MaterialTextButton(
                    onClick = {
                        viewModel.onRemoveProjectClick()
                    },
                ) {
                    MdiDelete()
                    Text(Res.string.button_delete_project)
                }
            }

            if (state.hasApiKey) {
                if (state.canReadApiKey) {
                    MaterialTextButton(
                        onClick = {
                            if (state.apiKey != null) {
                                window.navigator.clipboard.writeText(state.apiKey)
                                snackbarState.show(Res.string.label_api_key_has_been_copied)
                            }
                        },
                    ) {
                        MdiContentCopy()
                        Text(Res.string.button_copy_api_key)
                    }
                }

                if (state.canRemoveApiKey) {
                    MaterialTextButton(
                        onClick = {
                            viewModel.onRemoveApiKeyClick()
                        },
                    ) {
                        MdiKeyOff()
                        Text(Res.string.button_remove_api_key)
                    }
                }
            } else if (state.canCreateApiKey) {
                MaterialTextButton(
                    onClick = {
                        viewModel.onCreateApiKeyClick()
                    },
                ) {
                    MdiKey()
                    Text(Res.string.button_generate_api_key)
                }
            }

            if (state.canImport) {
                MaterialTextButton(
                    onClick = {
                        ctx.router.routeToImport(projectId)
                    },
                ) {
                    MdiPublish()
                    Text(Res.string.button_import)
                }
            }

            if (state.canExport) {
                MaterialTextButton(
                    onClick = {
                        ctx.router.routeToExport(projectId)
                    },
                ) {
                    MdiDownload()
                    Text(Res.string.button_export)
                }
            }
        }
    }
}
