package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.model.term.Term
import cz.cvut.fit.horanvoj.ribbon.model.term.TermCreation
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term.domain.repository.TermRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term.domain.usecase.CreateTermUseCase.Params
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase

interface CreateTermUseCase : UseCase<Params, Term> {
    data class Params(
        val projectId: String,
        val creation: TermCreation,
    )
}

internal class CreateTermUseCaseImpl(
    private val termRepository: TermRepository,
) : CreateTermUseCase {
    override suspend fun execute(params: Params): Term {
        return termRepository.createTerm(
            projectId = params.projectId,
            creation = params.creation,
        )
    }
}
