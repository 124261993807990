package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.options

import cz.cvut.fit.horanvoj.ribbon.model.project.ProjectWithMembership
import cz.cvut.fit.horanvoj.ribbon.model.project.RolePermission
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase.*
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.invoke
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.ViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.domain.hasPermission
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.options.OptionsViewModel.State

internal class OptionsViewModel(
    private val getProject: GetProjectUseCase,
    private val getApiKey: GetApiKeyUseCase,
    private val createApiKey: CreateApiKeyUseCase,
    private val removeApiKey: RemoveApiKeyUseCase,
    private val removeProject: RemoveProjectUseCase,
) : ViewModel<State>(State()) {
    fun onAppear(projectId: String) {
        update { copy(projectId = projectId) }
        launch { fetchProject() }
        launch { fetchApiKey() }
    }

    private suspend fun fetchProject() {
        val projectId = state.projectId ?: return

        update { copy(projectLoading = true) }

        getProject(
            GetProjectUseCase.Params(
                projectId = projectId,
            ),
        ).onSuccess { project ->
            project.collect {
                update {
                    copy(
                        projectLoading = false,
                        project = it,
                        error = null,
                    )
                }
            }
        }.onFailure { error ->
            update {
                copy(
                    projectLoading = false,
                    error = error,
                )
            }
        }
    }

    private suspend fun fetchApiKey() {
        val projectId = state.projectId ?: return
        update { copy(apiKeyLoading = true) }

        getApiKey(
            GetApiKeyUseCase.Params(
                projectId = projectId,
            ),
        ).onSuccess {
            update { copy(apiKey = it, apiKeyLoading = false) }
        }.onFailure {
            update { copy(error = it, apiKeyLoading = false) }
        }
    }

    private fun removeApiKey() {
        val projectId = state.projectId ?: return
        update { copy(apiKeyLoading = true) }
        launch {
            removeApiKey(
                RemoveApiKeyUseCase.Params(
                    projectId = projectId,
                ),
            ).onSuccess {
                update { copy(apiKey = null, apiKeyLoading = false) }
            }.onFailure {
                update { copy(error = it, apiKeyLoading = false) }
            }
        }
    }

    private fun createApiKey() {
        val projectId = state.projectId ?: return
        update { copy(apiKeyLoading = true) }
        launch {
            createApiKey(
                CreateApiKeyUseCase.Params(
                    projectId = projectId,
                ),
            ).onSuccess {
                fetchApiKey()
                update { copy(apiKeyLoading = false) }
            }.onFailure {
                update { copy(error = it, apiKeyLoading = false) }
            }
        }
    }

    private fun removeProject() {
        val projectId = state.projectId ?: return
        update { copy(projectLoading = true) }
        launch {
            removeProject(
                RemoveProjectUseCase.Params(
                    projectId = projectId,
                ),
            ).onSuccess {
                update { copy(removedProject = true, projectLoading = false) }
            }.onFailure {
                update { copy(error = it, projectLoading = false) }
            }
        }
    }

    fun onConfirmDeleteApiKey() {
        update { copy(removingApiKey = false) }
        removeApiKey()
    }

    fun onCancelDeleteApiKey() {
        update { copy(removingApiKey = false) }
    }

    fun onConfirmRemoveProject() {
        update { copy(removingProject = false) }
        removeProject()
    }

    fun onCancelRemoveProject() {
        update { copy(removingProject = false) }
    }

    fun onRemoveProjectClick() {
        update { copy(removingProject = true) }
    }

    fun onRemoveApiKeyClick() {
        update { copy(removingApiKey = true) }
    }

    fun onCreateApiKeyClick() {
        createApiKey()
    }

    fun onModifyProjectClick() {
        update { copy(modifyingProject = true) }
    }

    fun onProjectModified() {
        update { copy(modifyingProject = false) }
    }

    fun onProjectModificationCancelled() {
        update { copy(modifyingProject = false) }
    }

    data class State(
        val projectId: String? = null,
        val project: ProjectWithMembership? = null,
        val apiKey: String? = null,
        val removingApiKey: Boolean = false,
        val removingProject: Boolean = false,
        val modifyingProject: Boolean = false,
        val removedProject: Boolean = false,
        val projectLoading: Boolean = false,
        val apiKeyLoading: Boolean = false,
        val error: Throwable? = null,
    ) {
        val loading = apiKeyLoading || projectLoading
        val hasApiKey = apiKey != null

        val canEditProject = project?.membership.hasPermission(RolePermission.MODIFY_PROJECT)
        val canRemoveProject = project?.membership.hasPermission(RolePermission.REMOVE_PROJECT)
        val canCreateApiKey = project?.membership.hasPermission(RolePermission.CREATE_API_KEY)
        val canReadApiKey = project?.membership.hasPermission(RolePermission.READ_API_KEY)
        val canRemoveApiKey = project?.membership.hasPermission(RolePermission.REMOVE_API_KEY)
        val canExport = project?.membership.hasPermission(RolePermission.CREATE_OTP)
        val canImport = project?.membership.hasPermission(RolePermission.IMPORT)

        val hasAnyOptions =
            canEditProject ||
                canRemoveProject ||
                canCreateApiKey ||
                canRemoveApiKey ||
                canRemoveApiKey ||
                canExport ||
                canImport
    }
}
