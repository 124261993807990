package cz.cvut.fit.horanvoj.ribbon.unwinder.web.components

import androidx.compose.runtime.*
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import org.koin.compose.koinInject
import kotlin.coroutines.CoroutineContext
import kotlin.coroutines.EmptyCoroutineContext

abstract class ViewModel<State : Any>(
    initialState: State,
) {
    var state by mutableStateOf(initialState)
        private set

    lateinit var coroutineScope: CoroutineScope

    protected fun update(action: State.() -> State) {
        state = action(state)
    }

    protected fun launch(
        context: CoroutineContext = EmptyCoroutineContext,
        body: suspend () -> Unit,
    ): Job {
        return coroutineScope.launch(context) { body() }
    }
}

@Composable
inline fun <reified T : ViewModel<*>> rememberViewModel(vararg keys: Any?): T {
    val coroutineScope = rememberCoroutineScope()
    val vm = koinInject<T>()

    return remember(vm, coroutineScope, *keys) {
        vm.coroutineScope = coroutineScope
        vm
    }
}
