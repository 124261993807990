package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language

import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.data.repository.LanguageRepositoryImpl
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.data.source.LanguageSource
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.domain.repository.LanguageRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.domain.usecase.*
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.infrastructure.api.LanguageApi
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.infrastructure.api.LanguageApiImpl
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.infrastructure.source.LanguageSourceImpl
import org.koin.core.module.dsl.factoryOf
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.bind
import org.koin.dsl.module

val featureLanguageModule =
    module {
        singleOf(::LanguageApiImpl) bind LanguageApi::class
        singleOf(::LanguageSourceImpl) bind LanguageSource::class
        singleOf(::LanguageRepositoryImpl) bind LanguageRepository::class

        factoryOf(::AddLanguageToProjectUseCaseImpl) bind AddLanguageToProjectUseCase::class
        factoryOf(::RemoveLanguageFromProjectUseCaseImpl) bind RemoveLanguageFromProjectUseCase::class
        factoryOf(::GetProjectLanguagesUseCaseImpl) bind GetProjectLanguagesUseCase::class
        factoryOf(::GetSupportedLanguagesUseCaseImpl) bind GetSupportedLanguagesUseCase::class
    }
