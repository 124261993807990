package cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material

import Res
import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.JustifySelf
import com.varabyte.kobweb.compose.dom.ref
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.forms.InputGroup
import com.varabyte.kobweb.silk.components.forms.InputVars
import com.varabyte.kobweb.silk.components.forms.TextInput
import com.varabyte.kobweb.silk.components.icons.mdi.MdiSearch
import com.varabyte.kobweb.silk.components.icons.mdi.MdiVisibility
import com.varabyte.kobweb.silk.components.icons.mdi.MdiVisibilityOff
import com.varabyte.kobweb.silk.components.style.vars.color.ColorVar
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.CornerSizeExtraSmall
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.CornerSizeRound
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.fadeInAnimation
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.toSitePalette
import org.jetbrains.compose.web.attributes.AutoComplete
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.px

@Composable
fun MaterialTextInput(
    text: String,
    modifier: Modifier = Modifier,
    textFieldModifier: Modifier = Modifier,
    placeholder: String? = null,
    icon: (@Composable () -> Unit)? = null,
    required: Boolean = false,
    autoComplete: AutoComplete? = null,
    password: Boolean = false,
    valid: Boolean = true,
    onTextChanged: (text: String) -> Unit,
    onCommit: () -> Unit = {},
    enabled: Boolean = true,
) {
    val style = ColorMode.current.toSitePalette()
    var passwordVisible by remember { mutableStateOf(false) }

    InputGroup(
        modifier = modifier,
    ) {
        if (icon != null) {
            LeftInset(
                modifier = Modifier
                    .left(auto),
                width = 48.px,
            ) {
                Box(
                    Modifier.align(Alignment.Center)
                        .fontSize(24.px)
                        .thenIf(!enabled) {
                            Modifier.color(style.onSurface.toRgb().copy(alpha = 96))
                        },
                ) { icon() }
            }
        }

        if (password) {
            RightInset(
                modifier = Modifier,
                width = 48.px,
            ) {
                MaterialIconButton(
                    modifier = Modifier
                        .justifySelf(JustifySelf.Start),
                    onClick = {
                        passwordVisible = !passwordVisible
                    },
                ) {
                    if (passwordVisible) {
                        MdiVisibility(
                            Modifier.color(style.onSurface)
                                .fadeInAnimation(),
                        )
                    } else {
                        MdiVisibilityOff(
                            Modifier.color(style.onSurface)
                                .fadeInAnimation(),
                        )
                    }
                }
            }
        }

        TextInput(
            text = text,
            onTextChanged = onTextChanged,
            ref =
                ref {
                    it.onkeyup = { e ->
                        if (valid && e.keyCode == 13) {
                            e.preventDefault()
                            e.stopPropagation()
                            onCommit()
                        }
                    }
                },
            enabled = enabled,
            password = password && !passwordVisible,
            invalidBorderColor = style.error,
            placeholder = placeholder,
            required = required,
            valid = valid,
            autoComplete = autoComplete,
            modifier = Modifier
                .setVariable(InputVars.BorderRadius, CornerSizeExtraSmall)
                .setVariable(InputVars.BorderColor, style.outline)
                .setVariable(InputVars.BorderFocusColor, style.primary)
                .setVariable(InputVars.BorderHoverColor, style.onSurface)
                .setVariable(InputVars.BorderInvalidColor, style.error)
                .setVariable(InputVars.InsetLeftWidth, 48.px)
                .setVariable(InputVars.Height, 56.px)
                .setVariable(InputVars.Padding, 16.px)
                .setVariable(ColorVar, style.onSurface)
                .setVariable(InputVars.PlaceholderColor, style.onSurfaceVariant)
                .setVariable(InputVars.FontSize, 16.sp)
                .thenIf(!enabled) {
                    Modifier
                        .setVariable(ColorVar, style.onSurface.toRgb().copy(alpha = 96))
                        .setVariable(InputVars.BorderColor, style.onSurface.toRgb().copy(alpha = 30))
                }
                .then(textFieldModifier),
        )
    }
}

@Composable
fun SearchInput(
    text: String,
    modifier: Modifier = Modifier,
    placeholder: String? = null,
    onTextChanged: (text: String) -> Unit,
    onCommit: () -> Unit = {},
    enabled: Boolean = true,
) {
    val style = ColorMode.current.toSitePalette()

    MaterialTextInput(
        text = text,
        placeholder = placeholder,
        onTextChanged = onTextChanged,
        onCommit = onCommit,
        enabled = enabled,
        icon = {
            MdiSearch()
        },
        modifier = Modifier
            .then(modifier),
        textFieldModifier = Modifier
            .setVariable(InputVars.BorderRadius, CornerSizeRound)
            .setVariable(InputVars.BorderColor, Colors.Transparent)
            .setVariable(InputVars.BorderFocusColor, Colors.Transparent)
            .setVariable(InputVars.BorderHoverColor, Colors.Transparent)
            .setVariable(InputVars.BorderInvalidColor, Colors.Transparent)
            .setVariable(InputVars.FilledColor, style.secondaryContainer)
            .setVariable(ColorVar, style.onSurface)
            .materialShadow(MaterialShadowLevel.LEVEL_THREE),
    )
}

@Composable
fun SearchInputOutlined(
    text: String,
    modifier: Modifier = Modifier,
    placeholder: String? = Res.string.label_search,
    onTextChanged: (text: String) -> Unit,
    onCommit: () -> Unit = {},
    enabled: Boolean = true,
) {
    MaterialTextInput(
        text = text,
        placeholder = placeholder,
        onTextChanged = onTextChanged,
        onCommit = onCommit,
        enabled = enabled,
        icon = {
            MdiSearch()
        },
        modifier = Modifier
            .then(modifier),
        textFieldModifier = Modifier
            .setVariable(InputVars.BorderRadius, CornerSizeRound),
    )
}
