package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.terms

import Res
import androidx.compose.runtime.*
import com.varabyte.kobweb.browser.dom.clearFocus
import com.varabyte.kobweb.compose.css.FontStyle
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.css.WordBreak
import com.varabyte.kobweb.compose.dom.ref
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.mdi.*
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import cz.cvut.fit.horanvoj.ribbon.model.common.Order
import cz.cvut.fit.horanvoj.ribbon.model.language.Language
import cz.cvut.fit.horanvoj.ribbon.model.project.MemberRole
import cz.cvut.fit.horanvoj.ribbon.model.term.Term
import cz.cvut.fit.horanvoj.ribbon.model.term.TermFilter
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.EscapeKeyEffect
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.CornerSizeExtraSmall
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceSmall
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.filter.TermFilterDialog
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.PageLayout
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.ProjectTab
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.RequireAuthenticationEffect
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.RequireProjectRoleEffect
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.*
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.rememberViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.routeToManageTerms
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.EmptyView
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.ErrorSnackbar
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.PageControls
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.TranslationTextArea
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.domain.displayName
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.domain.format
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.domain.shortDisplayName
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.enterVerticallyAnimation
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.terms.TermTranslationViewObject.NotTranslated
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.terms.TermTranslationViewObject.Translated
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.toSitePalette
import cz.cvut.fit.horanvoj.ribbon.util.findEnumValue
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLTextAreaElement

@Page("/project/{projectId}/manage/terms")
@Composable
fun TermsPage() {
    RequireAuthenticationEffect()
    RequireProjectRoleEffect(
        MemberRole.ADMINISTRATOR,
        MemberRole.DEVELOPER,
    )

    val ctx = rememberPageContext()
    val projectId = ctx.route.params.getValue("projectId")

    val query = ctx.route.params["query"]
    val cursor = ctx.route.params["cursor"]
    val order = ctx.route.params["order"]?.let { findEnumValue<Order>(it) }
    val filter = ctx.route.params["filter"]?.let { findEnumValue<TermFilter>(it) }

    val viewModel = rememberViewModel<TermsViewModel>()

    LaunchedEffect(viewModel, projectId, query, cursor, filter, order) {
        viewModel.onAppear(projectId, query, cursor, filter, order)
    }

    LaunchedEffect(viewModel.state.redirectToCursor) {
        val redirectToCursor = viewModel.state.redirectToCursor
        if (redirectToCursor?.isEmpty() == true) {
            ctx.router.routeToManageTerms(
                projectId = projectId,
                order = order,
                filter = filter,
            )
        } else if (redirectToCursor != null) {
            ctx.router.routeToManageTerms(
                projectId = projectId,
                cursor = redirectToCursor,
                order = order,
                filter = filter,
            )
        }
    }

    ErrorSnackbar(
        throwable = viewModel.state.error,
    )

    val removingTerm = viewModel.state.removingTerm
    if (removingTerm != null) {
        RemoveLanguagesDialog(
            term = removingTerm,
            onConfirmed = viewModel::onRemoveTermConfirmed,
            onCancelled = viewModel::onRemoveTermCancelled,
        )
    }

    val updatingTerm = viewModel.state.updatingTerm
    if (updatingTerm != null) {
        UpdateTermDialog(
            term = updatingTerm,
            onConfirmed = viewModel::onUpdateTermConfirmed,
            onCancelled = viewModel::onUpdateTermCancelled,
        )
    }

    if (viewModel.state.creatingTerm) {
        CreateTermDialog(
            onCancelled = viewModel::onCreateTermCancelled,
            onConfirmed = viewModel::onCreateTermConfirmed,
        )
    }

    if (viewModel.state.filteringTerms) {
        TermFilterDialog(
            filter = viewModel.state.filter,
            order = viewModel.state.order,
            onCancelled = viewModel::onFilterCancelled,
            onConfirmed = { newFilter, newOrder ->
                viewModel.onFilterConfirmed()

                ctx.router.routeToManageTerms(
                    projectId = projectId,
                    order = newOrder,
                    filter = newFilter,
                    query = query,
                )
            },
        )
    }

    PageLayout(
        title = Res.string.title_terms,
        activeTab = ProjectTab.TERMS,
        loading = viewModel.state.loading,
    ) {
        Column(
            modifier = Modifier
                .padding(SpaceMedium)
                .fillMaxWidth()
                .gap(SpaceMedium),
        ) {
            TermControls(
                projectId = projectId,
                query = query,
                onFilterClick = viewModel::onFilterClicked,
                filter = viewModel.state.filter,
                order = viewModel.state.order,
            )

            OutlinedCard(
                modifier = Modifier
                    .fillMaxWidth()
                    .minHeight(70.vh),
            ) {
                Column(Modifier.fillMaxWidth()) {
                    if (viewModel.state.isEmpty) {
                        EmptyView(
                            text = Res.string.label_terms_empty,
                        )
                    } else {
                        viewModel.state.terms.forEachIndexed { index, term ->
                            val translations =
                                remember(viewModel.state.translations, term.id) {
                                    viewModel.state.translations[term.id]
                                }
                            TermItem(
                                isLoading = term.id == viewModel.state.loadingTermId,
                                term = term,
                                onRemoveTermClicked = {
                                    viewModel.onRemoveTermClick(term)
                                },
                                onUpdateTermClicked = {
                                    viewModel.onUpdateTermClick(term)
                                },
                                onTranslateClick = {
                                    viewModel.onTranslateClicked(term)
                                },
                                translations = translations,
                                onSaveTranslation = { language, text ->
                                    viewModel.onSaveTranslation(
                                        termId = term.id,
                                        languageId = language.languageId,
                                        translation = text,
                                    )
                                },
                            )

                            if (index != viewModel.state.terms.lastIndex) {
                                MaterialHorizontalDivider()
                            }
                        }
                    }
                }
            }

            PageControls(
                hasPreviousPage = viewModel.state.hasPreviousPage,
                hasNextPage = viewModel.state.hasNextPage,
                onPreviousPageClicked = {
                    ctx.router.routeToManageTerms(
                        projectId = projectId,
                        cursor = viewModel.state.previousPage,
                        query = query,
                        order = order,
                        filter = filter,
                    )
                },
                onNextPageClicked = {
                    ctx.router.routeToManageTerms(
                        projectId = projectId,
                        cursor = viewModel.state.nextPage,
                        query = query,
                        order = order,
                        filter = filter,
                    )
                },
            )
        }

        ExtendedFloatingActionButton(
            text = Res.string.button_create_term,
            onClick = viewModel::onCreateTermClicked,
        ) {
            MdiAddCircle()
        }
    }
}

@Composable
fun TermControls(
    projectId: String,
    query: String?,
    order: Order,
    filter: TermFilter,
    onFilterClick: () -> Unit,
) {
    val ctx = rememberPageContext()

    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier
            .fillMaxWidth()
            .flexWrap(FlexWrap.WrapReverse)
            .gap(SpaceMedium),
    ) {
        MaterialTonalButton(
            onClick = { onFilterClick() },
        ) {
            MdiFilterList()
            Text(Res.string.label_filter)
        }

        SpanText(
            text = order.displayName + ", " + filter.displayName,
            modifier = Modifier
                .typography(TitleMedium),
        )

        Spacer()

        var inputQuery by remember { mutableStateOf(query.orEmpty()) }

        SearchInputOutlined(
            text = inputQuery,
            onTextChanged = {
                inputQuery = it
            },
            onCommit = {
                ctx.router.routeToManageTerms(
                    projectId = projectId,
                    query = inputQuery,
                    order = order,
                    filter = filter,
                )
            },
        )
    }
}

@Composable
private fun TermItem(
    term: Term,
    isLoading: Boolean,
    onUpdateTermClicked: () -> Unit,
    onRemoveTermClicked: () -> Unit,
    onTranslateClick: () -> Unit,
    onSaveTranslation: (language: Language, text: String) -> Unit,
    translations: List<TermTranslationViewObject>?,
    modifier: Modifier = Modifier,
) {
    Column(
        modifier = modifier
            .fillMaxWidth(),
    ) {
        var areTranslationsShown by remember { mutableStateOf(false) }

        if (isLoading) {
            HorizontalProgressBar(
                Modifier.enterVerticallyAnimation()
                    .fillMaxWidth(),
            )
        }

        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier
                .gap(SpaceMedium)
                .fillMaxWidth()
                .padding(topBottom = SpaceMedium)
                .flexWrap(FlexWrap.Wrap),
        ) {
            Column(
                verticalArrangement = Arrangement.Center,
                modifier = Modifier,
            ) {
                SpanText(
                    text = term.id,
                    modifier = Modifier
                        .typography(TitleMedium)
                        .wordBreak(WordBreak.BreakAll)
                        .padding(bottom = SpaceSmall),
                )

                term.description?.let {
                    Row(
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier
                            .gap(SpaceSmall)
                            .typography(BodySmall),
                    ) {
                        MdiNotes(
                            modifier = Modifier
                                .title(Res.string.label_description),
                        )

                        SpanText(text = it)
                    }
                }

                term.authorEmail?.let {
                    Row(
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier
                            .gap(SpaceSmall)
                            .typography(BodySmall),
                    ) {
                        MdiPerson(
                            modifier = Modifier
                                .title(Res.string.label_author),
                        )

                        SpanText(text = it)
                    }
                }

                term.statistics?.let { stats ->
                    Row(
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier
                            .gap(SpaceSmall)
                            .typography(BodySmall),
                    ) {
                        MdiBarChart(
                            modifier = Modifier
                                .title(Res.string.label_translations),
                        )

                        SpanText(
                            text =
                                Res.string.label_translated_in.format(
                                    percent = stats.percentageOfTranslations.toString(),
                                ),
                        )
                    }
                }
            }

            Row(
                horizontalArrangement = Arrangement.End,
                modifier = Modifier
                    .flexGrow(1),
            ) {
                MaterialIconButton(
                    onClick = {
                        onUpdateTermClicked()
                    },
                ) {
                    MdiEdit()
                }

                MaterialIconButton(
                    onClick = {
                        onRemoveTermClicked()
                    },
                ) {
                    MdiDelete()
                }

                MaterialIconButton(
                    onClick = {
                        areTranslationsShown = !areTranslationsShown
                        onTranslateClick()
                    },
                ) {
                    MdiTranslate()
                }
            }
        }

        if (translations != null && areTranslationsShown) {
            TermTranslations(
                translations = translations,
                onSaveTranslation = onSaveTranslation,
            )
        }
    }
}

@Composable
private fun TermTranslations(
    translations: List<TermTranslationViewObject>,
    onSaveTranslation: (language: Language, text: String) -> Unit,
    modifier: Modifier = Modifier,
) {
    val style = ColorMode.current.toSitePalette()

    Column(
        modifier = modifier
            .fillMaxWidth()
            .background(style.secondaryContainer)
            .color(style.onSecondaryContainer)
            .margin(bottom = SpaceMedium),
    ) {
        translations.forEachIndexed { index, translation ->
            Translation(
                translation = translation,
                onSaveTranslation = {
                    onSaveTranslation(translation.language, it)
                },
            )

            if (index != translations.lastIndex) {
                MaterialHorizontalDivider()
            }
        }
    }
}

@Composable
private fun Translation(
    onSaveTranslation: (text: String) -> Unit,
    translation: TermTranslationViewObject,
    modifier: Modifier = Modifier,
) {
    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = modifier
            .fillMaxWidth()
            .padding(SpaceSmall)
            .gap(SpaceMedium),
    ) {
        SpanText(
            text = translation.language.shortDisplayName,
            modifier = Modifier
                .typography(LabelLarge)
                .singleLine()
                .flexShrink(0),
        )

        when (translation) {
            is NotTranslated -> {
                TranslationText(
                    text = null,
                    onSaveTranslation = onSaveTranslation,
                    modifier = Modifier
                        .color(Colors.Red)
                        .fontStyle(FontStyle.Italic),
                )
            }

            is Translated -> {
                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .gap(SpaceSmall),
                ) {
                    TranslationText(
                        text = translation.text,
                        onSaveTranslation = onSaveTranslation,
                    )

                    val author = translation.authorEmail
                    val date = translation.updated.format()

                    val updatedText =
                        if (author == null) {
                            Res.string.label_last_updated_no_author.format(
                                date = date,
                            )
                        } else {
                            Res.string.label_last_updated.format(
                                name = author,
                                date = date,
                            )
                        }

                    SpanText(
                        text = updatedText,
                        modifier = Modifier
                            .typography(LabelMedium),
                    )
                }
            }
        }
    }
}

val TranslationTextStyle by ComponentStyle {
    base {
        Modifier
            .gap(SpaceSmall)
            .padding(right = SpaceSmall)
            .borderRadius(CornerSizeExtraSmall)
    }

    hover {
        Modifier
            .background(colorMode.toSitePalette().outlineVariant)
    }
}

@Composable
private fun TranslationText(
    text: String?,
    onSaveTranslation: (text: String) -> Unit,
    modifier: Modifier = Modifier,
) {
    var isHovered by remember { mutableStateOf(false) }
    var isTextInputVisible by remember { mutableStateOf(false) }
    var editedInput by remember(text) { mutableStateOf(text.orEmpty()) }

    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier
            .fillMaxWidth()
            .typography(BodyLarge)
            .then(modifier),
    ) {
        if (!isTextInputVisible) {
            Row(
                modifier =
                    TranslationTextStyle.toModifier()
                        .onClick {
                            isTextInputVisible = true
                            isHovered = false
                        },
                verticalAlignment = Alignment.CenterVertically,
                ref =
                    ref {
                        it.onmouseover = {
                            isHovered = true
                            Unit
                        }
                        it.onmouseout = {
                            isHovered = false
                            Unit
                        }
                    },
            ) {
                SpanText(
                    text = text ?: Res.string.label_no_translation,
                    modifier = Modifier
                        .whiteSpace(WhiteSpace.PreWrap),
                )

                if (isHovered) {
                    SpanText(
                        text = "edit",
                        modifier = Modifier
                            .color(ColorMode.current.toSitePalette().onSecondaryContainer)
                            .classNames("material-icons-outlined"),
                    )
                }
            }
        } else {
            var textAreaRef: HTMLTextAreaElement? by remember { mutableStateOf(null) }

            EscapeKeyEffect {
                editedInput = text.orEmpty()
                textAreaRef?.clearFocus()
                isTextInputVisible = false
            }

            TranslationTextArea(
                value = editedInput,
                onFocus = {},
                isFocused = true,
                onInput = {
                    editedInput = it
                },
                onBlur = {
                    if (editedInput != text &&
                        !(editedInput.isEmpty() && text == null)
                    ) {
                        onSaveTranslation(editedInput)
                        isTextInputVisible = false
                    } else {
                        isTextInputVisible = false
                    }
                },
                onClick = {},
                ref = {
                    textAreaRef = it
                    it.focus()
                    onDispose {
                        textAreaRef = null
                    }
                },
            )
        }
    }
}
