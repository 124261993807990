package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.model.project.ProjectWithMembership
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.repository.ProjectRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase.GetProjectUseCase.Params
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase
import kotlinx.coroutines.flow.Flow

interface GetProjectUseCase : UseCase<Params, Flow<ProjectWithMembership>> {
    data class Params(
        val projectId: String,
    )
}

internal class GetProjectUseCaseImpl(
    private val projectRepository: ProjectRepository,
) : GetProjectUseCase {
    override suspend fun execute(params: Params): Flow<ProjectWithMembership> {
        return projectRepository.getProject(
            projectId = params.projectId,
        )
    }
}
