package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.repository.ProjectRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase.GetApiKeyUseCase.Params
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase

interface GetApiKeyUseCase : UseCase<Params, String?> {
    data class Params(
        val projectId: String,
    )
}

internal class GetApiKeyUseCaseImpl(
    private val projectRepository: ProjectRepository,
) : GetApiKeyUseCase {
    override suspend fun execute(params: Params): String? {
        return projectRepository.getApiKey(
            projectId = params.projectId,
        )
    }
}
