package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.auth.domain.repository.AuthRepository
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCaseNoParams

interface GetIsLoggedInUseCase : UseCaseNoParams<Boolean>

internal class GetIsLoggedInUseCaseImpl(
    private val authRepository: AuthRepository,
) : GetIsLoggedInUseCase {
    override suspend fun execute(): Boolean {
        return authRepository.isLoggedIn()
    }
}
