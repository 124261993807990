package cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets

import Res
import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.gap
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.MaterialTextButton
import org.jetbrains.compose.web.dom.Text

@Composable
fun PageControls(
    hasPreviousPage: Boolean,
    hasNextPage: Boolean,
    onPreviousPageClicked: () -> Unit,
    onNextPageClicked: () -> Unit,
    modifier: Modifier = Modifier,
) {
    Row(
        modifier = modifier.fillMaxWidth()
            .gap(SpaceMedium),
    ) {
        Spacer()
        MaterialTextButton(
            enabled = hasPreviousPage,
            onClick = {
                onPreviousPageClicked()
            },
        ) {
            Text(Res.string.button_prev_page)
        }

        MaterialTextButton(
            enabled = hasNextPage,
            onClick = {
                onNextPageClicked()
            },
        ) {
            Text(Res.string.button_next_page)
        }
        Spacer()
    }
}
