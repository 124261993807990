package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.repository.ProjectRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase.RemoveInviteUseCase.Params
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase

interface RemoveInviteUseCase : UseCase<Params, Unit> {
    data class Params(
        val projectId: String,
        val inviteId: String,
    )
}

internal class RemoveInviteUseCaseImpl(
    private val projectRepository: ProjectRepository,
) : RemoveInviteUseCase {
    override suspend fun execute(params: Params) {
        projectRepository.removeInvite(
            projectId = params.projectId,
            inviteId = params.inviteId,
        )
    }
}
