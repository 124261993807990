package cz.cvut.fit.horanvoj.ribbon.unwinder.web.domain

import cz.cvut.fit.horanvoj.ribbon.model.user.User
import org.kotlincrypto.hash.sha2.SHA256

@OptIn(ExperimentalStdlibApi::class)
val User.imageUrl
    get() =
        run {
            val data = emailAddress.trim().lowercase().encodeToByteArray()
            val imageId =
                SHA256()
                    .digest(data)
                    .toHexString()

            "https://gravatar.com/avatar/$imageId?d=404"
        }
