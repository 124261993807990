package cz.cvut.fit.horanvoj.ribbon.unwinder.web

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TransitionProperty
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.animation.Keyframes
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.init.registerStyleBase
import com.varabyte.kobweb.silk.theme.colors.palette.overlay
import org.jetbrains.compose.web.css.AnimationTimingFunction
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.s

@InitSilk
fun initSiteStyles(ctx: InitSilkContext) {
    ctx.stylesheet.registerStyleBase("body") {
        Modifier
            .fontFamily("Roboto", "sans-serif")
            .fontSize(16.px)
            .height(100.percent)
    }

    ctx.stylesheet.registerStyleBase("html") {
        Modifier
            .overflow(Overflow.Hidden, Overflow.Scroll) // always show scrollbar
            .styleModifier {
                property("scrollbar-color", ctx.theme.palettes.dark.overlay.toString() + " transparent")
            }
    }

    ctx.stylesheet.registerStyleBase("*") {
        Modifier
            .padding(0.px)
            .margin(0.px)
    }

    ctx.stylesheet.registerStyleBase("textarea, input, button, select") {
        Modifier
            .fontFamily("inherit")
            .fontSize(FontSize.Inherit)
    }
}

val AnimationFadeIn by Keyframes {
    from { Modifier.opacity(0f) }
    to { Modifier.opacity(1f) }
}

val AnimationEnterVertical by Keyframes {
    from { Modifier.opacity(0f).height(0.px) }
    to { Modifier.opacity(1f) }
}

val BackgroundTransition =
    CSSTransition(
        property = TransitionProperty.of("background"),
        duration = 0.3.s,
        timingFunction = AnimationTimingFunction.EaseInOut,
    )

@Composable
fun Modifier.fadeInAnimation() =
    this.animation(
        AnimationFadeIn.toAnimation(
            duration = 0.3.s,
        ),
    )

@Composable
fun Modifier.enterVerticallyAnimation() =
    this.animation(
        AnimationEnterVertical.toAnimation(
            duration = 0.3.s,
        ),
    )
