package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.user

import Res
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.minWidth
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.mdi.MdiAccountCircle
import com.varabyte.kobweb.silk.components.icons.mdi.MdiAlternateEmail
import com.varabyte.kobweb.silk.components.icons.mdi.MdiDelete
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceSmall
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.PageLayout
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.RequireAuthenticationEffect
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.*
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.rememberViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.toSitePalette
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Page("/user")
@Composable
fun UserPage() {
    val ctx = rememberPageContext()
    val viewModel = rememberViewModel<UserViewModel>()
    val snackbarState = rememberSnackbarState()

    LaunchedEffect(viewModel) {
        viewModel.onAppear()
    }

    LaunchedEffect(viewModel.state.hasResetPassword) {
        if (viewModel.state.hasResetPassword) {
            snackbarState.show(Res.string.label_password_reset_email_sent)
        }
    }

    LaunchedEffect(viewModel.state.hasDeletedAccount) {
        if (viewModel.state.hasDeletedAccount) {
            ctx.router.tryRoutingTo("/")
        }
    }

    Snackbar(
        state = snackbarState,
    )

    if (viewModel.state.isDeleteDialogVisible) {
        DeleteDialog(
            onCancelClick = viewModel::onCancelDeleteClick,
            onConfirmClick = viewModel::onConfirmDeleteClick,
        )
    }

    PageLayout(
        title = Res.string.title_user,
        loading = viewModel.state.loading,
    ) {
        UserDetail(
            state = viewModel.state,
            onNameChanged = viewModel::onNameChanged,
            onDeleteClick = viewModel::onDeleteClick,
            onResetPasswordClick = viewModel::onResetButtonClick,
            onSaveClick = viewModel::onSaveClick,
        )
    }
}

@Composable
private fun UserDetail(
    state: UserViewModel.State,
    onNameChanged: (String) -> Unit,
    onDeleteClick: () -> Unit,
    onResetPasswordClick: () -> Unit,
    onSaveClick: () -> Unit,
) {
    val style = ColorMode.current.toSitePalette()

    RequireAuthenticationEffect()
    Column(
        verticalArrangement = Arrangement.Center,
        modifier = Modifier
            .margin(SpaceMedium)
            .minWidth(35.percent),
    ) {
        SpanText(
            text = Res.string.title_your_account,
            modifier = Modifier
                .typography(TitleLarge)
                .margin(bottom = SpaceMedium),
        )

        SpanText(
            text = Res.string.label_email_address,
            modifier = Modifier
                .typography(BodySmall)
                .margin(bottom = SpaceSmall),
        )

        MaterialTextInput(
            text = state.email,
            enabled = false,
            onTextChanged = {},
            icon = {
                MdiAlternateEmail()
            },
            modifier = Modifier
                .margin(bottom = SpaceMedium)
                .fillMaxWidth(),
        )

        SpanText(
            text = Res.string.label_full_name,
            modifier = Modifier
                .typography(BodySmall)
                .margin(bottom = SpaceSmall),
        )

        MaterialTextInput(
            text = state.name,
            placeholder = Res.string.label_full_name,
            required = true,
            onTextChanged = onNameChanged,
            icon = {
                MdiAccountCircle()
            },
            modifier = Modifier
                .margin(bottom = SpaceMedium)
                .fillMaxWidth(),
        )

        Span {
            SpanText(text = Res.string.label_user_avatar_info)
            Link("https://gravatar.com")
        }

        Row(
            modifier = Modifier
                .fillMaxWidth()
                .margin(top = SpaceMedium)
                .gap(SpaceSmall),
        ) {
            MaterialFilledButton(
                backgroundColor = style.error,
                color = style.onError,
                onClick = {
                    onDeleteClick()
                },
            ) {
                Text(Res.string.button_delete)
            }

            MaterialTonalButton(
                onClick = {
                    onResetPasswordClick()
                },
            ) {
                Text(Res.string.button_reset_password)
            }

            Spacer()

            MaterialFilledButton(
                onClick = {
                    onSaveClick()
                },
                enabled = state.isSaveButtonEnabled,
            ) {
                Text(Res.string.button_save)
            }
        }
    }
}

@Composable
private fun DeleteDialog(
    onConfirmClick: () -> Unit,
    onCancelClick: () -> Unit,
) {
    MaterialDialog(
        icon = {
            MdiDelete()
        },
        content = {
            Text(Res.string.label_delete_account_info)
        },
        headline = {
            Text(Res.string.title_are_you_sure)
        },
        onDismiss = onCancelClick,
        buttons = {
            MaterialTextButton(
                onClick = { onCancelClick() },
            ) {
                Text(Res.string.button_cancel)
            }
            MaterialTextButton(
                onClick = { onConfirmClick() },
            ) {
                Text(Res.string.button_confirm)
            }
        },
    )
}
