package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.projects.createProject

import Res
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.silk.components.text.SpanText
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.*
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.rememberViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.ErrorSnackbar
import org.jetbrains.compose.web.dom.Text

@Composable
fun CreateProjectDialog(
    onCreated: () -> Unit,
    onCancelled: () -> Unit,
) {
    val viewModel = rememberViewModel<CreateProjectViewModel>()

    LaunchedEffect(viewModel) {
        viewModel.onAppear()
    }

    ErrorSnackbar(viewModel.state.error)

    LaunchedEffect(viewModel.state.createdProject) {
        if (viewModel.state.createdProject) {
            onCreated()
        }
    }

    MaterialDialog(
        headline = {
            Text(Res.string.title_create_project)
        },
        onDismiss = onCancelled,
        buttons = {
            MaterialTextButton(
                onClick = {
                    onCancelled()
                },
            ) {
                Text(Res.string.button_cancel)
            }
            MaterialTextButton(
                enabled = viewModel.state.isCreateEnabled,
                onClick = {
                    viewModel.onCreateClick()
                },
            ) {
                Text(Res.string.button_create)
            }
        },
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .gap(SpaceMedium),
        ) {
            SpanText(
                text = Res.string.label_project_creation,
                modifier = Modifier
                    .typography(BodyMedium),
            )

            MaterialTextInput(
                text = viewModel.state.name,
                onTextChanged = viewModel::onNameChanged,
                placeholder = Res.string.label_project_name,
                modifier = Modifier
                    .fillMaxWidth(),
            )
        }
    }
}
