package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage

import cz.cvut.fit.horanvoj.ribbon.model.language.Language
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.domain.usecase.GetProjectLanguagesUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.invoke
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.ViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.LanguagesCardViewModel.State

internal class LanguagesCardViewModel(
    private val getProjectLanguages: GetProjectLanguagesUseCase,
) : ViewModel<State>(State()) {
    fun onAppear(projectId: String) {
        update { copy(projectId = projectId) }
        launch { fetchLanguages() }
    }

    private suspend fun fetchLanguages() {
        val projectId = state.projectId ?: return
        update { copy(loading = true) }
        getProjectLanguages(
            GetProjectLanguagesUseCase.Params(
                projectId = projectId,
            ),
        ).onSuccess {
            update { copy(languages = it.take(10), loading = false) }
        }.onFailure {
            update { copy(error = it, loading = false) }
        }
    }

    data class State(
        val projectId: String? = null,
        val languages: List<Language> = emptyList(),
        val loading: Boolean = true,
        val error: Throwable? = null,
    )
}
