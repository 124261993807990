package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.options.modify

import Res
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.mdi.MdiEdit
import com.varabyte.kobweb.silk.components.text.SpanText
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.*
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.rememberViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.ErrorSnackbar
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.enterVerticallyAnimation
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.fadeInAnimation
import org.jetbrains.compose.web.dom.Text

@Composable
internal fun ModifyProjectDialog(
    onModified: () -> Unit,
    onCancelClick: () -> Unit,
) {
    val ctx = rememberPageContext()
    val projectId = ctx.route.params.getValue("projectId")

    val viewModel = rememberViewModel<ModifyProjectViewModel>()

    LaunchedEffect(viewModel) {
        viewModel.onAppear(projectId)
    }

    ErrorSnackbar(viewModel.state.error)

    LaunchedEffect(viewModel.state.modifiedProject) {
        if (viewModel.state.modifiedProject) {
            onModified()
        }
    }

    MaterialDialog(
        modifier = Modifier
            .fadeInAnimation(),
        icon = {
            MdiEdit()
        },
        headline = {
            Text(Res.string.title_edit_project)
        },
        onDismiss = onCancelClick,
        buttons = {
            MaterialTextButton(
                onClick = { onCancelClick() },
            ) {
                Text(Res.string.button_cancel)
            }
            MaterialTextButton(
                enabled = viewModel.state.isModifyEnabled,
                onClick = { viewModel.onModifyClick() },
            ) {
                Text(Res.string.button_confirm)
            }
        },
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .gap(SpaceMedium),
        ) {
            if (viewModel.state.loading) {
                HorizontalProgressBar(
                    modifier = Modifier
                        .fillMaxWidth()
                        .enterVerticallyAnimation(),
                )
            } else {
                SpanText(
                    text = Res.string.label_edit_project,
                    modifier = Modifier
                        .typography(BodyMedium),
                )

                MaterialTextInput(
                    text = viewModel.state.name,
                    onTextChanged = viewModel::onNameChanged,
                    placeholder = Res.string.label_project_name,
                    modifier = Modifier
                        .fillMaxWidth(),
                )
            }
        }
    }
}
