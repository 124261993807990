package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.members

import Res
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.mdi.MdiAddCircle
import com.varabyte.kobweb.silk.components.icons.mdi.MdiDelete
import com.varabyte.kobweb.silk.components.icons.mdi.MdiEdit
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import cz.cvut.fit.horanvoj.ribbon.model.invite.Invite
import cz.cvut.fit.horanvoj.ribbon.model.project.MemberRole
import cz.cvut.fit.horanvoj.ribbon.model.project.ProjectMember
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.PageLayout
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.ProjectTab
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.RequireAuthenticationEffect
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.RequireProjectRoleEffect
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.*
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.rememberViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.routeToProjects
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.ErrorSnackbar
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.UserImage
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.domain.displayName
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.toSitePalette
import org.jetbrains.compose.web.css.px

@Page("/project/{projectId}/manage/members")
@Composable
fun MembersPage() {
    RequireAuthenticationEffect()
    RequireProjectRoleEffect(
        MemberRole.ADMINISTRATOR,
        MemberRole.DEVELOPER,
    )

    val ctx = rememberPageContext()
    val projectId = ctx.route.params.getValue("projectId")
    val viewModel = rememberViewModel<MemberViewModel>()
    val snackbarState = rememberSnackbarState()

    LaunchedEffect(viewModel) {
        viewModel.onAppear(projectId)
    }

    ErrorSnackbar(viewModel.state.error)
    Snackbar(snackbarState)

    LaunchedEffect(viewModel.state.invitedMember) {
        if (viewModel.state.invitedMember) {
            snackbarState.show(Res.string.label_user_has_been_invited_info)
        }
    }
    LaunchedEffect(viewModel.state.removedSelf) {
        if (viewModel.state.removedSelf) {
            ctx.router.routeToProjects()
        }
    }

    if (viewModel.state.invitingMember) {
        InviteMemberDialog(
            onCancelled = viewModel::onInviteMemberCancelled,
            onInvite = viewModel::onMemberInviteConfirmed,
        )
    }

    val removingInvite = viewModel.state.removingInvite
    if (removingInvite != null) {
        RemoveInviteDialog(
            invite = removingInvite,
            onConfirmed = viewModel::onRemoveInviteConfirmed,
            onCancelled = viewModel::onRemoveInviteCancelled,
        )
    }

    val removingMember = viewModel.state.removingMember
    if (removingMember != null) {
        RemoveMemberDialog(
            user = removingMember,
            onConfirmed = viewModel::onRemoveMemberConfirmed,
            onCancelled = viewModel::onRemoveMemberCancelled,
        )
    }

    val modifyingMember = viewModel.state.modifyingMember
    if (modifyingMember != null) {
        ModifyMemberDialog(
            member = modifyingMember,
            onConfirmed = viewModel::onModifyMemberConfirmed,
            onCancelled = viewModel::onModifyMemberCancelled,
        )
    }

    PageLayout(
        title = Res.string.title_members,
        loading = viewModel.state.loading,
        activeTab = ProjectTab.MANAGE,
        retainContentOnLoading = true,
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .gap(SpaceMedium)
                .padding(SpaceMedium),
        ) {
            viewModel.state.members.forEach { member ->
                Member(
                    member = member,
                    canModify = viewModel.state.modifiableMembers.contains(member.user.id),
                    onModifyMemberRoleClick = {
                        viewModel.onModifyMemberRoleClick(member.user)
                    },
                    onRemoveMemberClick = {
                        viewModel.onRemoveMemberClick(member.user)
                    },
                )
            }

            viewModel.state.invites.forEach { invite ->
                Invite(
                    invite = invite,
                    canModify = viewModel.state.canModifyMembers,
                    onRemoveInviteClick = {
                        viewModel.onRemoveInviteClick(invite)
                    },
                )
            }
        }

        if (viewModel.state.canInviteMembers) {
            ExtendedFloatingActionButton(
                text = Res.string.button_invite_member,
                onClick = viewModel::onInviteMemberClick,
            ) {
                MdiAddCircle()
            }
        }
    }
}

@Composable
private fun Member(
    member: ProjectMember,
    canModify: Boolean,
    onModifyMemberRoleClick: () -> Unit,
    onRemoveMemberClick: () -> Unit,
) {
    OutlinedCard(
        modifier = Modifier
            .fillMaxWidth(),
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier
                .fillMaxWidth()
                .gap(SpaceMedium),
        ) {
            UserImage(
                user = member.user,
                size = 36.px,
            )

            Column {
                SpanText(
                    text = "${member.user.name} (${member.user.emailAddress})",
                    modifier = Modifier
                        .typography(TitleMedium),
                )
                SpanText(
                    text = member.role.displayName,
                    modifier = Modifier
                        .typography(LabelLarge),
                )
            }
            Spacer()

            if (canModify) {
                MaterialIconButton(
                    onClick = {
                        onModifyMemberRoleClick()
                    },
                ) {
                    MdiEdit(
                        Modifier.color(OutlinedCardColor.value()),
                    )
                }

                MaterialIconButton(
                    onClick = {
                        onRemoveMemberClick()
                    },
                ) {
                    MdiDelete(
                        Modifier.color(OutlinedCardColor.value()),
                    )
                }
            }
        }
    }
}

@Composable
private fun Invite(
    invite: Invite,
    canModify: Boolean,
    onRemoveInviteClick: () -> Unit,
) {
    val style = ColorMode.current.toSitePalette()
    OutlinedCard(
        modifier = Modifier
            .fillMaxWidth()
            .setVariable(OutlinedCardBackground, style.tertiaryContainer)
            .setVariable(OutlinedCardColor, style.onTertiaryContainer),
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier
                .fillMaxWidth()
                .gap(SpaceMedium),
        ) {
            Column {
                SpanText(
                    text = invite.invitedUserEmail,
                    modifier = Modifier
                        .typography(TitleMedium),
                )
                SpanText(
                    text =
                        Res.string.label_invited_by.format(
                            name = invite.invitedBy?.let { "${it.name} (${it.emailAddress})" } ?: Res.string.error_unknown_user,
                            role = invite.role.displayName,
                        ),
                    modifier = Modifier
                        .typography(LabelLarge),
                )
            }
            Spacer()

            if (canModify) {
                MaterialIconButton(
                    onClick = {
                        onRemoveInviteClick()
                    },
                ) {
                    MdiDelete(
                        Modifier.color(OutlinedCardColor.value()),
                    )
                }
            }
        }
    }
}
