package cz.cvut.fit.horanvoj.ribbon.unwinder.web.infrastructure

import io.ktor.client.*
import io.ktor.client.engine.js.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.auth.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.browser.window
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.json.Json

@OptIn(ExperimentalSerializationApi::class)
internal fun createHttpClient(tokenProvider: suspend () -> String?): HttpClient {
    return HttpClient(Js) {
        expectSuccess = true

        defaultRequest {
            url(getApiUrl())
            contentType(ContentType.Application.Json)
        }

        install(ContentNegotiation) {
            json(
                Json(DefaultJson) {
                    explicitNulls = false
                },
            )
        }

        install(Auth) {
            firebase {
                getToken { tokenProvider() }
            }
        }
    }
}

fun getApiUrl(): String {
    return when (window.location.hostname) {
        "localhost" -> "http://localhost:8080"
        else -> "https://api.ribbon.vojtechh.eu/"
    }
}
