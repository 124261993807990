package cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material

import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.TransitionProperty
import com.varabyte.kobweb.compose.css.boxShadow
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.styleModifier
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.MaterialShadowLevel.*
import org.jetbrains.compose.web.css.AnimationTimingFunction
import org.jetbrains.compose.web.css.s

enum class MaterialShadowLevel {
    LEVEL_ONE,
    LEVEL_TWO,
    LEVEL_THREE,
    LEVEL_FOUR,
    LEVEL_FIVE,
}

fun Modifier.materialShadow(level: MaterialShadowLevel = LEVEL_THREE) =
    styleModifier {
        val value =
            when (level) {
                LEVEL_ONE -> "0px 1px 4px 0px rgba(0, 0, 0, 0.37)"
                LEVEL_TWO -> "0px 2px 2px 0px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.3)"
                LEVEL_THREE -> "0px 11px 7px 0px rgba(0, 0, 0, 0.19), 0px 13px 25px 0px rgba(0, 0, 0, 0.3)"
                LEVEL_FOUR -> "0px 14px 12px 0px rgba(0, 0, 0, 0.17), 0px 20px 40px 0px rgba(0, 0, 0, 0.3)"
                LEVEL_FIVE -> "0px 17px 17px 0px rgba(0, 0, 0, 0.15), 0px 27px 55px 0px rgba(0, 0, 0, 0.3)"
            }
        this.boxShadow(value)
    }

val ElevationTransition =
    CSSTransition(
        property = TransitionProperty.of("box-shadow"),
        duration = 0.3.s,
        timingFunction = AnimationTimingFunction.EaseInOut,
    )
