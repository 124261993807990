package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.translation

import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.translation.data.repository.TranslationRepositoryImpl
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.translation.data.source.TranslationSource
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.translation.domain.repository.TranslationRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.translation.domain.usecase.DeleteTranslationUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.translation.domain.usecase.DeleteTranslationUseCaseImpl
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.translation.domain.usecase.SetTranslationUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.translation.domain.usecase.SetTranslationUseCaseImpl
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.translation.infrastructure.api.TranslationApi
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.translation.infrastructure.api.TranslationApiImpl
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.translation.infrastructure.source.TranslationSourceImpl
import org.koin.core.module.dsl.factoryOf
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.bind
import org.koin.dsl.module

val featureTranslationModule =
    module {
        singleOf(::TranslationApiImpl) bind TranslationApi::class
        singleOf(::TranslationSourceImpl) bind TranslationSource::class
        singleOf(::TranslationRepositoryImpl) bind TranslationRepository::class

        factoryOf(::SetTranslationUseCaseImpl) bind SetTranslationUseCase::class
        factoryOf(::DeleteTranslationUseCaseImpl) bind DeleteTranslationUseCase::class
    }
