package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.infrastructure.source

import cz.cvut.fit.horanvoj.ribbon.model.invite.Invite
import cz.cvut.fit.horanvoj.ribbon.model.user.User
import cz.cvut.fit.horanvoj.ribbon.model.user.UserModification
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.data.source.UserSource
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.infrastructure.api.UserApi

internal class UserSourceImpl(
    private val userApi: UserApi,
) : UserSource {
    override suspend fun getUser(userId: String): User {
        return userApi.getUser(userId)
    }

    override suspend fun removeUser() {
        userApi.removeUser()
    }

    override suspend fun modifyUser(userModification: UserModification): User {
        return userApi.modifyUser(userModification)
    }

    override suspend fun getInvites(): List<Invite> {
        return userApi.getInvites()
    }

    override suspend fun acceptInvite(inviteId: String) {
        userApi.acceptInvite(inviteId)
    }

    override suspend fun declineInvite(inviteId: String) {
        userApi.declineInvite(inviteId)
    }
}
