package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages

import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.NavHeaderViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.languages.LanguagesViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.login.ForgottenPasswordViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.login.LoginViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.LanguagesCardViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.MembersCardViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.TermsCardViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.export.ExportViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.import.ImportViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.languages.ManageLanguagesViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.languages.addLanguage.AddLanguageViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.members.MemberViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.options.OptionsViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.options.modify.ModifyProjectViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.terms.TermsViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.projects.ProjectsViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.projects.createProject.CreateProjectViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.register.RegisterViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.translate.TranslateViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.translate.referenceLanguage.ReferenceLanguageViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.user.UserViewModel
import org.koin.core.module.dsl.factoryOf
import org.koin.dsl.module

internal val presentationModule =
    module {
        factoryOf(::ProjectsViewModel)
        factoryOf(::LanguagesViewModel)
        factoryOf(::LoginViewModel)
        factoryOf(::RegisterViewModel)
        factoryOf(::NavHeaderViewModel)
        factoryOf(::UserViewModel)
        factoryOf(::ForgottenPasswordViewModel)
        factoryOf(::ReferenceLanguageViewModel)
        factoryOf(::AddLanguageViewModel)
        factoryOf(::TranslateViewModel)
        factoryOf(::CreateProjectViewModel)
        factoryOf(::LanguagesCardViewModel)
        factoryOf(::TermsCardViewModel)
        factoryOf(::MembersCardViewModel)
        factoryOf(::OptionsViewModel)
        factoryOf(::ModifyProjectViewModel)
        factoryOf(::ExportViewModel)
        factoryOf(::MemberViewModel)
        factoryOf(::ManageLanguagesViewModel)
        factoryOf(::TermsViewModel)
        factoryOf(::ImportViewModel)
    }
