package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.terms

import Res
import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.silk.components.text.SpanText
import cz.cvut.fit.horanvoj.ribbon.model.term.Term
import cz.cvut.fit.horanvoj.ribbon.model.term.TermModification
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.*
import org.jetbrains.compose.web.dom.Text

@Composable
fun UpdateTermDialog(
    term: Term,
    onConfirmed: (TermModification) -> Unit,
    onCancelled: () -> Unit,
) {
    var termId by remember(term) { mutableStateOf(term.id) }
    var description by remember(term) { mutableStateOf(term.description.orEmpty()) }

    val termValid = !termId.trim().contains(' ')
    val isConfirmButtonEnabled = termId.isNotEmpty() && termValid

    MaterialDialog(
        headline = {
            Text(Res.string.title_update_term)
        },
        onDismiss = onCancelled,
        buttons = {
            MaterialTextButton(
                onClick = {
                    onCancelled()
                },
            ) {
                Text(Res.string.button_cancel)
            }
            MaterialTextButton(
                enabled = isConfirmButtonEnabled,
                onClick = {
                    val creation =
                        TermModification(
                            id = termId.trim().takeIf { term.id != termId },
                            description = description.trim().takeIf { it.isNotEmpty() },
                        )

                    onConfirmed(creation)
                },
            ) {
                Text(Res.string.button_confirm)
            }
        },
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .gap(SpaceMedium),
        ) {
            SpanText(
                text = Res.string.label_update_term,
                modifier = Modifier
                    .typography(BodyMedium),
            )

            MaterialTextInput(
                text = termId,
                onTextChanged = {
                    termId = it
                },
                valid = termValid,
                placeholder = Res.string.label_term_id,
                modifier = Modifier
                    .fillMaxWidth(),
            )

            MaterialTextInput(
                text = description,
                onTextChanged = {
                    description = it
                },
                placeholder = Res.string.label_term_description,
                modifier = Modifier
                    .fillMaxWidth(),
            )
        }
    }
}
