package cz.cvut.fit.horanvoj.ribbon.model.invite

import cz.cvut.fit.horanvoj.ribbon.model.project.MemberRole
import cz.cvut.fit.horanvoj.ribbon.model.project.Project
import cz.cvut.fit.horanvoj.ribbon.model.user.User
import kotlinx.serialization.Serializable

@Serializable
data class Invite(
    val inviteId: String,
    val project: Project,
    val role: MemberRole,
    val invitedUserEmail: String,
    val invitedBy: User?,
    val accepted: Boolean,
    val declined: Boolean,
)
