package cz.cvut.fit.horanvoj.ribbon.unwinder.web.components

import com.varabyte.kobweb.navigation.Router
import cz.cvut.fit.horanvoj.ribbon.model.common.Order
import cz.cvut.fit.horanvoj.ribbon.model.term.TermFilter
import cz.cvut.fit.horanvoj.ribbon.util.serialized

/**
 * [cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.Index]
 */
fun Router.routeToHomepage() = tryRoutingTo("/")

/**
 * [cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.login.LoginPage]
 */
fun Router.routeToLogin(returnTo: String? = null) =
    tryRoutingTo(
        createPathFragment(path = "/login", "returnTo" to returnTo),
    )

/**
 * [cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.register.RegisterPage]
 */
fun Router.routeToRegister() = tryRoutingTo("/register")

/**
 * [cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.login.ForgottenPasswordPage]
 */
fun Router.routeToForgottenPassword() = tryRoutingTo("/forgottenPassword")

/**
 * [cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.user.UserPage]
 */
fun Router.routeToUser() = tryRoutingTo("/user")

/**
 * [cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.projects.ProjectsPage]
 */
fun Router.routeToProjects() = tryRoutingTo("/projects")

/**
 * [cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.languages.LanguagesPage]
 */
fun Router.routeToLanguages(projectId: String) = tryRoutingTo("/project/$projectId/languages")

/**
 * [cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.languages.Manage]
 */
fun Router.routeToManage(projectId: String) = tryRoutingTo("/project/$projectId/manage")

/**
 * [cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.translate.TranslatePage]
 */
fun Router.routeToTranslations(
    projectId: String,
    languageId: String,
    cursor: String? = null,
    query: String? = null,
    order: Order? = null,
    filter: TermFilter? = null,
    referenceLanguage: String? = null,
) = tryRoutingTo(
    createPathFragment(
        path = "/project/$projectId/languages/$languageId",
        "cursor" to cursor,
        "query" to query,
        "referenceLanguage" to referenceLanguage,
        "order" to order?.serialized(),
        "filter" to filter?.serialized(),
    ),
)

/**
 * [cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.members.MembersPage]
 */
fun Router.routeToMembers(projectId: String) = tryRoutingTo("/project/$projectId/manage/members")

/**
 * [cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.languages.ManageLanguagesPage]
 */
fun Router.routeToManageLanguages(projectId: String) = tryRoutingTo("/project/$projectId/manage/languages")

/**
 * [cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.terms.TermsPage]
 */
fun Router.routeToManageTerms(
    projectId: String,
    cursor: String? = null,
    query: String? = null,
    order: Order? = null,
    filter: TermFilter? = null,
) = tryRoutingTo(
    createPathFragment(
        path = "/project/$projectId/manage/terms",
        "cursor" to cursor,
        "query" to query,
        "order" to order?.serialized(),
        "filter" to filter?.serialized(),
    ),
)

/**
 * [cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.import.ImportPage]
 */
fun Router.routeToImport(projectId: String) = tryRoutingTo("/project/$projectId/manage/import")

/**
 * [cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.export.ExportPage]
 */
fun Router.routeToExport(projectId: String) = tryRoutingTo("/project/$projectId/manage/export")

private fun createPathFragment(
    path: String,
    vararg queryParams: Pair<String, String?>,
): String {
    var hasQuery = false
    return buildString {
        append(path)

        queryParams.forEach { (key, value) ->
            if (!value.isNullOrEmpty()) {
                if (!hasQuery) {
                    append("?")
                    hasQuery = true
                } else {
                    append("&")
                }
                append("$key=$value")
            }
        }
    }
}
