package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.batch.infrastructure.api

import cz.cvut.fit.horanvoj.ribbon.model.batch.BatchFormat
import cz.cvut.fit.horanvoj.ribbon.model.batch.ImportResult
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.use
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*

internal interface BatchApi {
    suspend fun import(
        projectId: String,
        languageId: String,
        format: BatchFormat,
        importData: String,
        overrideExisting: Boolean,
    ): ImportResult
}

internal class BatchApiImpl(
    private val httpClient: HttpClient,
) : BatchApi {
    override suspend fun import(
        projectId: String,
        languageId: String,
        format: BatchFormat,
        importData: String,
        overrideExisting: Boolean,
    ): ImportResult {
        return httpClient.use {
            post("api/v1/batch/$projectId/import") {
                parameter("languageId", languageId)
                parameter("overrideExisting", overrideExisting)
                parameter("format", format.name.lowercase())
                setBody(importData)
            }.body()
        }
    }
}
