package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.import

import cz.cvut.fit.horanvoj.ribbon.model.batch.BatchFormat
import cz.cvut.fit.horanvoj.ribbon.model.batch.ImportResult
import cz.cvut.fit.horanvoj.ribbon.model.batch.extension
import cz.cvut.fit.horanvoj.ribbon.model.language.Language
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.batch.domain.usecase.ImportUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.language.domain.usecase.GetProjectLanguagesUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.invoke
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.ViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.import.ImportViewModel.State
import org.w3c.files.File
import org.w3c.files.FileReader

internal class ImportViewModel(
    private val getProjectLanguages: GetProjectLanguagesUseCase,
    private val import: ImportUseCase,
) : ViewModel<State>(State()) {
    private val fileReader: FileReader = FileReader()

    init {
        fileReader.onload = {
            val importData = fileReader.result.toString()
            update { copy(importData = importData) }
        }
    }

    fun onAppear(projectId: String) {
        update { copy(projectId = projectId) }
        launch { fetchLanguages() }
    }

    private suspend fun fetchLanguages() {
        val projectId = state.projectId ?: return
        update { copy(loading = true) }
        getProjectLanguages(GetProjectLanguagesUseCase.Params(projectId = projectId))
            .onSuccess { languages ->
                update { copy(languages = languages, loading = false) }
            }
            .onFailure {
                update { copy(loading = false, error = it) }
            }
    }

    fun onLanguageSelected(languageId: String) {
        if (state.selectedLanguage == languageId) {
            update { copy(selectedLanguage = null) }
        } else {
            update { copy(selectedLanguage = languageId) }
        }
    }

    fun onFormatSelected(format: BatchFormat) {
        update { copy(selectedFormat = format) }
    }

    fun onFileSelected(file: File) {
        val format =
            BatchFormat.entries.firstOrNull {
                file.name.endsWith(it.extension)
            }

        val language =
            state.languages.firstOrNull {
                file.name.contains(it.languageId)
            }

        update {
            copy(
                importFile = file,
                selectedFormat = format,
                selectedLanguage = language?.languageId,
                importData = null,
            )
        }
        fileReader.readAsText(file)
    }

    fun onOverrideExistingChanged(value: Boolean) {
        update { copy(overrideExisting = value) }
    }

    fun onImportClicked() {
        val projectId = state.projectId ?: return
        val selectedLanguage = state.selectedLanguage ?: return
        val selectedFormat = state.selectedFormat ?: return
        val importData = state.importData ?: return
        val overrideExisting = state.overrideExisting
        update { copy(importing = true, importResult = null, importError = null) }
        launch {
            import(
                ImportUseCase.Params(
                    projectId = projectId,
                    format = selectedFormat,
                    languageId = selectedLanguage,
                    overrideExisting = overrideExisting,
                    importData = importData,
                ),
            ).onSuccess { result ->
                update {
                    copy(
                        importResult = result,
                        importing = false,
                        importData = null,
                        importFile = null,
                        selectedFormat = null,
                        selectedLanguage = null,
                        overrideExisting = false,
                    )
                }
            }.onFailure {
                update { copy(importing = false, importError = it) }
            }
        }
    }

    data class State(
        val projectId: String? = null,
        val languages: List<Language> = emptyList(),
        val importData: String? = null,
        val importFile: File? = null,
        val selectedFormat: BatchFormat? = null,
        val selectedLanguage: String? = null,
        val overrideExisting: Boolean = false,
        val importResult: ImportResult? = null,
        val importing: Boolean = false,
        val loading: Boolean = false,
        val error: Throwable? = null,
        val importError: Throwable? = null,
        val acceptTypes: String = ACCEPTS.joinToString(separator = ", "),
    ) {
        val isImportEnabled =
            importFile != null &&
                importData != null &&
                selectedFormat != null &&
                selectedLanguage != null

        val isFormatKnown =
            if (importFile == null) {
                true
            } else {
                ACCEPTS.any { importFile.name.endsWith(it) }
            }

        private companion object {
            val ACCEPTS = BatchFormat.entries.map { it.extension }
        }
    }
}
