package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.languages.addLanguage

import Res
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.style.toModifier
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceSmall
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.*
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.rememberViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.PrimaryListItem
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.domain.displayName
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.enterVerticallyAnimation
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.fadeInAnimation
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

@Composable
fun AddLanguageDialog(
    onAdded: () -> Unit,
    onCancelled: () -> Unit,
) {
    val ctx = rememberPageContext()
    val projectId = ctx.route.params.getValue("projectId")
    val viewModel = rememberViewModel<AddLanguageViewModel>()

    LaunchedEffect(viewModel) {
        viewModel.onAppear(projectId)
    }

    LaunchedEffect(viewModel.state.addedLanguage) {
        if (viewModel.state.addedLanguage) {
            onAdded()
        }
    }

    MaterialDialog(
        maximize = true,
        onDismiss = onCancelled,
        headline = {
            Text(Res.string.title_add_language)
        },
        buttons = {
            MaterialTextButton(
                onClick = {
                    onCancelled()
                },
            ) {
                Text(Res.string.button_cancel)
            }
        },
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth(),
        ) {
            SearchInputOutlined(
                text = viewModel.state.query,
                onTextChanged = viewModel::onQueryChange,
                placeholder = Res.string.label_search,
                enabled = !viewModel.state.loading,
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(leftRight = SpaceMedium)
                    .margin(bottom = SpaceMedium),
            )

            if (viewModel.state.loading) {
                HorizontalProgressBar(
                    Modifier.fillMaxWidth()
                        .enterVerticallyAnimation(),
                )
            }

            Div(
                attrs =
                    MaterialHorizontalDividerStyle.toModifier()
                        .toAttrs(),
            )

            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .gap(SpaceMedium)
                    .height(50.vh)
                    .overflow(Overflow.Clip, Overflow.Auto)
                    .margin(topBottom = SpaceSmall)
                    .padding(leftRight = SpaceMedium),
            ) {
                if (viewModel.state.supportedLanguages.isEmpty() && !viewModel.state.loading) {
                    Box(
                        contentAlignment = Alignment.Center,
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(SpaceMedium)
                            .typography(TitleLarge)
                            .textAlign(TextAlign.Center)
                            .fadeInAnimation(),
                    ) {
                        if (viewModel.state.isQueryInvalid) {
                            Text(Res.string.label_languages_search)
                        } else {
                            Text(Res.string.label_languages_empty_search)
                        }
                    }
                } else {
                    viewModel.state.supportedLanguages.forEach { language ->
                        PrimaryListItem(
                            onClick = {
                                viewModel.onAddLanguageClick(language)
                            },
                            modifier = Modifier,
                        ) {
                            Text(language.displayName)
                        }
                    }
                }
            }
            Div(
                attrs =
                    MaterialHorizontalDividerStyle.toModifier()
                        .toAttrs(),
            )
        }
    }
}
