package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term

import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term.data.repository.TermRepositoryImpl
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term.data.source.TermSource
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term.domain.repository.TermRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term.domain.usecase.*
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term.infrastructure.api.TermApi
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term.infrastructure.api.TermApiImpl
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term.infrastructure.source.TermSourceImpl
import org.koin.core.module.dsl.factoryOf
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.bind
import org.koin.dsl.module

val featureTermModule =
    module {
        singleOf(::TermApiImpl) bind TermApi::class
        singleOf(::TermSourceImpl) bind TermSource::class
        singleOf(::TermRepositoryImpl) bind TermRepository::class

        factoryOf(::CreateTermUseCaseImpl) bind CreateTermUseCase::class
        factoryOf(::GetTermsUseCaseImpl) bind GetTermsUseCase::class
        factoryOf(::GetTermUseCaseImpl) bind GetTermUseCase::class
        factoryOf(::QueryTermsUseCaseImpl) bind QueryTermsUseCase::class
        factoryOf(::RemoveTermUseCaseImpl) bind RemoveTermUseCase::class
        factoryOf(::UpdateTermUseCaseImpl) bind UpdateTermUseCase::class
    }
