package cz.cvut.fit.horanvoj.ribbon.model.project

import kotlinx.serialization.Serializable

@Serializable
data class ProjectWithMembership(
    val id: String,
    val name: String,
    val membership: Membership,
)
