package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.options.modify

import cz.cvut.fit.horanvoj.ribbon.model.project.ProjectModification
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase.GetProjectUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase.ModifyProjectUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.invoke
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.ViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.manage.section.options.modify.ModifyProjectViewModel.State
import kotlinx.coroutines.flow.first

internal class ModifyProjectViewModel(
    private val getProject: GetProjectUseCase,
    private val modifyProject: ModifyProjectUseCase,
) : ViewModel<State>(State()) {
    fun onAppear(projectId: String) {
        update { copy(projectId = projectId) }
        launch { fetchProject() }
    }

    private suspend fun fetchProject() {
        val projectId = state.projectId ?: return
        update { copy(loading = true) }
        getProject(GetProjectUseCase.Params(projectId = projectId))
            .onSuccess { projectFlow ->
                val project = projectFlow.first()
                update {
                    copy(
                        name = project.name,
                        originalName = project.name,
                        loading = false,
                    )
                }
            }
            .onFailure {
                update { copy(loading = false, error = it) }
            }
    }

    fun onModifyClick() {
        val projectId = state.projectId ?: return
        if (!state.hasModified) {
            return
        }

        launch {
            update { copy(loading = true) }
            modifyProject(
                ModifyProjectUseCase.Params(
                    projectId = projectId,
                    modification =
                        ProjectModification(
                            name = state.name,
                        ),
                ),
            ).onSuccess {
                update { copy(modifiedProject = true, loading = false) }
            }.onFailure {
                update { copy(error = it, loading = false) }
            }
        }
    }

    fun onNameChanged(name: String) {
        update { copy(name = name) }
    }

    data class State(
        val loading: Boolean = true,
        val projectId: String? = null,
        val modifiedProject: Boolean = false,
        val name: String = "",
        val originalName: String = "",
        val error: Throwable? = null,
    ) {
        val hasModified = name != originalName

        val isModifyEnabled = name.isNotBlank() && hasModified
    }
}
