package cz.cvut.fit.horanvoj.ribbon.model.batch

import kotlinx.serialization.Serializable

@Serializable
data class ImportResult(
    val totalImportedCount: Int,
    val skipped: List<String>,
    val importLanguage: String,
)
