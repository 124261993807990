package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.exception

import cz.cvut.fit.horanvoj.ribbon.model.common.Error
import io.ktor.http.*

class NetworkException(
    error: Error?,
    status: HttpStatusCode,
) : ApplicationException(
        error?.message ?: "Unknown error: $status",
    )
