package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.model.project.Project
import cz.cvut.fit.horanvoj.ribbon.model.project.ProjectCreation
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.repository.ProjectRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase.CreateProjectUseCase.Params
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase

interface CreateProjectUseCase : UseCase<Params, Project> {
    data class Params(
        val creation: ProjectCreation,
    )
}

internal class CreateProjectUseCaseImpl(
    private val projectRepository: ProjectRepository,
) : CreateProjectUseCase {
    override suspend fun execute(params: Params): Project {
        return projectRepository.createProject(
            creation = params.creation,
        )
    }
}
