package cz.cvut.fit.horanvoj.ribbon.unwinder.web.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import kotlinx.browser.document

@Composable
fun EscapeKeyEffect(onEscape: () -> Unit) {
    LaunchedEffect(Unit) {
        document.onkeydown = {
            if (it.keyCode == 27) { // escape keycode
                onEscape()
            }
        }
    }
}
