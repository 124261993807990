package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.login

import Res
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.components.icons.mdi.MdiAlternateEmail
import com.varabyte.kobweb.silk.components.icons.mdi.MdiLock
import com.varabyte.kobweb.silk.components.text.SpanText
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceLarge
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.dimens.SpaceMedium
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.layouts.PageLayout
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.material.*
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.rememberViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.routeToForgottenPassword
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.routeToRegister
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.widgets.ErrorSnackbar
import org.jetbrains.compose.web.attributes.AutoComplete
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Text

@Page("/login")
@Composable
fun LoginPage() {
    val viewModel = rememberViewModel<LoginViewModel>()
    val ctx = rememberPageContext()

    LaunchedEffect(viewModel) {
        viewModel.onAppear()
    }

    LaunchedEffect(
        viewModel.state.isLoggedIn,
        ctx.route.pathQueryAndFragment,
    ) {
        if (viewModel.state.isLoggedIn) {
            val returnTo = ctx.route.params["returnTo"]
            if (returnTo.isNullOrEmpty()) {
                ctx.router.tryRoutingTo(
                    pathQueryAndFragment = "/",
                    updateHistoryMode = UpdateHistoryMode.REPLACE,
                )
            } else {
                ctx.router.tryRoutingTo(
                    pathQueryAndFragment = returnTo,
                    updateHistoryMode = UpdateHistoryMode.REPLACE,
                )
            }
        }
    }

    ErrorSnackbar(
        throwable = viewModel.state.error,
    )

    PageLayout(
        title = Res.string.title_login,
        loading = viewModel.state.loading,
        retainContentOnLoading = true,
    ) {
        Column(
            verticalArrangement = Arrangement.Center,
            modifier = Modifier
                .padding(SpaceMedium)
                .minWidth(35.percent)
                .maxWidth(400.px)
                .width(100.percent)
                .gap(SpaceMedium),
        ) {
            SpanText(
                text = Res.string.title_login,
                modifier = Modifier
                    .typography(TitleLarge),
            )

            MaterialTextInput(
                text = viewModel.state.email,
                onTextChanged = viewModel::onEmailChange,
                placeholder = Res.string.label_email,
                required = true,
                valid = viewModel.state.isEmailValid,
                autoComplete = AutoComplete.email,
                icon = {
                    MdiAlternateEmail()
                },
                modifier = Modifier
                    .fillMaxWidth(),
            )

            MaterialTextInput(
                text = viewModel.state.password,
                onTextChanged = viewModel::onPasswordChange,
                onCommit = viewModel::login,
                valid = viewModel.state.isPasswordValid,
                password = true,
                placeholder = Res.string.label_password,
                required = true,
                autoComplete = AutoComplete.currentPassword,
                icon = {
                    MdiLock()
                },
                modifier = Modifier
                    .fillMaxWidth(),
            )

            Row(
                modifier = Modifier
                    .fillMaxWidth(),
            ) {
                MaterialTextButton(
                    onClick = {
                        ctx.router.routeToForgottenPassword()
                    },
                ) {
                    Text(Res.string.button_forgotten_password)
                }
                Spacer()
                MaterialFilledButton(
                    onClick = { viewModel.login() },
                    enabled = viewModel.state.isLoginButtonEnabled,
                ) {
                    Text(Res.string.button_login)
                }
            }

            SpanText(
                text = Res.string.title_looking_for_registration,
                modifier = Modifier
                    .typography(TitleLarge)
                    .margin(top = SpaceLarge),
            )

            Row(
                horizontalArrangement = Arrangement.End,
                modifier = Modifier.fillMaxWidth(),
            ) {
                MaterialTonalButton(
                    onClick = {
                        ctx.router.routeToRegister()
                    },
                ) {
                    Text(Res.string.button_register_instead)
                }
            }
        }
    }
}
