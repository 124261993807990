package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.model.user.UserModification
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.usecase.ModifyLoggedInUseUseCase.Params
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase

interface ModifyLoggedInUseUseCase : UseCase<Params, Unit> {
    data class Params(
        val modification: UserModification,
    )
}
