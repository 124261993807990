package cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.projects.createProject

import cz.cvut.fit.horanvoj.ribbon.model.project.ProjectCreation
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase.CreateProjectUseCase
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.util.invoke
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.components.ViewModel
import cz.cvut.fit.horanvoj.ribbon.unwinder.web.pages.projects.createProject.CreateProjectViewModel.State

internal class CreateProjectViewModel(
    private val createProject: CreateProjectUseCase,
) : ViewModel<State>(State()) {
    fun onAppear() {
    }

    fun onCreateClick() {
        launch {
            createProject(
                CreateProjectUseCase.Params(
                    creation =
                        ProjectCreation(
                            name = state.name,
                        ),
                ),
            ).onSuccess {
                update { copy(createdProject = true) }
            }.onFailure {
                update { copy(error = it) }
            }
        }
    }

    fun onNameChanged(name: String) {
        update { copy(name = name) }
    }

    data class State(
        val createdProject: Boolean = false,
        val name: String = "",
        val error: Throwable? = null,
    ) {
        val isCreateEnabled = name.isNotBlank()
    }
}
