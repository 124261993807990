package cz.cvut.fit.horanvoj.ribbon.unwinder.web.domain

import kotlinx.datetime.*
import kotlinx.datetime.format.char

fun Instant.format() =
    toLocalDateTime(
        TimeZone.currentSystemDefault(),
    ).format(
        LocalDateTime.Format {
            hour()
            char(':')
            minute()
            char(' ')
            dayOfMonth()
            char('.')
            monthNumber()
            char('.')
            year()
        },
    )
