package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.infrastructure.source

import com.russhwolf.settings.ExperimentalSettingsApi
import com.russhwolf.settings.Settings
import com.russhwolf.settings.serialization.serializedValue
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.shared.domain.model.CacheKey
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapNotNull
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.KSerializer
import kotlinx.serialization.builtins.MapSerializer
import kotlinx.serialization.builtins.serializer
import kotlin.time.Duration
import kotlin.time.Duration.Companion.minutes

@OptIn(ExperimentalSerializationApi::class, ExperimentalSettingsApi::class)
abstract class CacheLocalSource<Value>(
    serializer: KSerializer<Value>,
    cacheKey: String,
    settings: Settings,
    private val entryDuration: Duration = DEFAULT_ENTRY_DURATION,
) {
    private var values: Map<String, CacheKey<Value>> by settings
        .serializedValue(
            serializer =
                MapSerializer(
                    String.serializer(),
                    CacheKey.serializer(serializer),
                ),
            key = cacheKey,
            defaultValue = mapOf(),
        )

    private val valueFlow = MutableStateFlow(values.mapValues { it.value.value })

    fun isValuePresent(id: String): Boolean {
        val value = values[id] ?: return false

        val timestamp = Instant.fromEpochSeconds(value.timestamp)
        return Clock.System.now() - timestamp <= entryDuration
    }

    fun getValue(id: String): Flow<Value> {
        return valueFlow.mapNotNull { map -> map[id] }
    }

    fun updateValue(
        id: String,
        value: Value,
    ) {
        val newValue = values.toMutableMap()
        newValue[id] = CacheKey(value)
        update(newValue)
    }

    fun clearValue(id: String) {
        update(values - id)
    }

    private fun update(newValue: Map<String, CacheKey<Value>>) {
        values = newValue
        valueFlow.value = newValue.mapValues { it.value.value }
    }

    private companion object {
        val DEFAULT_ENTRY_DURATION = 10.minutes
    }
}
