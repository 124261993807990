package cz.cvut.fit.horanvoj.ribbon.model.term

import cz.cvut.fit.horanvoj.ribbon.model.translation.Translation
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class Term(
    val id: String,
    val description: String?,
    val translations: List<Translation>?,
    val updated: Instant,
    val authorEmail: String?,
    val authorId: String?,
    val statistics: TermStatistics?,
)
