package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term.data.repository

import cz.cvut.fit.horanvoj.ribbon.model.common.Order
import cz.cvut.fit.horanvoj.ribbon.model.common.Paging
import cz.cvut.fit.horanvoj.ribbon.model.term.Term
import cz.cvut.fit.horanvoj.ribbon.model.term.TermCreation
import cz.cvut.fit.horanvoj.ribbon.model.term.TermFilter
import cz.cvut.fit.horanvoj.ribbon.model.term.TermModification
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term.data.source.TermSource
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.term.domain.repository.TermRepository

internal class TermRepositoryImpl(
    private val termRepository: TermSource,
) : TermRepository {
    override suspend fun getTerms(
        projectId: String,
        size: Int,
        cursor: String?,
        includeLanguageIds: List<String>,
        filter: TermFilter?,
        filterLanguage: String?,
        order: Order?,
    ): Paging<Term> {
        return termRepository.getTerms(
            projectId = projectId,
            size = size,
            cursor = cursor,
            includeLanguageIds = includeLanguageIds,
            filter = filter,
            filterLanguage = filterLanguage,
            order = order,
        )
    }

    override suspend fun queryTerms(
        projectId: String,
        size: Int,
        cursor: String?,
        includeLanguageIds: List<String>,
        filter: TermFilter?,
        filterLanguage: String?,
        order: Order?,
        query: String,
    ): Paging<Term> {
        return termRepository.queryTerms(
            projectId = projectId,
            size = size,
            cursor = cursor,
            includeLanguageIds = includeLanguageIds,
            filter = filter,
            filterLanguage = filterLanguage,
            order = order,
            query = query,
        )
    }

    override suspend fun getTerm(
        projectId: String,
        termId: String,
    ): Term {
        return termRepository.getTerm(projectId, termId)
    }

    override suspend fun createTerm(
        projectId: String,
        creation: TermCreation,
    ): Term {
        return termRepository.createTerm(projectId, creation)
    }

    override suspend fun updateTerm(
        projectId: String,
        termId: String,
        modification: TermModification,
    ): Term {
        return termRepository.updateTerm(projectId, termId, modification)
    }

    override suspend fun removeTerm(
        projectId: String,
        termId: String,
    ) {
        termRepository.removeTerm(projectId, termId)
    }
}
