package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user

import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.data.repository.UserRepositoryImpl
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.data.source.UserSource
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.domain.repository.UserRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.domain.usecase.*
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.infrastructure.api.UserApi
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.infrastructure.api.UserApiImpl
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.user.infrastructure.source.UserSourceImpl
import org.koin.core.module.dsl.factoryOf
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.bind
import org.koin.dsl.module

val featureUserModule =
    module {
        singleOf(::UserApiImpl) bind UserApi::class
        singleOf(::UserSourceImpl) bind UserSource::class
        singleOf(::UserRepositoryImpl) bind UserRepository::class

        factoryOf(::ModifyUserUseCaseImpl) bind ModifyUserUseCase::class
        factoryOf(::RemoveUserUseCaseImpl) bind RemoveUserUseCase::class
        factoryOf(::GetUserUseCaseImpl) bind GetUserUseCase::class
        factoryOf(::GetInvitesUseCaseImpl) bind GetInvitesUseCase::class
        factoryOf(::AcceptInviteUseCaseImpl) bind AcceptInviteUseCase::class
        factoryOf(::DeclineInviteUseCaseImpl) bind DeclineInviteUseCase::class
    }
