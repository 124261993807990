package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.translation.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.model.term.Term
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.translation.domain.repository.TranslationRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.translation.domain.usecase.DeleteTranslationUseCase.Params
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase

interface DeleteTranslationUseCase : UseCase<Params, Term> {
    data class Params(
        val projectId: String,
        val termId: String,
        val languageId: String,
    )
}

internal class DeleteTranslationUseCaseImpl(
    private val translationRepository: TranslationRepository,
) : DeleteTranslationUseCase {
    override suspend fun execute(params: Params): Term {
        return translationRepository.deleteTranslation(
            projectId = params.projectId,
            termId = params.termId,
            languageId = params.languageId,
        )
    }
}
