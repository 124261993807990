package cz.cvut.fit.horanvoj.ribbon.model.project

import cz.cvut.fit.horanvoj.ribbon.model.user.User
import kotlinx.serialization.Serializable

@Serializable
data class ProjectMember(
    val user: User,
    val role: MemberRole,
)
