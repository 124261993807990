package cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase

import cz.cvut.fit.horanvoj.ribbon.model.invite.InviteCreation
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.repository.ProjectRepository
import cz.cvut.fit.horanvoj.ribbon.unwidner.feature.project.domain.usecase.InviteMemberUseCase.Params
import cz.cvut.fit.horanvoj.ribbon.usecase.UseCase

interface InviteMemberUseCase : UseCase<Params, Unit> {
    data class Params(
        val invite: InviteCreation,
    )
}

internal class InviteMemberUseCaseImpl(
    private val projectRepository: ProjectRepository,
) : InviteMemberUseCase {
    override suspend fun execute(params: Params) {
        projectRepository.inviteMember(
            inviteCreation = params.invite,
        )
    }
}
